// import AddRoundedIcon from '@mui/icons-material/AddRounded'
// import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import type { Components } from '@mui/material'

const MuiAccordionSummary: Components['MuiAccordionSummary'] = {
  defaultProps: {
    expandIcon: <ExpandMoreIcon />,
    // expandIcon: <AddRoundedIcon />,
    // shrinkIcon: <RemoveRoundedIcon />,
    'aria-controls': 'panel-content',
    id: 'panel-header',
  },
}

export default MuiAccordionSummary

import type { Components } from '@mui/material'

import SPACING from 'src/styles/spacing'

const MuiTextField: Components['MuiTextField'] = {
  defaultProps: {
    variant: 'filled',
  },
  styleOverrides: {
    root: {
      marginTop: SPACING.themeSpacing(2),
    },
  },
}

export default MuiTextField

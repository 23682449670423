import type { Components } from '@mui/material'

import { TEXT } from 'src/styles/colors'
import SPACING from 'src/styles/spacing'

const MuiChip: Components['MuiChip'] = {
  styleOverrides: {
    root: {
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '18px',
      borderRadius: SPACING.themeSpacing(1),
      display: 'inline-flex',
      flexDirection: 'row',
      alignItems: 'center',
      label: {
        padding: '4px 6px',
      },
      sizeSmall: {
        fontSize: '13px',
        lineHeight: '18px',
      },
      outlined: {
        borderColor: '#E5E7EB',
        color: TEXT.PRIMARY,
        '.MuiSvgIcon-root': {
          marginLeft: '8px',
          marginRight: '1px',
        },
        '.MuiChip-label': {
          color: TEXT.SECONDARY,
          fontSize: '13px',
          lineHeight: '18px',
          fontWeight: '300',
        },
      },
    },
  },
}

export default MuiChip

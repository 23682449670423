/* eslint-disable sonarjs/max-switch-cases */
/* eslint-disable complexity */
import { useTheme } from '@mui/material'

export function GetColorLink(LinkName: string) {
  const theme = useTheme()
  switch (LinkName) {
    case 'Spotify':
      return theme.palette.spotify
    case 'Apple Music':
      return theme.palette.appleMusic
    case 'iTunes & Apple Music':
      return theme.palette.appleMusic
    case 'Deezer':
      return theme.palette.deezer
    case 'Amazon Music':
      return theme.palette.amazon
    case 'Soundcloud':
      return theme.palette.soundcloud
    case 'SoundCloud':
      return theme.palette.soundcloud
    case 'TikTok':
      return theme.palette.tiktok
    case 'YouTube':
      return theme.palette.youtube
    case 'iTunes':
      return '#EA4CC0'
    case 'Tidal':
      return '#000000'
    case 'Pandora':
      return '#00A0EE'
    case 'Audiomack':
      return '#ffa200'
    case 'iHeartRadio':
      return '#C6002B'
    case 'Vevo':
      return '#174BEB'
    case 'Joox':
      return '#00DE75'
    case 'KKBox':
      return '#09CEF6'
    case 'Napster':
      return '#2ca6de'
    case 'YouTube Music':
      return theme.palette.youtube
    case 'Beatport':
      return '#01FF95'
    case 'Bandcamp':
      return '#629aa9'
    case 'Anghami':
      return '#39baf8'
    case 'Kanjian':
      return '#0040ff'
    case 'Melon':
      return '#00d344'
    case 'Claro-musica':
      return '#ED1C24'
    case 'Netease':
      return '#C20C0C'
    case 'KKBOX':
      return '#09CEF6'
    case 'Saavn':
      return '#1E1F1F'
    case 'Amazon':
      return theme.palette.amazon
    case 'Facebook':
      return theme.palette.facebook
    case 'YouTube Content ID':
      return theme.palette.youtube
    case '7Digital':
      return '#048da3'
    case 'Bugs!':
      return '#FF3D33'
    case 'Tencent':
      return '#1772F9'
    case 'Qobuz':
      return '#1C8EBC'
    case 'Naver':
      return '#19ce60'
    case 'Nuuday':
      return '#5bfe60'
    case 'eMUSIC':
      return '#ef3e28'
    case 'AWA':
      return '#fc7459'
    case 'FLO':
      return '#4962f7'
    case 'Kuack Media':
      return '#f7aa2f'
    case 'Gracenote':
      return '#f40a53'
    case 'Bandsintown':
      return '#00cec8'
    case 'Songkick':
      return '#f80046'
    case 'Ticketmaster':
      return '#008CFF'
    case 'Download':
      return '#000000'
    case 'Website':
      return '#000000'
    default:
      return theme.palette.primary.main
  }
}

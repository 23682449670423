// Not a functional component
/* eslint-disable react/jsx-no-constructed-context-values */
import type { AlertColor } from '@mui/material'
import type { AxiosError } from 'axios'
import type { ErrorInfo } from 'react'
import { Component, createContext, useContext } from 'react'

import ErrorBanner from 'src/components/ErrorBanner'

type Props = Record<string, unknown>
type ErrorAlert = Error & Partial<ErrorInfo> & { discarded?: boolean, severity: AlertColor, key: string }
export type ErrorContextProps = { errors: ErrorAlert[], discardError: (errorKey: string) => void }

let keyCount = 0
const getKey = () => `${keyCount++}`

let consoleWarn: Console['warn']
let consoleError: Console['error']
let handledRejectionError: Console['error']
const REACT_APP_CATCH_CONSOLE_ERRORS = process.env.REACT_APP_CATCH_CONSOLE_ERRORS === 'true'
if (REACT_APP_CATCH_CONSOLE_ERRORS) {
  const console = ((oldCons: Console) => ({
    ...oldCons,
    warn(...data) {
      oldCons.warn(...data)
      consoleWarn(...data)
    },
    error(...data) {
      oldCons.error(...data)
      consoleError(...data)
    },
    exception(...data: [unknown?, ...unknown[]]) {
      oldCons.error(...data)
      consoleError(...data)
    },
  } as Console))(window.console)
  window.console = console
}

window.onunhandledrejection = event => handledRejectionError(event.reason)

const initialValue = {
  errors: [],
  discardError: () => { /* */ },
} as ErrorContextProps

const ErrorContext = createContext(initialValue)
ErrorContext.displayName = 'ErrorContext'

export const useError = () => useContext(ErrorContext)

const anyToError = (error: Error | { toString: () => string }, errorInfo: ErrorInfo) => ({
  ...errorInfo,
  severity: 'error',
  ...error instanceof Error
    ? {
      name: error.name,
      message: error.message,
      stack: error.stack,
    }
    : {
      name: `${typeof error} error`,
      message: error.toString(),
    },
} as ErrorAlert)

const consoleToError = (error: Error | { toString: () => string }, severity: AlertColor) => {
  const errorAlert: ErrorAlert = {
    severity,
    key: getKey(),
    ...error instanceof Error
      ? {
        name: `[Console] ${error.name}`,
        message: error.message,
        stack: error.stack,
      }
      : {
        name: `[Console] ${typeof error} ${severity}`,
        message: error.toString(),
      },
  }

  if (errorAlert.message.startsWith('Warning')) {
    errorAlert.severity = 'warning'
  }

  return errorAlert
}

const rejectionToError = (error: AxiosError | Error | { toString: () => string }) => {
  const namePrefix = (error as AxiosError).isAxiosError ? '[API]' : 'Unhandled Promise'
  const errorAlert: ErrorAlert = {
    severity: 'error',
    key: getKey(),
    ...error instanceof Error
      ? {
        name: `${namePrefix} ${error.name}`,
        message: error.message,
        stack: error.stack,
      }
      : {
        name: `${namePrefix} ${typeof error} error`,
        message: error.toString(),
      },
  }

  return errorAlert
}

// Note: TODO?
// eslint-disable-next-line react/require-optimization
export default class ErrorBoundary extends Component<Props, ErrorContextProps> {
  constructor(props: Props) {
    super(props)
    this.state = initialValue
  }

  componentDidCatch(error: Error | { toString: () => string }, errorInfo: ErrorInfo) {
    this.setState(previousState => ({ errors: [...previousState.errors, anyToError(error, errorInfo)] }))
  }

  discardError = (errorKey: string) =>
    this.setState(previousState => ({ errors: previousState.errors.filter(error => error.key !== errorKey) }))

  // Note: False positive
  // eslint-disable-next-line react/require-render-return
  render() {
    if (REACT_APP_CATCH_CONSOLE_ERRORS) {
      consoleWarn = (error: Error | { toString: () => string }) =>
        this.setState(previousState => ({ errors: [...previousState.errors, consoleToError(error, 'warning')] }))

      consoleError = (error: Error | { toString: () => string }) => {
        const parsedError = consoleToError(error, 'error')
        if (
          // Compilation errors
          parsedError.message.includes('TypeScript error') ||
          // Will cause `InternalError: too much recursion` in ErrorBanner
          parsedError.message.includes('Cannot update during an existing state transition')
        ) return
        this.setState(previousState => ({ errors: [...previousState.errors, parsedError] }))
      }
    }

    handledRejectionError = (error: AxiosError | Error | { toString: () => string }) =>
      this.setState(previousState => ({ errors: [...previousState.errors, rejectionToError(error)] }))

    const value = { ...this.state, discardError: this.discardError }

    return <ErrorContext.Provider value={value}>
      {this.props.children}
      <ErrorBanner
        sx={{
          position: 'absolute',
          // Above header
          zIndex: 1201,
          right: 0,
          bottom: 0,
          left: 0,
        }}
        {...value}
      />
    </ErrorContext.Provider>
  }
}

import type { Components } from '@mui/material'

import baseTypography from 'src/styles/base.typography.theme'

const MuiFormControlLabel: Components['MuiFormControlLabel'] = {
  styleOverrides: {
    label: { ...baseTypography.body2 },
  },
}

export default MuiFormControlLabel

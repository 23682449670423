import type { Components } from '@mui/material'

const MuiLink: Components['MuiLink'] = {
  defaultProps: {
    underline: 'hover',
    rel: 'noopener noreferrer',
    color: 'info.main',
  },
}

export default MuiLink

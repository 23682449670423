/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const GracenoteLogo = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='3 48 50 92.756' >
    <path d='M41.623 117.871c0 3.852-7.291 6.994-16.3 6.994s-16.3-3.143-16.3-6.994c0-3.854 7.291-6.994 16.3-6.994s16.3 3.141 16.3 6.994zM43.934 88.768c0 10.314-8.357 18.611-18.611 18.611A18.566 18.566 0 0 1 6.712 88.768c0-10.254 8.298-18.611 18.611-18.611 10.254 0 18.611 8.357 18.611 18.611zM48.557 71.342c0 2.549-2.074 4.624-4.623 4.624s-4.683-2.074-4.683-4.624c0-2.608 2.134-4.682 4.683-4.682s4.623 2.074 4.623 4.682z' />
    <path d='M186.898 109.514h-.533v-.297h1.363v.297h-.475v1.363h-.355v-1.363zm2.727.117h-.059l-.416 1.246h-.295l-.475-1.246v1.246h-.297v-1.66h.475l.414 1.127.475-1.127h.475v1.66h-.297v-1.246z' />
  </SvgIcon>

export default GracenoteLogo

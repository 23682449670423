import axios from 'axios'

import { baseUrlLinksApi } from 'src/api/links'

const PATH = '/subscribe' as const

export const getSpotifySubscribeLink = (orgId: string, linkId: string) =>
  axios.post<string>(`${baseUrlLinksApi}${PATH}/org/spotify/${orgId}/${linkId}`)
    .then(result => result.data)

export const getDeezerSubscribeLink = (orgId: string, linkId: string) =>
  axios.post<string>(`${baseUrlLinksApi}${PATH}/org/deezer/${orgId}/${linkId}`)
    .then(response => response.data)

export const appleMusicSubscribeLink = (id: string, code: string, email: string) =>
  axios.post<string>(`${baseUrlLinksApi}${PATH}/org/apple/${id}`, { code, email })
    .then(response => response.data)

export const getAmazonSubscribeLink = (orgId: string, linkId: string) =>
  axios.post<string>(`${baseUrlLinksApi}${PATH}/org/amazon/${orgId}/${linkId}`)
    .then(response => response.data)

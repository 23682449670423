/* eslint-disable react/jsx-child-element-spacing */
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Avatar, Button, Divider, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useState } from 'react'
import Confetti from 'react-confetti'

import DialogDrawer from 'src/components/pages/SmartLink/DialogDrawer/DialogDrawer'
import SubscribeModal from 'src/components/pages/SmartLink/SubscribeModal/SubscribeModal'
import type { MarketingLink } from 'src/models/Marketing'
import type { BasicOrganisation } from 'src/models/Organisation'
import * as darkTheme from 'src/styles/dark.theme'
import * as lightTheme from 'src/styles/light.theme'

type Props = {
  close: () => void
  open: boolean
  link: MarketingLink | undefined
  onOpen: () => void
  organisation: BasicOrganisation
}

const PresaveModal = (props: Props) => {
  const [openSubscribe, setOpenSubscribe] = useState(false)
  const height = window.innerHeight
  const width = window.innerWidth

  const activeTheme = props.link?.palette === 'Dark'
    ? darkTheme
    : lightTheme

  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  return (
    <>
      <Confetti
        height={height}
        numberOfPieces={280}
        recycle={false}
        width={width}
      />
      <SubscribeModal
        close={() => setOpenSubscribe(false)}
        link={props.link}
        open={openSubscribe}
        openDrawer={() => setOpenSubscribe(true)}
        organisation={props.organisation}
      />
      <DialogDrawer
        close={props.close}
        isOpen={props.open}
        link={props.link}
        open={props.onOpen}
      >
        <Stack paddingX={3} paddingY={3} spacing={2} width={1}>
          {!fullScreen &&
          <Stack alignItems='flex-end' width={1}>
            <IconButton
              onClick={() => props.close()}
              sx={{
                width: 'fit-content',
              }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </Stack>}
          <Stack alignItems='center' spacing={1} width='100%'>
            <CheckCircleRoundedIcon color='success' sx={{ height: 128, width: 128 }} />
            <Typography
              color={activeTheme.default.palette.text.primary}
              textAlign='center'
              variant='h3'
            >
              Congratulations!
            </Typography>
            <Stack alignItems='center' width='100%'>
              <Typography
                color={activeTheme.default.palette.text.primary}
                textAlign='center'
                variant='body1'
              >
                You have successfully presaved
                <b>
                  {` ${props.link?.title}`}
                </b>
              </Typography>
              <Typography
                color={activeTheme.default.palette.text.secondary}
                textAlign='center'
                variant='body1'
              >
                It will be added to your library on
                {` ${dayjs(props.link?.releaseDate).format('MMMM D')}`}
              </Typography>
            </Stack>
          </Stack>

          {props.link?.foreverPresave &&
          <>
            <Divider sx={{ borderColor: activeTheme.default.palette.divider }} />

            <Stack alignItems='center' justifyContent='center' width='100%'>
              <Avatar
                src={props.organisation.logoUrl}
                sx={{
                  height: 128,
                  width: 128,
                }}
              />
              <Typography
                lineHeight={1.2}
                sx={{
                  color: activeTheme.default.palette.text.primary,
                }}
                textAlign='center'
                variant='h4'
              >
                {`Subscribe to all releases from ${props.organisation.name}`}
              </Typography>
              <Typography
                sx={{
                  color: activeTheme.default.palette.text.secondary,
                }}
                textAlign='center'
                variant='body2'
              >
                {`By subscribing to ${props.organisation.name}, all their future 
 releases will be added to your library`}
              </Typography>
            </Stack>

            <Stack direction='row' justifyContent='center' width='100%'>
              <Button
                color='success'
                fullWidth
                onClick={() => setOpenSubscribe(true)}
              >
                Subscribe now
              </Button>
            </Stack>
          </>}

        </Stack>
      </DialogDrawer>
    </>
  )
}

export default PresaveModal

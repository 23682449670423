/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const SaavnLogo = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='0 0 100 100'>
    <g>
      <g>
        <path
          className='st1'
          d='M47.5,70.4c2-4.3,3.4-8.5,4.5-12.7c2-7.5,3.1-15.1,2.7-22.9c-0.1-1.8-0.3-3.6-0.6-5.5
			c-0.2-1.1,0.1-1.4,1.2-1.4c3.1,0,6.2,0,9.3,0c4.2,0.1,7.3,3.1,7.4,7.3c0.1,2.9,0,5.8,0.1,8.7c0,0.9-0.6,1.1-1.1,1.4
			c-6.6,3.6-11.9,8.4-16,14.7c-2.1,3.3-3.8,6.9-4.9,10.7c-0.3,1.1-0.8,1.5-1.9,1.4c-2.5-0.1-2.6,0-3-2.5c-2-11.1-7.4-20.1-16.5-26.7
			c-0.4-0.3-0.8-0.5-0.8-1.1c0.1-2.7-0.2-5.4,0.2-8.1c0.5-2.9,2.5-5.1,5.2-5.6c1.4-0.3,1.7-0.1,2.3,1.2c3.6,7.7,6.9,15.5,8.9,23.8
			c1.2,5.2,2.1,10.5,2.8,15.8C47.3,69.1,47.4,69.6,47.5,70.4z'
        />
        <path
          className='st1'
          d='M59.8,72.1c-1.6,0-3.3,0-4.9,0c-0.6,0-1.1,0.1-1.4-0.6c-0.3-0.7,0.2-1.1,0.6-1.5c2.6-2.7,5.8-4.6,9.3-6.1
			c2.5-1.1,5-1.9,7.5-2.7c1-0.3,1.3-0.1,1.3,0.9c0,1.6,0.1,3.2-0.4,4.8c-1.1,3.1-3.7,5.1-7,5.2C63.1,72.1,61.4,72.1,59.8,72.1
			C59.8,72.1,59.8,72.1,59.8,72.1z'
        />
        <path
          className='st1'
          d='M38.1,72.1c-1.3-0.1-2.9,0.1-4.4-0.2c-3.8-0.8-6.4-4.8-5.8-8.5c0.1-0.6,0.4-0.9,1.1-0.8
			c4.9,1.4,9.7,3.1,13.1,7.2c0.2,0.3,0.4,0.5,0.6,0.8c0.5,0.8,0.1,1.5-0.8,1.6C40.7,72.1,39.6,72.1,38.1,72.1z'
        />
      </g>
      <path
        className='st1'
        d='M80.1,36.4c0-1.5-0.1-3.1-0.3-4.6c-0.6-5.7-4.5-10.1-10.1-11.3c-1.6-0.4-3.3-0.5-5-0.5c-2.8,0-5.5,0-8.3,0v0
		c-7.1,0-14.2,0-21.3,0c-1.7,0-3.4,0.2-5,0.5c-5.6,1.2-9.4,5.6-10.1,11.3c-0.2,1.5-0.2,3.1-0.3,4.6c0,9.8-0.1,19.6,0.1,29.4
		c0,2.3,0.4,4.5,1.4,6.6c2.2,4.4,5.8,6.8,10.6,7.3c1.5,0.2,2.9,0.2,4.4,0.2c3.8,0,7.6,0,11.4,0c5.3,0,10.6,0,16,0
		c1.5,0,2.9-0.1,4.4-0.2c4.8-0.5,8.5-2.9,10.6-7.3c1-2.1,1.3-4.3,1.4-6.6C80.3,56.1,80.2,46.2,80.1,36.4z M75.9,67.4
		c-0.7,3.8-2.6,6.5-6.4,7.8c-1.4,0.5-2.8,0.7-4.3,0.8c-2.9,0.1-5.8,0.2-8.7,0.2v0c-2.4,0-4.8,0-7.1,0c-2,0-3.9,0-5.9,0v0
		c-2.9,0-5.8-0.1-8.7-0.2c-1.5-0.1-2.9-0.3-4.3-0.8c-3.7-1.3-5.7-4-6.4-7.8c-0.3-2-0.4-4.1-0.4-6.1c0-7.5,0-15.1,0-22.6
		c0-2.3,0.1-4.5,0.6-6.8c1-4.5,3.9-7.1,8.4-7.7c1.5-0.2,3-0.3,4.5-0.3c3.1,0,6.1,0,9.2-0.1c2,0,4.1,0,6.1,0c0.3,0,0.5,0,0.8,0
		c3.2,0,6.4,0,9.6,0.1c1.5,0,3,0.1,4.5,0.3c4.5,0.7,7.4,3.3,8.4,7.7c0.5,2.2,0.6,4.5,0.6,6.8c0,7.5,0,15.1,0,22.6
		C76.3,63.4,76.2,65.4,75.9,67.4z'
      />
    </g>
  </SvgIcon>

export default SaavnLogo

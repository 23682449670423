import type { AxiosError } from 'axios'
import axios from 'axios'
import { StatusCodes } from 'http-status-codes'

const { NOT_FOUND, UNAUTHORIZED } = StatusCodes

export const allowNotFoundAndUnauthorized = (error: AxiosError) => {
  if (error.response && (error.response.status === NOT_FOUND || error.response.status === UNAUTHORIZED)) {
    return null
  }
  throw error
}

const initializeAxios = () => {
  axios.defaults.baseURL = process.env.NODE_ENV === 'production'
    ? 'https://api.releese.io/api'
    : 'http://localhost:5000/api'

  axios.interceptors.request.use(config => {
    (config.headers as Record<string, string>).Authorization = 'Bearer anonymous'

    return config
  })
}
export default initializeAxios

import type { Components } from '@mui/material'

const MuiStepIcon: Components['MuiStepIcon'] = {
  styleOverrides: {
    text: {
      fontSize: '0.5125em',
    },
  },
}

export default MuiStepIcon

/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const QobuzLogo = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='0 0 50 50'>
    <path d='M 24 2 C 11.861562 2 2 11.861562 2 24 C 2 36.138438 11.861562 46 24 46 C 28.986012 46 33.55729 44.290409 37.248047 41.490234 L 42.242188 46.484375 L 46.484375 42.242188 L 45.777344 41.535156 L 41.492188 37.25 C 44.292017 33.558581 46 28.985756 46 24 C 46 11.861562 36.138438 2 24 2 z M 24 4 C 35.057562 4 44 12.942438 44 24 C 44 28.842339 42.284698 33.270427 39.425781 36.730469 L 38.845703 37.431641 L 43.65625 42.242188 L 42.242188 43.65625 L 38.074219 39.488281 L 37.431641 38.845703 L 36.017578 37.429688 L 36.015625 37.429688 L 33.050781 34.464844 C 32.659781 34.074844 32.660781 33.439781 33.050781 33.050781 C 33.440781 32.660781 34.072844 32.660781 34.464844 33.050781 L 37.429688 36.017578 L 38.126953 35.132812 C 40.660953 31.921812 42 28.072 42 24 C 42 14.075 33.925 6 24 6 C 14.075 6 6 14.075 6 24 C 6 33.925 14.075 42 24 42 C 27.773368 42 31.349871 40.837364 34.410156 38.652344 L 35.851562 40.09375 C 32.533495 42.542748 28.445548 44 24 44 C 12.942438 44 4 35.057562 4 24 C 4 12.942438 12.942438 4 24 4 z M 24 16 C 28.418 16 32 19.582 32 24 C 32 28.418 28.418 32 24 32 C 19.582 32 16 28.418 16 24 C 16 19.582 19.582 16 24 16 z M 24 22 A 2 2 0 0 0 24 26 A 2 2 0 0 0 24 22 z' />
  </SvgIcon>

export default QobuzLogo

/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const NeteaseLogo = (props: SvgIconProps) =>
  <SvgIcon {...props} height={50} viewBox='10 20 290 250' width={50}>
    <path d='M135.71 138.8c3.27-11.41 13.86-18.93 24.73-22.33 3.28 10.2 6.49 20.43 9.28 30.77 1.13 5.46 1.07 11.55-2.06 16.38-5 8.64-18.16 11.44-25.73 4.55-8.11-7.19-9-19.49-6.22-29.37z' />
    <path d='M148.77 66.94c1.34-18.74 19.74-33.86 38.24-32.92 10.72.28 21.24 4.57 29.49 11.35 5.51 4.29 7.5 12.86 3.81 18.9-4.25 6.11-13.66 5.79-19.12 1.54-5.07-3.94-11.54-7.47-18.15-6.05-5.78 1.34-8.47 7.86-7.54 13.29.94 5.46 2.73 10.72 4.15 16.07 10.47.45 20.93 3.13 30.14 8.18 15.34 8.82 28.47 22.01 35.7 38.29 6.91 15.12 8.37 32.34 5.62 48.63-3.89 21.16-16.07 40.43-32.63 54.01-18.78 15.38-43.29 22.87-67.41 22.65-22.64 0-45.07-8.12-62.74-22.22-23.66-18.65-38.89-47.61-40.57-77.71-1.48-21.05 3.9-42.44 14.71-60.52 12.41-20.92 32.12-37.44 54.97-45.81 3.94-1.3 8.49-1.25 12.15.83 5.71 2.99 8.1 11.1 4.55 16.58-1.92 3.47-5.43 5.7-9.1 6.96-18.77 7.23-34.57 21.75-43.43 39.8-7.3 14.48-9.84 31.2-7.53 47.22 2.93 21.06 14.48 40.75 31.4 53.63 13.49 10.34 30.5 16.23 47.54 15.75 19.55-.06 39.52-6.9 53.63-20.72 10.15-10.05 17.73-23.21 19.38-37.56 2.38-15.49-1.77-32.04-12.18-43.92-7.24-8.07-16.28-15.18-27.04-17.69 2.53 7.74 4.85 15.55 7.05 23.39 3.05 11.59 3.17 24.55-2.72 35.3-6.29 12.11-18.57 20.92-32.1 22.82-12.8 2.23-26.5-2.21-35.75-11.3-8.91-8.45-14.02-20.58-14.45-32.8-.89-11.59 1.54-23.53 7.62-33.51 8.1-13.67 22.17-23.11 37.31-27.36-1.9-8.3-5.6-16.42-5-25.1m-13.06 71.86c-2.78 9.88-1.89 22.18 6.22 29.37 7.57 6.89 20.73 4.09 25.73-4.55 3.13-4.83 3.19-10.92 2.06-16.38-2.79-10.34-6-20.57-9.28-30.77-10.87 3.4-21.46 10.92-24.73 22.33z' fill='#fff' />
  </SvgIcon>

export default NeteaseLogo

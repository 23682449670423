import type { StackProps } from '@mui/material'
import { Stack } from '@mui/material'

import type { ErrorContextProps } from 'src/components/providers/ErrorBoundary'

const ErrorBanner = (props: ErrorContextProps & { sx: StackProps['sx'] }) =>
  <Stack
    spacing={1}
    sx={{
      whiteSpace: 'pre',
      '.MuiAlert-message, code': {
        display: 'inline-block',
        width: '100%',
        overflowX: 'hidden',
      },
      '.MuiAlert-root': {
        // Enough to show error name and message
        maxHeight: '83px',
        overflowY: 'auto',
      },
      ...props.sx,
    }}
  />
export default ErrorBanner

/* eslint-disable max-len */
import { Icon } from '@mui/material'

import bugsImage from 'src/components/Icons/bugs.png'

const BugsLogo = () =>
  <Icon sx={{ height: 26, width: 26, fontSize: '1rem' }}>
    <img alt='Bugs Logo' height='100%' src={bugsImage} width='100%' />
  </Icon>

export default BugsLogo

import type { Components } from '@mui/material'

import SPACING from 'src/styles/spacing'

const MuiDrawer: Components['MuiDrawer'] = {
  styleOverrides: {
    paper: {
      border: 'none',
      borderRadius: SPACING.BORDER_RADIUS,
    },
  },
}

export default MuiDrawer

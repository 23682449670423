/* eslint-disable react/jsx-child-element-spacing */
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { Button, Dialog, Stack, Typography } from '@mui/material'
import Confetti from 'react-confetti'

import type { BasicOrganisation } from 'src/models/Organisation'

type Props = {
  close: () => void
  open: boolean
  organisation: BasicOrganisation
}

const SubscribeCompleteModal = (props: Props) => {
  const height = window.innerHeight
  const width = window.innerWidth
  return (
    <>
      <Confetti
        height={height}
        numberOfPieces={280}
        recycle={false}
        width={width}
      />
      <Dialog
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        maxWidth='xs'
        onClose={props.close}
        open={props.open}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          overflow: 'scroll',
          zIndex: 99_999,
        }}
      >
        <Stack paddingX={3} paddingY={3} spacing={2} width={1}>
          <Stack alignItems='center' spacing={1} width='100%'>
            <CheckCircleRoundedIcon color='success' sx={{ height: 128, width: 128 }} />
            <Typography textAlign='center' variant='h3'>
              Congratulations!
            </Typography>
            <Stack alignItems='center' width='100%'>
              <Typography textAlign='center' variant='body1'>
                You have successfully susbcribed to
                <b>
                  {` ${props.organisation.name}`}
                </b>
              </Typography>
              <Typography textAlign='center' variant='body1'>
                All their future releases will be added to your library
              </Typography>
            </Stack>
          </Stack>
          <Stack direction='row' justifyContent='center' width='100%'>
            <Button
              color='success'
              fullWidth
              onClick={() => props.close()}
            >
              Close
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  )
}

export default SubscribeCompleteModal

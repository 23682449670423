import AdapterDateFns from '@mui/lab/AdapterDayjs'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import { StrictMode } from 'react'
import Div100vh from 'react-div-100vh'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import App from './components/App'
import initializeAxios from 'src/api/axios'
import ErrorBoundary from 'src/components/providers/ErrorBoundary'
import reportWebVitals from 'src/utils/reportWebVitals'

initializeAxios()
dayjs.extend(localeData)
ReactDOM.render(
  // Add static providers here
  <StrictMode>
    <ErrorBoundary>
      <Div100vh>
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <App />
          </LocalizationProvider>
        </BrowserRouter>
      </Div100vh>
    </ErrorBoundary>
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

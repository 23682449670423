/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const QzoneIcon = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='0 0 50 50' >
    <path d='M49.949,18.684c-0.115-0.344-0.408-0.6-0.765-0.667l-15.545-2.915L25.868,1.504c-0.384-0.672-1.352-0.672-1.736,0 l-7.771,13.599L0.816,18.017c-0.357,0.067-0.65,0.322-0.765,0.667c-0.115,0.345-0.034,0.725,0.211,0.992l10.69,11.662 L9.007,47.883c-0.042,0.36,0.114,0.715,0.408,0.927c0.294,0.213,0.68,0.25,1.008,0.096l14.59-6.809l15.586,6.819 C40.728,48.973,40.864,49,41,49c0.224,0,0.446-0.075,0.626-0.221c0.291-0.233,0.427-0.608,0.354-0.974L40.139,37 c2.861-2,3.762-3.51,3.828-3.762c0.095-0.359-0.017-0.741-0.291-0.992c-0.131-0.121-0.29-0.199-0.458-0.237 C27,40,14.191,36.027,14,36c-0.382-0.055-1-0.305-1-1c0-0.351,0.161-0.762,0.466-1C17.375,30.939,28,23,28,23s-4.985-1.993-15-1 c-0.451,0.045-0.926-0.412-0.97-0.866c-0.046-0.465,0.051-0.873,0.431-1.126C12.716,19.837,17.803,18.065,27,18 c4.925-0.035,7.462,1.902,7.595,1.99c0.258,0.172,0.421,0.454,0.443,0.763s-0.102,0.611-0.333,0.817l-10.732,8.4 c6.177,2.29,15.02,1.022,15.02,1.022l10.745-11.317C49.982,19.408,50.063,19.028,49.949,18.684z' />
  </SvgIcon>

export default QzoneIcon

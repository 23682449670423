/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const TencentLogo = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='0 0 1306.000000 1306.000000'>
    <g
      fill='#FFFFFF'
      stroke='none'
      transform='translate(0.000000,1306.000000) scale(0.100000,-0.100000)'
    >
      <path d='M3908 6525 c-714 -1415 -1298 -2576 -1298 -2579 0 -3 463 -6 1029 -6
l1030 0 435 863 c239 474 600 1191 802 1592 l368 730 -528 980 c-290 539 -530
983 -534 987 -4 4 -591 -1151 -1304 -2567z'
      />
      <path d='M6815 8118 c-290 -540 -528 -987 -528 -993 0 -7 359 -726 799 -1598
l799 -1587 1033 0 1032 0 -23 48 c-303 602 -2572 5099 -2576 5104 -4 5 -245
-434 -536 -974z'
      />
      <path d='M372 8372 c-193 -384 -352 -702 -352 -705 0 -4 892 -6 1983 -5 l1982
3 353 703 353 702 -1983 0 -1983 0 -353 -698z'
      />
      <path d='M8194 8368 l355 -703 1316 -3 1317 -2 -354 702 -355 703 -1317 3
-1317 2 355 -702z'
      />
      <path d='M1632 6858 c-104 -205 -393 -778 -642 -1273 -249 -495 -538 -1068
-642 -1272 l-189 -373 974 0 975 0 826 1640 c454 902 826 1642 826 1645 0 3
-436 5 -969 5 l-970 0 -189 -372z'
      />
      <path d='M9146 6500 l368 -730 1313 0 c722 0 1313 3 1313 6 0 3 -165 332 -366
730 l-365 724 -1315 0 -1316 0 368 -730z'
      />
      <path d='M9720 5344 c0 -3 159 -320 353 -705 l352 -699 1313 0 c721 0 1312 3
1312 6 0 4 -158 321 -352 705 l-352 699 -1313 0 c-722 0 -1313 -3 -1313 -6z'
      />
    </g>
  </SvgIcon>

export default TencentLogo

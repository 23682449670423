import type { Components } from '@mui/material'

const MuiToolbar: Components['MuiToolbar'] = {
  styleOverrides: {
    root: {
      padding: 0,
    },
  },
}

export default MuiToolbar

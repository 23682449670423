import type { Components } from '@mui/material'

import SPACING from 'src/styles/spacing'

const MuiSelect: Components['MuiSelect'] = {
  defaultProps: {
    variant: 'filled',
  },
  styleOverrides: {
    filled: {
      height: SPACING.INPUT_HEIGHT_DENSE,
      lineHeight: 2.6,
      paddingRight: `${SPACING.addPixels(SPACING.INPUT_SIDE_PADDING, -SPACING.INPUT_BORDER_WIDTH)}`,
      paddingLeft: `${SPACING.addPixels(SPACING.INPUT_SIDE_PADDING, -SPACING.INPUT_BORDER_WIDTH)}`,
    },
  },
}

export default MuiSelect

/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import 'src/styles/Custom/cookielaw.css'

import { Avatar, Button, Card, Skeleton, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import { getBandsintownArtist } from 'src/api/links'
import BandsintownIconDark from 'src/components/Icons/BandsintownDark'
import BandsintownIconLight from 'src/components/Icons/BandsintownIconLight'
import RenderBandsintownItem from 'src/components/pages/SmartLink/Content/BioLinkContent/BandsInTownItem'
import RenderAllBandsintownEvents from 'src/components/pages/SmartLink/Content/BioLinkContent/RenderAllBandsintownEvents'
import type { BandsintownArtist, BandsintownEvents, MarketingLink } from 'src/models/Marketing'
import * as darkTheme from 'src/styles/dark.theme'
import * as lightTheme from 'src/styles/light.theme'

type Props = {
  link: MarketingLink
  embedUrl: string
}

const RenderBandsintown = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [events, setEvents] = useState<BandsintownEvents[] | null | undefined>(null)
  const [artist, setArtist] = useState<BandsintownArtist | null | undefined>(null)
  const [openDrawer, setOpenDrawer] = useState(false)

  const activeTheme = props.link.palette === 'Light'
    ? lightTheme
    : darkTheme

  const skeletonColor = props.link.palette === 'Light'
    ? 'rgba(17, 24, 39, 0.11)'
    : 'rgba(256, 256, 256, 0.25)'

  useEffect(() => {
    setLoading(true)
    void getBandsintownArtist(props.embedUrl)
      .then(event => {
        setArtist(event.artist)
        setEvents(event.events)
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }, [props.embedUrl])

  return (
    <>
      <Card
        elevation={0}
        sx={{
          background: activeTheme.default.palette.background.default,
          width: 1,
        }}
      >
        <Stack alignItems='center' direction='row' paddingTop={2} paddingX={2} spacing={1} width={1}>
          {loading
            ? <Skeleton
              height={48}
              sx={{
                backgroundColor: skeletonColor,
                flexShrink: 0,
              }}
              variant='circular'
              width={48}
            />
            : <Avatar
              src={artist?.image_url}
              sx={{
                width: 48,
                height: 48,
              }}
            />}

          {loading &&
          <Stack direction='column' width={1}>
            <Skeleton
              sx={{
                backgroundColor: skeletonColor,
              }}
              variant='rounded'
            >
              <Typography
                color={activeTheme.default.palette.text.primary}
                fontSize='1.2em'
                lineHeight={1.2}
                textAlign='left'
                variant='h3'
              >
                This is an artist
              </Typography>
            </Skeleton>

            <Skeleton
              height={12}
              sx={{
                backgroundColor: skeletonColor,
                marginTop: 0.5,
              }}
              variant='rounded'
            >
              <Typography
                color={activeTheme.default.palette.text.secondary}
                variant='body1'
              >
                This is an artist description
              </Typography>
            </Skeleton>
          </Stack>}

          {artist && !loading &&
          <Stack direction='column' width={1}>
            <Typography
              color={activeTheme.default.palette.text.primary}
              fontSize='1.25em'
              lineHeight={1.2}
              textAlign='left'
              variant='h3'
            >
              {artist.name}
            </Typography>
            <Typography
              color={activeTheme.default.palette.text.secondary}
              variant='body2'
            >
              {artist.tracker_count.toLocaleString()}
              {' Followers'}
              {' · '}
              {artist.upcoming_event_count.toLocaleString()}
              {' Concerts'}
            </Typography>
          </Stack>}
        </Stack>

        <Stack alignItems='center' direction='column' justifyContent='center' padding={2} spacing={2}>
          {loading && [...Array.from({ length: 5 })].map((_item, index) =>
            <Skeleton
              height={54}
              key={`${index.toString()}`}
              sx={{
                backgroundColor: skeletonColor,
              }}
              variant='rounded'
              width='100%'
            />)}

          <Button
            fullWidth
            href={artist?.url}
            rounded
            sx={{
              color: activeTheme.default.palette.text.primary,
              borderColor: activeTheme.default.palette.text.primary,
              ':hover': {
                color: '#00CEC8',
                borderColor: '#00CEC8',
                background: 'transparent',
              },
            }}
            variant='outlined'
          >
            Follow on Bandsintown
          </Button>

          {!loading && artist && events?.slice(0, 5).map(event =>
            <RenderBandsintownItem
              artist={artist}
              event={event}
              key={event.id}
              link={props.link}
            />)}

          {!loading && (!events || (events.length === 0)) &&
            <Typography color={activeTheme.default.palette.text.secondary} textAlign='center' variant='body1'>
              No upcoming events scheduled
            </Typography>}
        </Stack>

        <Stack
          alignItems='center'
          justifyContent='center'
          paddingBottom={2}
          paddingX={2}
          spacing={1}
        >
          {artist && !loading && events && events.length > 0 &&
          <Button
            color='secondary'
            onClick={() => setOpenDrawer(true)}
            size='small'
            sx={{
              color: '#00CEC8',
            }}
            variant='text'
          >
            View all events
          </Button>}

          <Stack alignItems='center' direction='row'>
            <Typography
              color={activeTheme.default.palette.text.primary}
              textAlign='center'
              variant='body1'
            >
              Powered by
            </Typography>
            {props.link.palette === 'Light'
              ? <BandsintownIconLight
                sx={{
                  fontSize: 32,
                  width: 164,
                }}
              />
              : <BandsintownIconDark
                sx={{
                  fontSize: 32,
                  width: 164,
                }}
              />}
          </Stack>
        </Stack>
      </Card>
      {events && artist &&
      <RenderAllBandsintownEvents
        artist={artist}
        close={() => setOpenDrawer(false)}
        events={events}
        link={props.link}
        onOpen={() => setOpenDrawer(true)}
        open={openDrawer}
      />}
    </>
  )
}

export default RenderBandsintown

/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const VevoLogo = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='-750 -50 1508.2414 380'>
    <path
      d='M 559.57425,-61.378572 A 189.99997,189.99997 0 1 0 749.57414,128.62141 189.99997,189.99997 0 0 0 559.57425,-61.378572 Z m 0,278.666642 c -47.5038,0 -82.33333,-37.99999 -82.33333,-88.66666 0,-50.666659 34.8593,-88.666656 82.33333,-88.666656 47.49999,0 82.33326,38.007597 82.33326,88.666656 0,50.65907 -34.83327,88.66666 -82.33326,88.66666 z M 2.2409572,128.62141 c 0,-104.934455 -82.2300292,-189.999982 -183.6665872,-189.999982 -101.43656,0 -183.66665,85.065527 -183.66665,189.999982 0,104.93446 82.32129,189.99999 186.83332,189.99999 83.476489,0 148.93902,-50.45386 170.7161846,-120.33333 l -110.5495246,0 c -15.3368,22.69234 -37.8879,28.5 -60.16666,28.5 -42.72783,0 -69.95483,-29.41073 -78.4985,-69.66666 l 256.93565067,0 a 197.93247,197.93247 0 0 0 2.06276653,-28.5 z M -181.42563,27.288088 c 36.6472,0 65.04903,25.245298 73.75609,63.333329 l -147.65785,0 c 9.85656,-39.592831 36.0981,-63.333329 73.90176,-63.333329 z m -367.33331,288.166642 -209.90815,-365.749969 123.58606,0 86.32209,166.249989 86.32333,-166.249989 123.58549,0 z m 734.66655,0 -209.908184,-365.749969 123.586758,0 86.321426,166.249989 86.32333,-166.249989 123.58675,0 z'
    />
  </SvgIcon>

export default VevoLogo

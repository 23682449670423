
import type { ThemeOptions } from '@mui/material/styles'

import SPACING from './spacing'

const baseShape: ThemeOptions['shape'] = {
  borderRadius: SPACING.BORDER_RADIUS,
  inputRadius: SPACING.INPUT_RADIUS,
}

export default baseShape

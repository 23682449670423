import type { Components } from '@mui/material'

const MuiSvgIcon: Components['MuiSvgIcon'] = {
  styleOverrides: {
    root: {
      verticalAlign: 'middle',
    },
    fontSizeLarge: {
      fontSize: '1.875rem', // 30px
    },
    fontSizeMedium: {
      fontSize: '1.5rem', // 24px
    },
    fontSizeSmall: {
      fontSize: '1.125rem', // 18px
    },
    fontSizeTiny: {
      fontSize: '1rem', // 16px
    },
  },
  defaultProps: {
    fontSize: 'inherit',
  },
}

export default MuiSvgIcon

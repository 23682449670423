/* eslint-disable unicorn/filename-case */
/* eslint-disable unicorn/prevent-abbreviations */
/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const EmusicLogo = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='0 0 144 40' >
    <g fill='none' fillRule='evenodd' id='Web-UI-Sheet' stroke='none' strokeWidth='1'>
      <g id='Triplay_eMusic_Wordmark_CMYK-Copy-4' transform='translate(-579.000000, -40.000000)'>
        <g transform='translate(579.000000, 40.000000)'>
          <path d='M135.332237,10.2863636 L131.89557,10.2863636 C127.442068,10.2863636 123.819015,13.9134091 123.819015,18.3718182 L123.819015,31.6906818 C123.819015,36.1490909 127.442068,39.7761364 131.89557,39.7761364 L135.332237,39.7761364 C139.785739,39.7761364 143.409019,36.1490909 143.409019,31.6906818 L143.409019,30.9868182 L138.414523,30.9868182 L138.414523,31.6906818 C138.414523,33.3920455 137.031728,34.7761364 135.332237,34.7761364 L131.89557,34.7761364 C130.196079,34.7761364 128.813511,33.3920455 128.813511,31.6906818 L128.813511,18.3718182 C128.813511,16.6704545 130.196079,15.2863636 131.89557,15.2863636 L135.332237,15.2863636 C137.031728,15.2863636 138.414523,16.6704545 138.414523,18.3718182 L138.414523,19.0834091 L143.409019,19.0834091 L143.409019,18.3718182 C143.409019,13.9134091 139.785739,10.2863636 135.332237,10.2863636' fill='#FFFFFF' id='Fill-1' />
          <path d='M78.1388965,29.2825 L78.1388965,31.6877273 C78.1388965,33.3890909 76.7563291,34.7731818 75.0568382,34.7731818 L71.6201706,34.7731818 C69.9206797,34.7731818 68.5378853,33.3890909 68.5378853,31.6877273 L68.5378853,10.2831818 L63.5433888,10.2831818 L63.5433888,31.6877273 C63.5433888,36.1461364 67.166669,39.7731818 71.6201706,39.7731818 L78.1388965,39.7731818 L78.1388965,39.7763636 L83.133393,39.7763636 L83.133393,10.2831818 L78.1388965,10.2831818 L78.1388965,29.2825' fill='#FFFFFF' id='Fill-2' />
          <path d='M98.4079252,10.2822727 L95.3126995,10.2822727 C90.8591979,10.2822727 87.2470418,13.9009091 87.2470418,18.3595455 C87.2470418,23.0656818 90.8360416,24.7318182 94.3069895,26.3431818 C94.693609,26.5225 95.0940768,26.7084091 95.5063498,26.9047727 L95.5531164,26.9265909 C95.8366676,27.0545455 96.1125,27.1784091 96.3808407,27.2988636 C100.974869,29.3588636 101.501335,29.8120455 101.501335,31.7070455 C101.501335,33.4084091 100.11854,34.7925 98.4188222,34.7925 L95.3238236,34.7925 C93.6241057,34.7925 92.2415382,33.4084091 92.2415382,31.7070455 L92.2415382,30.9822727 L87.2470418,30.9822727 L87.2470418,31.7070455 C87.2470418,36.1654545 90.8700949,39.7761364 95.3238236,39.7761364 L98.4188222,39.7761364 C102.872551,39.7761364 106.495831,36.1654545 106.495831,31.7070455 C106.495831,26.3559091 102.754499,24.6781818 98.4226816,22.7356818 C98.1654651,22.6204545 97.9012108,22.5018182 97.6299188,22.3793182 C97.2101541,22.1793182 96.8021946,21.99 96.4080834,21.8070455 C92.8258943,20.1438636 92.2415382,19.6606818 92.2415382,18.3595455 C92.2415382,16.6579545 93.6241057,15.2736364 95.3238236,15.2736364 L98.4188222,15.2736364 C100.11854,15.2736364 101.501335,16.6579545 101.501335,18.3595455 L101.501335,19.0793182 L106.495831,19.0793182 L106.495831,18.3595455 C106.495831,13.9009091 102.861427,10.2822727 98.4079252,10.2822727' fill='#FFFFFF' id='Fill-3' />
          <polyline fill='#FFFFFF' id='Fill-4' points='109.682093 15.2829545 114.010278 15.2829545 114.010278 39.7761364 119.004774 39.7761364 119.004774 10.2829545 109.682093 10.2829545 109.682093 15.2829545' />
          <polygon fill='#FFFFFF' id='Fill-5' points='113.341015 5.32636364 119.004774 5.32636364 119.004774 0.223636364 113.341015 0.223636364' />
          <path d='M50.8158228,10.2956818 L47.3791552,10.2956818 C45.2188085,10.2956818 43.2555173,11.1509091 41.8048431,12.5386364 C40.3530338,11.1454545 38.3856563,10.2865909 36.2207691,10.2865909 L29.7020432,10.2865909 L29.7020432,10.2831818 L24.7075468,10.2831818 L24.7075468,39.7763636 L29.7020432,39.7763636 L29.7020432,18.3720455 C29.7020432,16.6706818 31.0846106,15.2865909 32.7841015,15.2865909 L36.2207691,15.2865909 C37.9188979,15.2865909 39.3005572,16.6686364 39.3028275,18.3681818 C39.3028275,18.3725 39.3023734,18.3768182 39.3023734,18.3813636 L39.3023734,39.7763636 L44.2968698,39.7763636 L44.2975509,18.3720455 L44.2975509,18.3713636 C44.3029994,16.6745455 45.6830696,15.2956818 47.3791552,15.2956818 L50.8158228,15.2956818 C52.5153137,15.2956818 53.8978811,16.6797727 53.8978811,18.3813636 L53.8978811,39.7763636 L58.8923775,39.7763636 L58.8923775,18.3813636 C58.8923775,13.9229545 55.2693244,10.2956818 50.8158228,10.2956818' fill='#FFFFFF' id='Fill-6' />
          <path d='M5.0637383,17.595 C5.0637383,16.2836364 6.12960925,15.2165909 7.43975647,15.2165909 L11.9606838,15.2165909 C13.270831,15.2165909 14.336702,16.2836364 14.336702,17.595 L14.336702,18.2563636 L5.0637383,18.2563636 L5.0637383,17.595 L5.0637383,17.595 Z M14.3040107,25.245 C14.1233008,26.3781818 13.1427903,27.2479545 11.9606838,27.2479545 L7.43975647,27.2479545 C6.12960925,27.2479545 5.0637383,26.1809091 5.0637383,24.8693182 L5.0637383,22.8729545 L19.3311984,22.8729545 L19.3311984,17.6618182 C19.3311984,13.5931818 16.0248418,10.2831818 11.9606838,10.2831818 L7.43975647,10.2831818 C3.37582554,10.2831818 0.0692418822,13.5931818 0.0692418822,17.6618182 L0.0692418822,24.8070455 C0.0692418822,28.8754545 3.37582554,32.1854545 7.43975647,32.1854545 L11.9606838,32.1854545 C15.8988442,32.1854545 19.125516,29.1359091 19.3216635,25.245 L14.3040107,25.245 L14.3040107,25.245 Z' fill='#FFFFFF' id='Fill-7' />
        </g>
      </g>
    </g>
  </SvgIcon>

export default EmusicLogo

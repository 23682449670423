/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const BandsintownIconDark = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='0 0 749 153'>
    <defs><style>{'.cls-1{fill:#ffffff!important;}.cls-2{fill:#00cec8!important;}'}</style></defs>
    <path className='cls-1' d='M181.63,99.8h-.2v6.65h-9.59V37.92h9.59v30h.2c2.84-4.89,7.83-8.12,15.27-8.12,11.94,0,22,9,22,24.08s-10.09,24.08-22,24.08C189.46,107.92,184.47,104.69,181.63,99.8Zm27.11-16c0-8.62-5.48-14.69-13.9-14.69s-13.9,6.07-13.9,14.69,5.48,14.68,13.9,14.68S208.74,92.45,208.74,83.84Z' />
    <path className='cls-1' d='M225.5,83.84c0-15.08,10.08-24.08,22-24.08C255,59.76,260,63,262.8,67.88H263V61.22h9.6v45.23H263V99.8h-.19c-2.84,4.89-7.83,8.12-15.27,8.12C235.58,107.92,225.5,98.91,225.5,83.84Zm38,0c0-8.62-5.49-14.69-13.91-14.69s-13.9,6.07-13.9,14.69,5.48,14.68,13.9,14.68S263.49,92.45,263.49,83.84Z' />
    <path className='cls-1' d='M325.79,78.65v27.8H316.2V80.31c0-7.73-3.92-11-10.38-11-7.05,0-12,4.21-12,15.47v21.63h-9.59V61.22h9.59v6.86h.2c2.93-5.19,7.83-8.32,14.78-8.32C318.45,59.76,325.79,64.94,325.79,78.65Z' />
    <path className='cls-1' d='M334.47,83.84c0-15.08,10.09-24.08,22-24.08,7.44,0,12.43,3.23,15.27,8.12h.2v-30h9.59v68.53H372V99.8h-.2c-2.84,4.89-7.83,8.12-15.27,8.12C344.56,107.92,334.47,98.91,334.47,83.84Zm38,0c0-8.62-5.49-14.69-13.9-14.69s-13.91,6.07-13.91,14.69,5.49,14.68,13.91,14.68S372.46,92.45,372.46,83.84Z' />
    <path className='cls-1' d='M389.24,103.12l4.31-8.51a28.62,28.62,0,0,0,14.39,4.3c4.6,0,7.05-1.37,7.05-4.2,0-1.86-1-3.23-4.8-5.09l-8.22-4c-6.85-3.23-9.5-7.83-9.5-13,0-8.32,6.17-12.82,16.06-12.82a39.57,39.57,0,0,1,15.66,3.52l-3.91,8.32a31.16,31.16,0,0,0-12-2.94c-4.21,0-5.58,1.37-5.58,3.43s1.28,3.53,5.19,5.39l8.42,4c5.88,2.74,9,6.66,9,12.73,0,9.2-7,13.7-17.62,13.7A37.59,37.59,0,0,1,389.24,103.12Z' />
    <path className='cls-1' d='M433.71,61.22h9.6v45.23h-9.6Z' />
    <circle className='cls-1' cx='438.51' cy='44.78' r='5.83' />
    <path className='cls-1' d='M496.68,78.65v27.8h-9.59V80.31c0-7.73-3.92-11-10.38-11-7,0-12,4.21-12,15.47v21.63h-9.6V61.22h9.6v6.86h.19c2.94-5.19,7.84-8.32,14.79-8.32C489.34,59.76,496.68,64.94,496.68,78.65Z' />
    <path className='cls-1' d='M520.26,69.35V93c0,3.23,1.37,4.7,4.7,4.7h2.35v8.81H522.9c-7.93,0-12.23-4.21-12.23-11.65V69.35h-8V61.22h8V43.11h9.59V61.22h9.2v8.13Z' />
    <path className='cls-1' d='M582.52,83.84c0,13.9-10.57,24.08-24.77,24.08S533,97.74,533,83.84s10.58-24.08,24.77-24.08S582.52,69.94,582.52,83.84Zm-39.35,0c0,8.91,6,14.68,14.58,14.68s14.59-5.77,14.59-14.68-6-14.69-14.59-14.69S543.17,74.93,543.17,83.84Z' />
    <path className='cls-1' d='M654.12,61.22,639,106.45h-8.82l-10.76-32.4-10.87,32.4h-8.81L584.51,61.22h10.58l9.1,32.6,10.48-32.6H624l10.47,32.6,9.11-32.6Z' />
    <path className='cls-1' d='M702.16,78.65v27.8h-9.59V80.31c0-7.73-3.92-11-10.38-11-7.05,0-12,4.21-12,15.47v21.63h-9.59V61.22h9.59v6.86h.2c2.93-5.19,7.83-8.32,14.78-8.32C694.82,59.76,702.16,64.94,702.16,78.65Z' />
    <rect className='cls-2' height='26.67' width='20' x='73.5' y='46.55' />
    <rect className='cls-2' height='26.67' width='20' x='100.17' y='46.55' />
    <rect className='cls-2' height='46.67' width='20' x='126.84' y='26.55' />
    <polygon className='cls-2' points='73.5 79.88 73.5 99.88 126.84 99.88 126.84 106.55 66.84 106.55 66.84 26.55 46.84 26.55 46.84 126.55 146.84 126.55 146.84 79.88 73.5 79.88' />
  </SvgIcon>

export default BandsintownIconDark

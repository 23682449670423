/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable react/jsx-child-element-spacing */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Avatar, Button, Card, CardMedia, Checkbox, Chip, darken, FormControl, FormControlLabel, IconButton, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'

import { createMarketingEvent } from 'src/api/links'
import { getBasicOrganisation } from 'src/api/organisation'
import { getAmazonSubscribeLink, getDeezerSubscribeLink, getSpotifySubscribeLink } from 'src/api/subscribe'
import { GetColorLink } from 'src/components/pages/SmartLink/Content/Functions/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/Functions/GetIcon'
import DialogDrawer from 'src/components/pages/SmartLink/DialogDrawer/DialogDrawer'
import type { MarketingLink } from 'src/models/Marketing'
import { MarketingEvent } from 'src/models/Marketing'
import type { BasicOrganisation } from 'src/models/Organisation'
import * as darkTheme from 'src/styles/dark.theme'
import * as lightTheme from 'src/styles/light.theme'

type Props = {
  close: () => void
  openDrawer: () => void
  open: boolean
  link: MarketingLink | undefined
  url: string
  browserName: string
  operatingSystem: string
  country?: string
  city?: string
  referrer: string
  presaveDestination?: string
}

const PresaveConsent = (props: Props) => {
  const [subscribeToAllReleases, setSubscribeToAllReleases] = useState(
    !!props.presaveDestination &&
    props.presaveDestination !== 'Apple Music' &&
    props.link?.foreverPresave === true
  )
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [organisation, setOrganisation] = useState<BasicOrganisation | null>()

  useEffect(() => {
    if (!!props.presaveDestination &&
      props.presaveDestination !== 'Apple Music' &&
      props.link?.foreverPresave === true) {
      setSubscribeToAllReleases(true)
    }
  }, [props.presaveDestination, props.link?.foreverPresave])

  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const activeTheme = props.link?.palette === 'Dark'
    ? darkTheme
    : lightTheme

  const clickButton = () => {
    if (subscribeToAllReleases) {
      // eslint-disable-next-line unicorn/prefer-switch
      if (props.presaveDestination === 'Spotify') {
        void spotifySubscribe()
      } else if (props.presaveDestination === 'Amazon') {
        void amazonSubscribe()
      } else if (props.presaveDestination === 'Deezer') {
        void deezerSubscribe()
      }
    } else {
      void createMarketingEvent(new MarketingEvent({
        type: 'Click',
        marketingLinkId: props.link?.id,
        browser: props.browserName,
        operatingSystem: props.operatingSystem,
        country: props.country,
        city: props.city,
        referrer: props.referrer,
        destination: props.presaveDestination,
      }))
      if (props.presaveDestination === 'Spotify') {
        const urlParams = new URLSearchParams(props.url.replace('https://accounts.spotify.com/authorize', ''))
        const spotifyAccountAuthorizeLink = props.url
        const redirectUri = 'https%3A%2F%2Fapi.releese.io%2Fapi%2Fspotify%2Fcallback'
        const scopes = urlParams.get('scope')
        const clientId = urlParams.get('client_id')
        const state = urlParams.get('state')

        if (isAndroid) {
          const intentLocationAndroid = `intent://accounts.spotify.com/inapp-authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${scopes}&state=${state}#Intent;package=com.spotify.music;scheme=https;end`
          window.location.replace(intentLocationAndroid)
        } else if (isIOS) {
          const intentLocationIOS = `spotify-action://authorize?cc=0&response_type=code&client_id=${clientId}&scope=${scopes}&redirect_uri=${redirectUri}&state=${state}`
          window.location.replace(intentLocationIOS)
        } else {
          window.location.href = spotifyAccountAuthorizeLink
        }
      } else {
        window.location.replace(props.url)
      }
    }
  }

  const handleCheckboxTerms = () => {
    setAcceptTerms(!acceptTerms)
  }

  const handleCheckboxSubscribe = () => {
    setSubscribeToAllReleases(!subscribeToAllReleases)
  }

  const spotifySubscribe = async () => {
    if (props.link?.organisationId && props.link.id) {
      await getSpotifySubscribeLink(props.link.organisationId, props.link.id)
        .then(link => {
          const urlParams = new URLSearchParams(link.replace('https://accounts.spotify.com/authorize', ''))
          const spotifyAccountAuthorizeLink = link
          const redirectUri = 'https%3A%2F%2Fapi.releese.io%2Fapi%2Fsubscribe%2Fspotify'
          const scopes = urlParams.get('scope')
          const clientId = urlParams.get('client_id')
          const state = urlParams.get('state')

          if (isAndroid) {
            const intentLocationAndroid = `intent://accounts.spotify.com/inapp-authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${scopes}&state=${state}#Intent;package=com.spotify.music;scheme=https;end`
            window.location.replace(intentLocationAndroid)
          } else if (isIOS) {
            const intentLocationIOS = `spotify-action://authorize?cc=0&response_type=code&client_id=${clientId}&scope=${scopes}&redirect_uri=${redirectUri}&state=${state}`
            window.location.replace(intentLocationIOS)
          } else {
            window.location.href = spotifyAccountAuthorizeLink
          }
        })
    }
  }

  const deezerSubscribe = async () => {
    if (props.link?.organisationId && props.link.id) {
      await getDeezerSubscribeLink(props.link.organisationId, props.link.id)
        .then(link => {
          window.location.href = link
        })
    }
  }

  const amazonSubscribe = async () => {
    if (props.link?.organisationId && props.link.id) {
      await getAmazonSubscribeLink(props.link.organisationId, props.link.id)
        .then(link => {
          window.location.href = link
        })
    }
  }

  useEffect(() => {
    if (props.link?.organisationId) {
      void getBasicOrganisation(props.link.organisationId)
        .then(setOrganisation)
    }
  }, [props.link?.organisationId])

  return (
    <DialogDrawer
      close={props.close}
      isOpen={props.open}
      link={props.link}
      open={props.openDrawer}
    >
      <Stack paddingX={3} paddingY={3} spacing={2} width={1}>
        <Stack alignItems='center' spacing={2} width='100%'>
          <Stack alignItems='center' width='100%'>
            {!fullScreen &&
            <Stack alignItems='flex-end' width={1}>
              <IconButton
                onClick={() => props.close()}
                sx={{
                  width: 'fit-content',
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </Stack>}
            <Typography
              sx={{
                color: activeTheme.default.palette.text.primary,
                textAlign: 'center',
              }}
              variant='h3'
            >
              {`Presave ${props.link?.title}`}
            </Typography>
            <Typography
              sx={{
                color: activeTheme.default.palette.text.secondary,
              }}
              textAlign='center'
              variant='body1'
            >
              {`${props.link?.title} will be saved to your
library on ${dayjs(props.link?.releaseDate).format('LL')}`}
            </Typography>
          </Stack>
          {props.link?.artworkUrl
            ? <Card
              elevation={0}
              sx={{
                borderRadius: 0,
                background: 'transparent',
                height: 300,
                width: 300,
                padding: 0,
              }}
            >
              <CardMedia
                alt={`${props.link.artworkUrl ? 'Invalid' : 'Missing'} artwork`}
                component='img'
                height={300}
                image={props.link.artworkUrl}
                sx={{
                  borderRadius: 1,
                  width: '300px!important',
                  height: '300px!important',
                }}
                width={300}
              />
            </Card>
            : <Card
              elevation={0}
              sx={{
                borderRadius: 1,
                background: activeTheme.default.palette.background.input,
                height: 300,
                width: 300,
              }}
            >
              <MusicNoteRoundedIcon
                sx={{
                  fontSize: 300,
                  color: activeTheme.default.palette.action.disabled,
                }}
              />
            </Card>}
          {props.presaveDestination !== 'Apple Music' && props.link?.foreverPresave === true &&
          <Stack alignItems='center' width='100%'>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={subscribeToAllReleases}
                    onChange={handleCheckboxSubscribe}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                  />
                }
                label={<Typography
                  sx={{
                    color: activeTheme.default.palette.text.label,
                  }}
                  variant='helperText'
                >
                  {`I want to subscribe to ${organisation?.name} on Releese and agree to Pre-save all their future releases `}
                </Typography>}
              />
            </FormControl>
          </Stack>}
          <Stack alignItems='center' width='100%'>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptTerms}
                    onChange={handleCheckboxTerms}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                  />
                }
                label={<Typography
                  sx={{
                    color: activeTheme.default.palette.text.label,
                  }}
                  variant='helperText'
                >
                  {`I agree to share my email and personal information for marketing purposes to ${organisation?.name} 
                  and accept the `}
                  <Link href='https://releese.io/legal/terms-and-conditions' target='_blank'>
                    Terms and conditions
                  </Link>
                  {' and the '}
                  <Link href='https://releese.io/legal/privacy-policy' target='_blank'>
                    Privacy policy.
                  </Link>
                </Typography>}
              />
            </FormControl>
          </Stack>
          {props.link &&
          props.presaveDestination === 'Spotify' &&
          props.link.spotifyFollowArtists.length > 0 &&
          <Stack alignItems='center' width='100%'>
            <Stack width={1}>
              <Typography
                sx={{
                  color: activeTheme.default.palette.text.label,
                }}
                variant='body2'
              >
                You will also follow the following artists
              </Typography>
              <Stack direction='row' flexWrap='wrap' width={1}>
                {props.link.spotifyFollowArtists.map(item =>
                  <Chip
                    avatar={<Avatar src={item.images?.[0].url} />}
                    clickable
                    component='a'
                    href={`https://open.spotify.com/artist/${item.uri.replace('spotify:artist:', '')}`}
                    key={item.uri}
                    label={item.name}
                    rounded
                    sx={{
                      width: 'fit-content',
                      background: activeTheme.default.palette.background.input,
                      color: activeTheme.default.palette.text.label,
                      marginTop: 0.5,
                      marginRight: 0.5,
                      borderRadius: 2,
                      border: `1px solid ${activeTheme.default.palette.background.input}!important`,
                    }}
                    target='_blank'
                    variant='filled'
                  />)}
              </Stack>
            </Stack>
          </Stack>}
        </Stack>
        <Button
          color='success'
          disabled={!acceptTerms}
          onClick={() => clickButton()}
          startIcon={GetIcon(props.presaveDestination ?? '')}
          sx={{
            backgroundColor: GetColorLink(props.presaveDestination ?? ''),
            ':hover': {
              backgroundColor: darken(GetColorLink(props.presaveDestination ?? ''), 0.2),
            },
          }}
        >
          Pre-save on
          {` ${props.presaveDestination}`}
        </Button>
      </Stack>
    </DialogDrawer>
  )
}

export default PresaveConsent

import { createTheme } from '@mui/material/styles'
import type { Theme as SystemTheme } from '@mui/system'

const PX_START = -2
const addPixels = (a: number | string, b: number | string) => {
  const aValue = typeof a === 'string' ? Number.parseInt(a.slice(0, PX_START)) : a
  const bValue = typeof b === 'string' ? Number.parseInt(b.slice(0, PX_START)) : b

  return `${aValue + bValue}px`
}
const borderStyle = (theme: SystemTheme | { palette: { border: string } }, style = 'solid') =>
  `${SPACING.INPUT_BORDER_WIDTH}px ${style} ${theme.palette.border as string}`

// Uses default spacing
const THEME_SPACING = 8
const helperTheme = createTheme({ spacing: THEME_SPACING })
const themeSpacing = helperTheme.spacing
const createThemetransition = helperTheme.transitions.create

const BORDER_RADIUS = 2 * THEME_SPACING // 16
const INPUT_RADIUS = `${THEME_SPACING}px` // 8px
const INPUT_HEIGHT = 6 * THEME_SPACING // 40px
const INPUT_HEIGHT_DENSE = 5 * THEME_SPACING // 40
const INPUT_SIDE_PADDING = themeSpacing(1.5) // 12px
const INPUT_VERT_PADDING_LARGE = themeSpacing(2.5) // 20px
const INPUT_SIDE_PADDING_LARGE = themeSpacing(4) // 32px
const INPUT_BORDER_WIDTH = 1
const MULTILINE_TOP_PADDING = Number.parseInt(INPUT_VERT_PADDING_LARGE) - INPUT_BORDER_WIDTH
const MULTILINE_BOTTOM_PADDING = (INPUT_HEIGHT - MULTILINE_TOP_PADDING) / 2
const RESPONSIVE_CONTOUR = { xl: 2, lg: 2, md: 2, sm: 0, xs: 0 } as const
const DRAWER_TOP_PADDING = { xl: themeSpacing(8), lg: themeSpacing(8), md: themeSpacing(8), sm: 0, xs: 0 } as const
const SPACING = {
  addPixels,
  themeSpacing,
  createThemetransition,
  borderStyle,
  RESPONSIVE_CONTOUR,
  BORDER_RADIUS,
  INPUT_RADIUS,
  INPUT_HEIGHT,
  INPUT_HEIGHT_DENSE,
  INPUT_SIDE_PADDING,
  INPUT_VERT_PADDING_LARGE,
  INPUT_SIDE_PADDING_LARGE,
  INPUT_BORDER_WIDTH,
  MULTILINE_TOP_PADDING,
  MULTILINE_BOTTOM_PADDING,
  DRAWER_TOP_PADDING,
}

export default SPACING

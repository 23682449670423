import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const SoundCloudIcon = (props: SvgIconProps) =>
  <SvgIcon {...props}>
    <path d='M 14.5 6 C 12.601563 6 11 6.90625 10 8.40625 L 10 17 L 20.5 17 C
  22.398438 17 24 15.398438 24 13.5 C 24 11.601563 22.398438 10 20.5 10 C
  20.300781 10 20.011719 9.992188 19.8125 10.09375 C 19.210938 7.695313 17 6
  14.5 6 Z M 8 8 L 8 17 L 9 17 L 9 8.09375 C 8.699219 7.992188 8.300781 8 8 8
  Z M 7 8.09375 C 6.601563 8.195313 6.300781 8.398438 6 8.5 L 6 17 L 7 17 Z M
  5 9.40625 C 4.5 9.90625 4.195313 10.488281 4.09375 11.1875 L 4 11.1875 L 4
  17 L 5 17 Z M 3 11 C 2.601563 11 2.300781 11.085938 2 11.1875 L 2 16.8125 C
  2.300781 16.914063 2.601563 17 3 17 Z M 1 11.8125 C 0.398438 12.3125 0 13.101563
  0 14 C 0 14.898438 0.398438 15.6875 1 16.1875 Z'
    />
  </SvgIcon>

export default SoundCloudIcon

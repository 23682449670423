import type { Components } from '@mui/material'

const MuiStepLabel: Components['MuiStepLabel'] = {
  styleOverrides: {
    iconContainer: {
      fontSize: '2em',
    },
  },
}

export default MuiStepLabel

/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import 'src/styles/Custom/cookielaw.css'

import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'
import { Box, Drawer, IconButton, Stack } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { browserName, osName } from 'react-device-detect'
import ReactPixel from 'react-facebook-pixel'
import TagManager from 'react-gtm-module'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import TiktokPixel from 'tiktok-pixel'
import { v4 } from 'uuid'

import { createAppleMusicPresave, createMarketingEvent, getMarketingLinkByUrl } from 'src/api/links'
import { getBasicOrganisation } from 'src/api/organisation'
import AllReleases from 'src/components/pages/SmartLink/Content/AllReleases'
import BioLinkContent from 'src/components/pages/SmartLink/Content/BioLinkContent'
import SmartLinkContent from 'src/components/pages/SmartLink/Content/SmartLinkContent'
import PresaveConsent from 'src/components/pages/SmartLink/PresaveConsentModal/PresaveConsent'
import PresaveModal from 'src/components/pages/SmartLink/PresaveModal/PresaveModal'
import { trackingModeGdpr } from 'src/components/pages/SmartLink/PrivacyBanner/CheckPrivacyGdpr'
import PrivacyBanner from 'src/components/pages/SmartLink/PrivacyBanner/PrivacyBanner'
import PreferenceCenter from 'src/components/pages/SmartLink/PrivacyDrawer/PrivacyDrawer'
import SubscribeCompleteModal from 'src/components/pages/SmartLink/SubscribeModal/SubscribeCompleteModal'
import type { MarketingLink } from 'src/models/Marketing'
import { AppleMusicPresave, MarketingEvent } from 'src/models/Marketing'
import type { BasicOrganisation } from 'src/models/Organisation'
import * as darkTheme from 'src/styles/dark.theme'
import * as lightTheme from 'src/styles/light.theme'

export type trackingData = {
  IPv4: string
  country_name: string
  city: string
}

const ReleasePreview = () => {
  const location = useLocation()
  const [link, setLink] = useState<MarketingLink>()
  const widthItem = useRef<HTMLDivElement | null>(null)
  const [width, setWidth] = useState(0)

  useEffect(() => {
    const getDimensions = () => widthItem.current
      ? widthItem.current.offsetWidth
      : 0

    const handleResize = () => {
      setWidth(getDimensions())
    }

    setWidth(getDimensions())

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [widthItem.current])

  const linkUrl = 'https://geolocation-db.com/json/c8a566e0-859f-11ec-b0ca-adb932d26d38'

  const referrerHistory = document.referrer
  const [trackingData, setTrackingData] = useState<trackingData>()
  const [bannerGdprMode, setBannerGdprMode] = useState(false)
  const [consentPerformance, setConsentPerformance] = useState(false)
  const [consentMarketing, setConsentMarketing] = useState(false)
  const [displayConsentBanner, setDisplayConsentBanner] = useState(false)
  const [displayPrivacyPreferenceCenter, setDisplayPrivacyPreferenceCenter] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [subscribedModal, setSubscribedModal] = useState(false)
  const [urlPresave, setUrlPresave] = useState<string>()
  const [presaveDestination, setPresaveDestination] = useState<string>()
  const [presaveConsent, setPresaveConsent] = useState(false)
  const search = useLocation().search
  const status = new URLSearchParams(search).get('status')
  const subscribed = new URLSearchParams(search).get('subscribed')
  const [organisation, setOrganisation] = useState<BasicOrganisation | null>(null)

  useEffect(() => {
    if (status === 'done') {
      setSuccessModal(true)
    }
  }, [status])

  useEffect(() => {
    if (subscribed === 'true') {
      setSubscribedModal(true)
    }
  }, [subscribed])

  const activeTheme = link?.palette === 'Light'
    ? lightTheme
    : darkTheme

  document.addEventListener('musickitloaded', () => {
    // MusicKit global is now defined
    MusicKit.configure({
      developerToken: 'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ijg3TDUyNjM1VjcifQ.eyJpc3MiOiJONUJDM1M3QVBEIiwiZXhwIjoxNzM4MTI5MzE0LCJpYXQiOjE3MjIzNTIzMTR9.jQ1gD8BWsu8O9bAWLeVCXDhacjbuuHcy1dkh7whwP7JuzO_gHBRU2dGl_tb7kOYqKT7e8SKpjteeDIH66w2ogA',
      app: {
        name: 'Releese',
        build: '1978.4.1',
      },
    })
  })

  const appleMusicPresave = async () => {
    if (link) {
      const music = MusicKit.getInstance()

      void createMarketingEvent(new MarketingEvent({
        type: 'Click',
        marketingLinkId: link.id,
        destination: 'Apple Music',
        browser: browserName,
        operatingSystem: osName,
        referrer: referrerHistory,
        uuid: v4(),
      }))

      await music.authorize()
        .then(musicUserToken => {
          void createAppleMusicPresave(new AppleMusicPresave({
            marketingLinkId: link.id,
            accessToken: musicUserToken,
          }))

          window.location.href = `${window.location.href}?status=done`
        })
    }
  }

  const presave = (type: 'Amazon' | 'Apple Music' | 'Deezer' | 'Spotify') => {
    if (link) {
      if (link.tiktokPixel && link.tiktokPixel.length > 0) {
        TiktokPixel.track('ClickButton',
          {
            'content_type': 'product',
            'content_name': type,
            'value': '0',
            'content_id': 0,
          })
      }
      if (link.facebookPixel && link.facebookPixel.length > 0) {
        ReactPixel.track('ViewContent',
          { 'content_name': type })
      }
      switch (type) {
        case 'Apple Music':
          void appleMusicPresave()
          break
        case 'Spotify':
          setPresaveDestination('Spotify')
          setUrlPresave(link.spotifyPresaveUrl)
          setPresaveConsent(true)
          break
        case 'Deezer':
          setPresaveDestination('Deezer')
          setUrlPresave(link.deezerPresaveUrl)
          setPresaveConsent(true)
          break
        case 'Amazon':
          setPresaveDestination('Amazon')
          setUrlPresave(`https://www.amazon.com/ap/oa?client_id=amzn1.application-oa2-client.f75a9018d47e41019cdcfb52c517f79b&scope=profile&response_type=code&state=${link.id}&redirect_uri=https://api.releese.io/api/amazon/webhook`)
          setPresaveConsent(true)
          break
        default:
          window.location.href = 'https://releese.io'
          break
      }
    }
  }

  const acceptAllCookies = () => {
    setConsentPerformance(true)
    setConsentMarketing(true)
  }

  const declineAllCookies = () => {
    setConsentPerformance(false)
    setConsentMarketing(false)
  }

  const setupInital = async () => {
    await getMarketingLinkByUrl(window.location.href.split('?')[0])
      .then(async linkItem => {
        if (!linkItem) {
          window.location.href = 'https://releese.io'
        }
        setLink(linkItem)
        const eventId = v4()
        void createMarketingEvent(new MarketingEvent({
          type: 'View',
          marketingLinkId: linkItem?.id,
          browser: browserName,
          operatingSystem: osName,
          referrer: referrerHistory,
          uuid: eventId,
        }))
        window.prerenderReady = true
        if (linkItem) {
          await getBasicOrganisation(linkItem.organisationId)
            .then(orgItem => {
              setOrganisation(orgItem)

              if (linkItem.useDefaultPixels && orgItem) {
                if (orgItem.defaultTikTokTag && orgItem.defaultTikTokTag.length > 0) {
                  TiktokPixel.init(orgItem.defaultTikTokTag)
                  TiktokPixel.pageView()
                }
                if (orgItem.defaultFbTag && orgItem.defaultFbTag.length > 0) {
                  ReactPixel.init(orgItem.defaultFbTag)
                  ReactPixel.pageView()
                }
                if (orgItem.defaultGTMTag && orgItem.defaultGTMTag.length > 0) {
                  TagManager.initialize({ gtmId: orgItem.defaultGTMTag })
                }
              } else {
                if (linkItem.tiktokPixel && linkItem.tiktokPixel.length > 0) {
                  TiktokPixel.init(linkItem.tiktokPixel)
                  TiktokPixel.pageView()
                }
                if (linkItem.facebookPixel && linkItem.facebookPixel.length > 0) {
                  ReactPixel.init(linkItem.facebookPixel)
                  ReactPixel.pageView()
                }
                if (linkItem.googleTagManager && linkItem.googleTagManager.length > 0) {
                  TagManager.initialize({ gtmId: linkItem.googleTagManager })
                }
              }
            })
            .catch(() => null)
        }

        if (linkItem?.type === 'Shortlink' && linkItem.target) {
          window.location.href = linkItem.target
        }

        await fetch(linkUrl, { method: 'get' })
          .then(response => response.json())
          .then((data: trackingData | undefined) => {
            setTrackingData(data)
            if (data) {
              setConsentPerformance(!trackingModeGdpr(data.country_name))
              setConsentMarketing(!trackingModeGdpr(data.country_name))
              setBannerGdprMode(trackingModeGdpr(data.country_name))
              setDisplayConsentBanner(trackingModeGdpr(data.country_name))
            }
          })
          .finally(() => {
            setConsentPerformance(true)
            setConsentMarketing(true)
          })
          .catch(() => null)
      })
  }

  useEffect(() => {
    if (location.pathname) {
      setupInital()
        .finally(() => null)
    }
  }, [])

  const handleButtonClick = (index: number) => {
    if (link) {
      const eventId = v4()
      if (link.tiktokPixel && link.tiktokPixel.length > 0) {
        TiktokPixel.track('ClickButton',
          {
            'content_type': 'product',
            'content_name': link.inputList[index].label,
            'value': '0',
            'content_id': eventId,
          })
      }
      if (link.facebookPixel && link.facebookPixel.length > 0) {
        ReactPixel.track('ViewContent',
          { 'content_name': link.inputList[index].label, 'eventID': eventId })
      }
      void createMarketingEvent(new MarketingEvent({
        type: 'Click',
        marketingLinkId: link.id,
        destination: link.inputList[index].label,
        browser: browserName,
        operatingSystem: osName,
        country: trackingData?.country_name,
        city: trackingData?.city,
        referrer: referrerHistory,
        uuid: eventId,
      }))
      window.location.href = link.inputList[index].value.includes('apple')
        ? `${link.inputList[index].value.split('?')[0]}?at=1000l3aaQ&ct=smart_link&itscg=30200&itsct=toolbox_linkbuilder&ls=1&app=music`
        : link.inputList[index].value
    }
  }

  const handleButtonClickBio = (index: number) => {
    if (link) {
      const eventId = v4()
      if (link.tiktokPixel && link.tiktokPixel.length > 0) {
        TiktokPixel.track('ClickButton',
          {
            'content_type': 'product',
            'content_name': link.inputList[index].label,
            'value': '0',
            'content_id': eventId,
          })
      }
      if (link.facebookPixel && link.facebookPixel.length > 0) {
        ReactPixel.track('ViewContent',
          { 'content_name': link.inputList[index].label, 'eventID': eventId })
      }
      void createMarketingEvent(new MarketingEvent({
        type: 'Click',
        marketingLinkId: link.id,
        destination: link.bioLinkList[index].linkPrimaryText,
        browser: browserName,
        operatingSystem: osName,
        country: trackingData?.country_name,
        city: trackingData?.city,
        referrer: referrerHistory,
        uuid: eventId,
      }))
      window.location.href = link.bioLinkList[index].linkDestinationUrl.includes('apple')
        ? `${link.bioLinkList[index].linkDestinationUrl.split('?')[0]}?at=1000l3aaQ&ct=smart_link&itscg=30200&itsct=toolbox_linkbuilder&ls=1&app=music`
        : link.bioLinkList[index].linkDestinationUrl
    }
  }

  return (
    <>
      <Helmet>
        <title>
          {link && (link.type === 'Presave' || link.type === 'Smartlink' || link.type === 'Biolink')
            ? link.artists.length > 0
              ? `${link.artists.join(', ')} - ${link.title}`
              : link.title
            : 'Releese'}
        </title>
        <meta
          content={link?.seoDescription
            ? link.seoDescription.length === 0
              ? link.title
              : link.seoDescription
            : 'Releese'}
          name='description'
        />
        <meta
          content={link?.title
            ? link.title
            : 'Releese'}
          property='og:title'
        />
        <meta
          content={link?.seoDescription
            ? link.seoDescription.length === 0
              ? link.title
              : link.seoDescription
            : 'Releese'}
          property='og:description'
        />
        {link?.artworkUrl &&
        <meta
          content={link.artworkUrl}
          property='og:image'
        />}
        <meta
          content='en_US'
          property='og:locale'
        />

        <script src='https://js-cdn.music.apple.com/musickit/v1/musickit.js' />

        {link?.snapchatPixel && consentMarketing &&
          <script type='text/javascript'>
            {`
            (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
            {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
            a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
            r.src=n;var u=t.getElementsByTagName(s)[0];
            u.parentNode.insertBefore(r,u);})(window,document,
            'https://sc-static.net/scevent.min.js');
            
            
            snaptr('init', '${link.snapchatPixel}');
            
            snaptr('track', 'PAGE_VIEW');
            `}
          </script>}

        <meta
          content='Version 2'
          name='custom'
        />
      </Helmet>

      {successModal && organisation &&
      <PresaveModal
        close={() => setSuccessModal(false)}
        link={link}
        onOpen={() => setSuccessModal(true)}
        open={successModal}
        organisation={organisation}
      />}
      {organisation && subscribedModal &&
      <SubscribeCompleteModal
        close={() => setSubscribedModal(false)}
        open={subscribedModal}
        organisation={organisation}
      />}
      <Stack
        height={1}
        sx={{
          background: link?.theme === 'Subtle'
            ? activeTheme.default.palette.background.default
            : link?.backgroundColor ?? '#ffffff',
        }}
        width={1}
      >
        {link?.type !== 'Shortlink' &&
        <>
          <Box height={1} overflow='auto' width={1}>
            {link?.artworkUrl && link.theme === 'Subtle' &&
            <div
              ref={widthItem}
              style={{
                position: 'fixed',
                height: '100%',
                width: '100%',
                background: `url(${link.artworkUrl})`,
                backgroundSize: '200%',
                backgroundPosition: 'center',
                filter: 'blur(60px)',
                zIndex: 0,
                transform: 'scale(1.5)',
                opacity: 0.5,
                minWidth: 1920,
                left: `calc(50% - ${width}px / 2)`,
              }}
            />}

            <Stack
              height={1}
              overflow='auto'
              position='relative'
              sx={{
                '::-webkit-scrollbar': {
                  background: activeTheme.default.palette.background.default,
                  width: 8,
                },
                '::-webkit-scrollbar-thumb': {
                  background: activeTheme.default.palette.divider,
                  borderRadius: 16,
                },
              }}
              width={1}
              zIndex={3}
            >
              {link && link.type !== 'Biolink' &&
              <SmartLinkContent
                handleClick={handleButtonClick}
                link={link}
                organisation={organisation}
                presave={presave}
                subscribed={subscribed === 'true'}
              />}
              {link && link.type === 'Biolink' &&
              <BioLinkContent
                browserName={browserName}
                handleClick={handleButtonClickBio}
                organisation={organisation}
                osName={osName}
                referrerHistory={referrerHistory}
                smartLinkData={link}
                subscribed={subscribed === 'true'}
                trackingData={trackingData}
              />}

              {link?.showAllReleases &&
                <AllReleases
                  browserName={browserName}
                  link={link}
                  organisation={organisation}
                  osName={osName}
                  referrerHistory={referrerHistory}
                  trackingData={trackingData}
                />}
            </Stack>
          </Box>

          <Stack
            direction='row'
            sx={{
              bottom: 4,
              left: 4,
              position: 'absolute',
              zIndex: 3,
            }}
          >
            <IconButton
              onClick={() => setDisplayPrivacyPreferenceCenter(true)}
              size='small'
              sx={{
                color: activeTheme.default.palette.text.primary,
                opacity: 0.5,
                '&:hover': {
                  opacity: 1,
                },
                zIndex: 3,
              }}
            >
              <SecurityRoundedIcon />
            </IconButton>
          </Stack>

          <PrivacyBanner
            acceptAll={acceptAllCookies}
            close={() => setDisplayConsentBanner(false)}
            declineAll={declineAllCookies}
            gdprTracking={bannerGdprMode}
            open={displayConsentBanner}
            openPreferenceCenter={() => setDisplayPrivacyPreferenceCenter(true)}
          />
          <PresaveConsent
            browserName={browserName}
            city={trackingData?.city}
            close={() => setPresaveConsent(false)}
            country={trackingData?.country_name}
            link={link}
            open={presaveConsent}
            openDrawer={() => setPresaveConsent(true)}
            operatingSystem={osName}
            presaveDestination={presaveDestination}
            referrer={referrerHistory}
            url={urlPresave ?? ''}
          />

          <Drawer
            anchor='left'
            onClose={() => setDisplayPrivacyPreferenceCenter(false)}
            open={displayPrivacyPreferenceCenter}
          >
            {displayPrivacyPreferenceCenter &&
            <PreferenceCenter
              close={() => setDisplayPrivacyPreferenceCenter(false)}
              organisation={organisation}
              setStatusMarketing={setConsentMarketing}
              setStatusPerformance={setConsentPerformance}
              statusMarketing={consentMarketing}
              statusPerformance={consentPerformance}
            />}
          </Drawer>
        </>}
      </Stack>
    </>
  )
}

export default ReleasePreview

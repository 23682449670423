import type { Components } from '@mui/material'

import SPACING from 'src/styles/spacing'

const MuiTab: Components['MuiTab'] = {
  defaultProps: {
    iconPosition: 'start',
  },
  styleOverrides: {
    root: {
      paddingBottom: 0,
      paddingTop: 0,
      minHeight: '48px',
      borderTopLeftRadius: SPACING.BORDER_RADIUS,
      borderTopRightRadius: SPACING.BORDER_RADIUS,
      fontSize: '16px',
      fontWeight: 'normal',
      textTransform: 'none',
    },
  },
}

export default MuiTab

/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const BandsintownIcon = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='0 0 24 24'>
    <path
      d='M18.783 0H24v12h-5.217V0zm-6.261 5h5.217v7h-5.217V5zM6.26 5h5.217v7H6.261V5zM24 24H0V0h5.217v19h13.566v-1H6.26v-5H24v11Z'
    />
  </SvgIcon>

export default BandsintownIcon

import type { SocialList } from 'src/models/Marketing'
import type { Serialized } from 'src/types/react-app-env'

export const PRICE_LOOKUP_KEYS = ['FREE', 'CREATOR', 'CREATOR_PRO', 'ENTERPRISE',
  'CREATOR_YEARLY', 'CREATOR_PRO_YEARLY', 'ENTERPRISE_YEARLY'] as const
export type PriceLookupKey = typeof PRICE_LOOKUP_KEYS[number]

export type Links = {
  spotify: string
  soundCloud: string
  twitter: string
  appleMusic: string
  deezer: string
}

export enum Permissions {
  CREATE_EDIT_REMOVE_PROJECTS,
  VIEW_PROJECTS,
  CREATE_EDIT_REMOVE_TASKBOARDS,
  VIEW_TASKBOARDS,
  CREATE_EDIT_REMOVE_MARKETING_LINKS,
  VIEW_MARKETING_LINKS,
  DISTRIBUTE_RELEASES,
  VIEW_EARNINGS,
  VIEW_ANALYTICS,
  MANAGE_PAYOUTS,
  CREATE_EDIT_REMOVE_RIGHTS,
  VIEW_RIGHTS,
  EDIT_ORGANISATION_SETTINGS,
  ADD_REMOVE_USERS,
  MANAGE_BILLING,
}

export class BasicOrganisation {
  name = ''
  logoUrl = ''
  defaultSocialList: SocialList[] = []
  id = ''
  defaultFbTag = ''
  defaultGTMTag = ''
  defaultSnapchatTag = ''
  defaultTikTokTag = ''

  constructor(dto: BasicOrganisationDto) {
    Object.assign(this, dto)
  }
}

export type BasicOrganisationDto = Partial<Serialized<BasicOrganisation>>

import type { Components } from '@mui/material'

import baseTypography from 'src/styles/base.typography.theme'
import SPACING from 'src/styles/spacing'

const MuiFormHelperText: Components['MuiFormHelperText'] = {
  styleOverrides: {
    root: {
      ...baseTypography.helperText,
      marginLeft: 0,
      marginTop: SPACING.themeSpacing(1),
    },
  },
}

export default MuiFormHelperText

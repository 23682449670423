import type { Components } from '@mui/material'

import baseTypography from 'src/styles/base.typography.theme'
import SPACING from 'src/styles/spacing'

const MuiFilledInput: Components['MuiFilledInput'] = {
  styleOverrides: {
    root: {
      borderRadius: SPACING.INPUT_RADIUS,
      borderWidth: SPACING.INPUT_BORDER_WIDTH,
      borderStyle: 'solid',
      '&:after, &:before': {
        borderBottom: 'none !important',
      },
      '& .MuiFilledInput-root': {
        paddingLeft: 0,
        height: '100%',
      },
      '& .MuiSvgIcon-root': {
        marginLeft: 1.5,
        marginRight: 1.5,
      },
    },
    input: {
      ...baseTypography.inputText,
      borderRadius: SPACING.INPUT_RADIUS,
      height: SPACING.INPUT_HEIGHT_DENSE,
      padding: 0,
      paddingLeft: SPACING.INPUT_SIDE_PADDING,
      paddingRight: SPACING.INPUT_SIDE_PADDING,
      '& .StripeElement': {
        display: 'flex',
        alignItems: 'center',
        '.__PrivateStripeElement': {
          width: '100%',
        },
      },
    },
    multiline: {
      padding: `${SPACING.MULTILINE_TOP_PADDING}px 0 ${SPACING.MULTILINE_BOTTOM_PADDING}px`,
    },
    sizeSmall: {
      input: {
        height: SPACING.addPixels(SPACING.INPUT_HEIGHT_DENSE, -SPACING.INPUT_BORDER_WIDTH * 2),
      },
    },
  },
}

export default MuiFilledInput

/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const KkboxLogo = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='0 0 786 164'>
    <path className='st0' d='M547.7 0c-56.6 0-81.9 35.1-81.9 82s25.8 82 81.9 82c56.1 0 82-40.7 82-82s-25.4-82-82-82zm0 131.1C516.9 131.1 504 105 504 82s14.1-48.6 43.7-48.6c29.5 0 43.7 25.5 43.7 48.6 0 23-12.9 49.1-43.7 49.1zM143.6 5.4h-26.9c-12.1 0-17.9 3.1-24.8 10.5L38.2 70.8V10.2c0-2.3-1.4-4.4-4.2-4.4H4.4C1.4 5.7 0 7.6 0 10.2V155c0 1.9 1.6 3.4 3.4 3.4h31.3c1.9 0 3.5-1.5 3.5-3.4V93.1l55.3 59.2c4.8 4.8 12.3 7 21.6 7h27c8.9 0 5.6-5.1 5.6-5.1L78.4 82.5l69.5-72c.1 0 4.6-5.1-4.3-5.1zm159.2 0h-26.9C263.7 5.4 258 8.5 251 16l-53.6 54.9V10.2c0-2.3-1.4-4.4-4.2-4.4h-29.6c-2.9 0-4.4 1.8-4.4 4.4V155c0 1.9 1.6 3.4 3.4 3.4h31.3c1.9 0 3.5-1.5 3.5-3.4V93.1l55.3 59.2c4.8 4.8 12.3 7 21.6 7h27c8.9 0 5.6-5.1 5.6-5.1l-69.3-71.8 69.5-72c0 .1 4.6-5-4.3-5z' />
    <path className='st0' d='M785.6 153.8l-53-73.2L782 10.5s4.6-5.1-4.4-5.1h-25.9c-11.3 0-16.2.1-21.5 7.9l-23.8 36.8-24.6-37.3c-5.6-6.8-12.1-7.4-23.6-7.4h-23.5c-9 0-4.6 5.1-4.6 5.1l50.7 70.1-54 73.6s-3.3 5.1 5.6 5.1h23.9c7.1 0 16.8 1.1 22.3-6.2.1-.1 27.7-41.4 27.7-41.4s27.2 40.6 27.3 40.7c5.5 7.5 14.7 6.9 21.8 6.9h23.7c9.2.1 6.5-5.5 6.5-5.5zM326.5 4.8h72.6C433 4.8 447 25 447 44.1c0 15.7-11.3 28.8-24.4 34.8 17.4 6.9 30.2 16.8 30.2 40.1 0 25.7-18.4 40.2-49.9 40.2h-77.2c-2.9 0-4.9-2.1-4.9-5.1V10.5c.1-3.6 2.4-5.7 5.7-5.7zm56.9 62.7c15 0 23-3.6 23-16.7v-.7c0-10.1-7.3-15-24.1-15l-21.3.4v32.1h22.4zm2.9 62.9c17.8 0 24.4-5.9 24.4-17.3v-.4c0-12.4-8-18.3-23.2-18.3H361v35.3l25.3.7z' />
    {' '}
  </SvgIcon>

export default KkboxLogo

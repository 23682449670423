/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-duplicate-string */
import 'src/styles/Custom/cookielaw.css'

import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded'
import { alpha, Avatar, Button, Card, CardContent, CardMedia, Container, darken, IconButton, lighten, Stack, Typography, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { useState } from 'react'

import { GetColorLink } from 'src/components/pages/SmartLink/Content/Functions/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/Functions/GetIcon'
import SubscribeModal from 'src/components/pages/SmartLink/SubscribeModal/SubscribeModal'
import type { MarketingLink } from 'src/models/Marketing'
import type { BasicOrganisation } from 'src/models/Organisation'
import * as darkTheme from 'src/styles/dark.theme'
import * as lightTheme from 'src/styles/light.theme'

const getFontFamily = (font: string) => {
  switch (font) {
    case 'classical':
      return 'Times New Roman, Times, serif'
    case 'artistic':
      return 'Georgia, cursive'
    case 'modern':
      return 'Arial, Helvetica, sans-serif'
    default:
      return
  }
}

const getBorderRadius = (style: string) => {
  switch (style) {
    case 'square':
      return 0
    case 'rounded':
      return
    case 'round':
      return 64
    case 'outlined-square':
      return 0
    case 'outlined-rounded':
      return
    case 'outlined-round':
      return 64
    case 'glass-square':
      return 0
    case 'glass-rounded':
      return
    case 'glass-round':
      return 64
    default:
      return
  }
}

const appleMusic = 'Apple Music'
const deezer = 'Deezer'
const spotify = 'Spotify'
const amazon = 'Amazon'

type Props = {
  link: MarketingLink
  handleClick: (index: number) => void
  presave: (type: 'Amazon' | 'Apple Music' | 'Deezer' | 'Spotify') => void
  organisation: BasicOrganisation | null | undefined
  subscribed: boolean
}

const SmartLinkContent = (props: Props) => {
  const theme = useTheme()
  const [subscribe, setSubscribe] = useState(false)

  const activeTheme = props.link.palette === 'Light'
    ? lightTheme
    : darkTheme

  dayjs.extend(advancedFormat)

  const getButtonBackgroundColor = (platform: string) => props.link.buttonStyle.includes('outlined')
    ? 'transparent'
    : props.link.buttonStyle.includes('glass')
      ? props.link.colorStyle === 'color'
        ? alpha(GetColorLink(platform), 0.4)
        : alpha(activeTheme.default.palette.text.label, 0.25)
      : props.link.colorStyle === 'color'
        ? GetColorLink(platform)
        : activeTheme.default.palette.text.primary

  const getButtonBorderColor = (platform: string) => props.link.colorStyle === 'color'
    ? GetColorLink(platform)
    : activeTheme.default.palette.text.primary

  const getButtonTextColor = (platform: string) => props.link.buttonStyle.includes('glass')
    ? props.link.colorStyle === 'color'
      ? activeTheme.default.palette.primary.contrastText
      : activeTheme.default.palette.text.primary
    : props.link.buttonStyle.includes('outlined')
      ? props.link.colorStyle === 'color'
        ? GetColorLink(platform)
        : activeTheme.default.palette.text.primary
      : props.link.colorStyle === 'color'
        ? activeTheme.default.palette.primary.contrastText
        : activeTheme.default.palette.background.default

  const getButtonBackgroundHoverColor = (platform: string) => props.link.colorStyle === 'color'
    ? darken(GetColorLink(platform), 0.2)
    : GetColorLink(platform)

  const getButtonVariant = () => props.link.buttonStyle.includes('outlined') ||
    props.link.buttonStyle.includes('glass')
    ? 'outlined'
    : 'contained'

  const getSubscribeButtonTextBorderColor = () => props.link.buttonStyle.includes('outlined')
    ? activeTheme.default.palette.text.primary
    : props.link.buttonStyle.includes('glass')
      ? activeTheme.default.palette.text.primary
      : activeTheme.default.palette.background.default

  const getActiveButtonBackgroundColor = () => props.link.buttonStyle.includes('outlined')
    ? 'transparent'
    : props.link.buttonStyle.includes('glass')
      ? alpha(activeTheme.default.palette.text.label, 0.25)
      : activeTheme.default.palette.text.primary

  const getBoxShadow = () => props.link.buttonStyle.includes('glass')
    ? '4px 8px 8px rgba(0,0,0,.08)'
    : 'none'

  return (
    <Container
      sx={{
        display: 'flex',
        paddingLeft: '24px!important',
        paddingRight: '24px!important',
        marginTop: 4,
        width: '100%',
        maxWidth: '400px!important',
        height: 'auto',
        flexShrink: 0,
      }}
    >
      <Stack
        direction='column'
        minHeight={props.link.showAllReleases
          ? '95vh'
          : '100vh'}
        spacing={1}
        width={1}
      >
        <Card
          elevation={0}
          sx={{
            width: 1,
            borderRadius: '16px',
            background: 'transparent',
            overflow: 'initial',
          }}
        >
          {props.link.artworkUrl
            ? <CardMedia
              alt={`${props.link.artworkUrl ? 'Invalid' : 'Missing'} artwork`}
              component='img'
              height='auto'
              image={props.link.artworkUrl}
              sx={{
                borderRadius: '8px!important',
                aspectRatio: '1/1',
              }}
              width={1}
            />
            : <Card
              elevation={0}
              sx={{
                borderRadius: '8px!important',
                background: theme.palette.background.input,
                height: 'auto',
                width: 1,
                aspectRatio: '1/1',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <MusicNoteRoundedIcon
                sx={{
                  fontSize: 250,
                  color: theme.palette.action.disabled,
                }}
              />
            </Card>}
          <CardContent sx={{ paddingX: 0 }}>
            <Stack spacing={2}>
              <Stack alignItems='flex-start' direction='column' spacing={1} width={1}>
                <Typography
                  color={activeTheme.default.palette.text.primary}
                  fontFamily={getFontFamily(props.link.fontStyle)}
                  lineHeight={1}
                  sx={{ wordBreak: 'break-word' }}
                  textAlign='left'
                  variant='h3'
                >
                  {props.link.title}
                </Typography>
                {props.link.artists.length > 0 &&
                <Typography
                  color={activeTheme.default.palette.text.primary}
                  fontFamily={getFontFamily(props.link.fontStyle)}
                  lineHeight={1}
                  textAlign='left'
                  variant='body1'
                >
                  {props.link.artists.map((artist, index) => index + 1 === props.link.artists.length
                    ? `${artist.toString()}`
                    : `${artist.toString()}, `)}
                </Typography>}
                {props.link.type === 'Presave' &&
                <Typography
                  color={activeTheme.default.palette.text.secondary}
                  fontFamily={getFontFamily(props.link.fontStyle)}
                  lineHeight={1}
                  textAlign='left'
                  variant='helperText'
                >
                  {`Out ${dayjs(props.link.releaseDate).format('MMMM Do')}`}
                </Typography>}
              </Stack>

              {props.link.descriptionText.length > 0 &&
                <Stack alignItems='flex-end' direction='row' spacing={1} width={1}>
                  <Avatar
                    src={props.organisation?.logoUrl}
                  />
                  <Card
                    elevation={0}
                    sx={{
                      width: 1,
                      background: props.link.palette === 'Light'
                        ? '#e9e9eb'
                        : '#26252a',
                      borderRadius: '20px 20px 20px 6px',
                    }}
                  >
                    <Stack padding={1.5} width={1}>
                      <Typography
                        color={activeTheme.default.palette.text.primary}
                        fontFamily={getFontFamily(props.link.fontStyle)}
                        textAlign='left'
                        variant='body2'
                      >
                        {props.link.descriptionText}
                      </Typography>
                    </Stack>
                  </Card>
                </Stack>}

              <Stack spacing={1.5}>
                {props.link.foreverPresave &&
                <Stack
                  alignItems='center'
                  direction='row'
                  justifyContent='space-between'
                  spacing={1}
                  width={1}
                >
                  <Button
                    disabled={props.subscribed}
                    fullWidth
                    onClick={() => setSubscribe(true)}
                    startIcon={<NotificationsRoundedIcon
                      sx={{
                        animation: 'ring 3s infinite',
                      }}
                    />}
                    sx={{
                      boxShadow: getBoxShadow(),
                      borderRadius: getBorderRadius(props.link.buttonStyle),
                      fontFamily: getFontFamily(props.link.fontStyle),
                      height: '48px',
                      color: getSubscribeButtonTextBorderColor(),
                      backgroundColor: getActiveButtonBackgroundColor(),
                      borderColor: getSubscribeButtonTextBorderColor(),
                      ':hover': {
                        color: activeTheme.default.palette.background.default,
                        backgroundColor: props.link.palette === 'Light'
                          ? lighten(activeTheme.default.palette.text.primary, 0.2)
                          : darken(activeTheme.default.palette.text.primary, 0.2),
                        borderColor: getSubscribeButtonTextBorderColor(),
                      },
                      paddingX: 3,
                      '.MuiButton-startIcon': {
                        position: 'absolute',
                        left: 16,
                      },
                    }}
                    variant={getButtonVariant()}
                  >
                    Subscribe to
                    {' '}
                    {props.organisation?.name}
                  </Button>
                </Stack>}

                {props.link.type === 'Presave' &&
                <>
                  <Button
                    fullWidth
                    key='Spotify-Presave'
                    onClick={() => props.presave('Spotify')}
                    startIcon={GetIcon(spotify)}
                    sx={{
                      boxShadow: getBoxShadow(),
                      borderRadius: getBorderRadius(props.link.buttonStyle),
                      fontFamily: getFontFamily(props.link.fontStyle),
                      color: getButtonTextColor(spotify),
                      backgroundColor: getButtonBackgroundColor(spotify),
                      borderColor: getButtonBorderColor(spotify),
                      ':hover': {
                        color: activeTheme.default.palette.primary.contrastText,
                        backgroundColor: getButtonBackgroundHoverColor(spotify),
                        borderColor: getButtonBorderColor(spotify),
                      },
                      '.MuiButton-startIcon': {
                        position: 'absolute',
                        left: 16,
                      },
                    }}
                    variant={getButtonVariant()}
                  >
                    Pre-save on Spotify
                  </Button>
                  <Button
                    fullWidth
                    id='apple-music-authorize'
                    key='Apple-Music-Presave'
                    onClick={() => props.presave('Apple Music')}
                    startIcon={GetIcon(appleMusic)}
                    sx={{
                      boxShadow: getBoxShadow(),
                      borderRadius: getBorderRadius(props.link.buttonStyle),
                      fontFamily: getFontFamily(props.link.fontStyle),
                      color: getButtonTextColor(appleMusic),
                      backgroundColor: getButtonBackgroundColor(appleMusic),
                      borderColor: getButtonBorderColor(appleMusic),
                      ':hover': {
                        color: activeTheme.default.palette.primary.contrastText,
                        backgroundColor: getButtonBackgroundHoverColor(appleMusic),
                        borderColor: getButtonBorderColor(appleMusic),
                      },
                      '.MuiButton-startIcon': {
                        position: 'absolute',
                        left: 16,
                      },
                    }}
                    variant={getButtonVariant()}
                  >
                    Pre-save on Apple Music
                  </Button>
                  <Button
                    fullWidth
                    key='Deezer-Presave'
                    onClick={() => props.presave('Deezer')}
                    startIcon={GetIcon(deezer)}
                    sx={{
                      boxShadow: getBoxShadow(),
                      borderRadius: getBorderRadius(props.link.buttonStyle),
                      fontFamily: getFontFamily(props.link.fontStyle),
                      color: getButtonTextColor(deezer),
                      backgroundColor: getButtonBackgroundColor(deezer),
                      borderColor: getButtonBorderColor(deezer),
                      ':hover': {
                        color: activeTheme.default.palette.primary.contrastText,
                        backgroundColor: getButtonBackgroundHoverColor(deezer),
                        borderColor: getButtonBorderColor(deezer),
                      },
                      '.MuiButton-startIcon': {
                        position: 'absolute',
                        left: 16,
                      },
                    }}
                    variant={getButtonVariant()}
                  >
                    Pre-save on Deezer
                  </Button>
                  <Button
                    fullWidth
                    key='Amazon-Presave'
                    onClick={() => props.presave('Amazon')}
                    startIcon={GetIcon(amazon)}
                    sx={{
                      boxShadow: getBoxShadow(),
                      borderRadius: getBorderRadius(props.link.buttonStyle),
                      fontFamily: getFontFamily(props.link.fontStyle),
                      color: getButtonTextColor(amazon),
                      backgroundColor: getButtonBackgroundColor(amazon),
                      borderColor: getButtonBorderColor(amazon),
                      ':hover': {
                        color: activeTheme.default.palette.primary.contrastText,
                        backgroundColor: getButtonBackgroundHoverColor(amazon),
                        borderColor: getButtonBorderColor(amazon),
                      },
                      '.MuiButton-startIcon': {
                        position: 'absolute',
                        left: 16,
                      },
                    }}
                    variant={getButtonVariant()}
                  >
                    Pre-save on Amazon Music
                  </Button>
                </>}

                {props.link.type === 'Smartlink' &&
                  props.link.inputList.map((linkItem, index) =>
                    <Button
                      fullWidth
                      key={linkItem.id}
                      onClick={() => props.handleClick(index)}
                      startIcon={GetIcon(linkItem.label)}
                      sx={{
                        boxShadow: getBoxShadow(),
                        borderRadius: getBorderRadius(props.link.buttonStyle),
                        fontFamily: getFontFamily(props.link.fontStyle),
                        color: getButtonTextColor(linkItem.label),
                        backgroundColor: getButtonBackgroundColor(linkItem.label),
                        borderColor: getButtonBorderColor(linkItem.label),
                        ':hover': {
                          color: activeTheme.default.palette.primary.contrastText,
                          backgroundColor: getButtonBackgroundHoverColor(linkItem.label),
                          borderColor: getButtonBorderColor(linkItem.label),
                        },
                        '.MuiButton-startIcon': {
                          position: 'absolute',
                          left: 16,
                        },
                      }}
                      variant={getButtonVariant()}
                    >
                      {linkItem.customTitle.length > 0
                        ? linkItem.customTitle
                        : linkItem.label}
                    </Button>)}
              </Stack>
              <Stack alignItems='center' direction='row' flexWrap='wrap' justifyContent='center' spacing={1} width={1}>
                {props.link.useOrgDefaultSocialIcons &&
                  props.organisation?.defaultSocialList.map(element =>
                    <Stack key={element.label}>
                      <IconButton
                        color='default'
                        href={element.value}
                        size='medium'
                        sx={{
                          color: activeTheme.default.palette.action.active,
                          ':hover': {
                            backgroundColor: activeTheme.default.palette.action.hover,
                          },
                        }}
                      >
                        {GetIcon(element.label)}
                      </IconButton>
                    </Stack>)}
                {!props.link.useOrgDefaultSocialIcons &&
                props.link.socialList.map(element =>
                  <Stack key={element.label}>
                    <IconButton
                      color='default'
                      href={element.value}
                      size='medium'
                      sx={{
                        color: activeTheme.default.palette.text.primary,
                        ':hover': {
                          color: activeTheme.default.palette.text.primary,
                        },
                      }}
                    >
                      {GetIcon(element.label)}
                    </IconButton>
                  </Stack>)}
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
      {props.organisation &&
      <SubscribeModal
        close={() => setSubscribe(false)}
        link={props.link}
        open={subscribe}
        openDrawer={() => setSubscribe(true)}
        organisation={props.organisation}
      />}
    </Container>
  )
}

export default SmartLinkContent

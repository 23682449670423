import type { Components } from '@mui/material'

import { OTHER } from 'src/styles/colors'
import SPACING from 'src/styles/spacing'

const MuiPaper: Components['MuiPaper'] = {
  styleOverrides: {
    rounded: {
      borderRadius: SPACING.BORDER_RADIUS,
    },
    outlined: {
      borderColor: OTHER.OUTLINE_BORDER,
    },
  },
}

export default MuiPaper

import 'src/styles/fonts/fonts.css'

// eslint-disable-next-line no-restricted-imports
import type { TypographyOptions } from '@mui/material/styles/createTypography'

import COLOR from 'src/styles/colors'
import SPACING from 'src/styles/spacing'

const baseTypography: TypographyOptions = {
  fontFamily: [
    'Poppins',
    'sans-serif',
    'Moderna',
    'Helvetica Neue',
    'Droid Sans',
    'Fira Sans',
    'Cantarell',
    'Ubuntu',
    'Inter',
    'Oxygen',
    'Roboto',
    'Segoe UI',
    'BlinkMacSystemFont',
    '-apple-system',
  ].join(','),
  fontSize: 18,
  h1: {
    fontSize: '3em', // 64px
    lineHeight: '72px',
    letterSpacing: '-0.25 px',
    fontWeight: 600,
  },
  h2: {
    fontSize: '2em', // 40px
    lineHeight: '48px',
    letterSpacing: '-0.25 px',
    fontWeight: 600,
  },
  h3: {
    fontSize: '1.5em', // 24px
    lineHeight: '40px',
    letterSpacing: '-0.25 px',
    fontWeight: 600,
  },
  h4: {
    fontSize: '1.25em', // 24px
    lineHeight: '36px',
    letterSpacing: '-0.25 px',
    fontWeight: 500,
  },
  h5: {
    fontSize: '1em', // 22px
    lineHeight: '32px',
    letterSpacing: '-0.25 px',
    fontWeight: 500,
  },
  h6: {
    fontSize: '1em', // 20px
    lineHeight: '28px',
    letterSpacing: '-0.25 px',
    fontWeight: 500,
  },
  subtitle1: {
    fontSize: '1.125em', // 18px
    lineHeight: '24px',
    letterSpacing: '0.15 px',
    fontWeight: 500,
  },
  subtitle2: {
    fontSize: '1em', // 16px
    lineHeight: '20px',
    letterSpacing: '0.15 px',
    fontWeight: 500,
  },
  body1: {
    fontSize: '1em', // 16px
    lineHeight: '24px',
    letterSpacing: '0.15 px',
    fontWeight: 400,
  },
  body2: {
    fontSize: '0.875em', // 14px
    lineHeight: '18px',
    letterSpacing: '0.15 px',
    fontWeight: 400,
  },
  caption: {
    fontSize: '0.8125em', // 13px
    lineHeight: '18px',
    letterSpacing: '0.1px',
    fontWeight: 400,
  },
  overline: {
    fontSize: '0.9375em', // 15px
    lineHeight: '18px',
    letterSpacing: '0.25 px',
    fontWeight: 400,
  },
  // #region CUSTOM COMPONENTS custom.d.ts
  callout: {
    fontSize: '1.0625em', // 17px
    lineHeight: '24px',
    letterSpacing: '1px',
    fontWeight: 400,
  },
  footnote: {
    fontSize: '0.9375em', // 15px
    lineHeight: '18px',
    fontWeight: 300,
  },
  helperText: {
    fontSize: '0.75em', // 12px
    lineHeight: '16px',
    letterSpacing: '0.15 px',
    fontWeight: 400,
  },
  tooltip: {
    fontSize: '0.75em', // 12px
    lineHeight: '18px',
    letterSpacing: '0.2px',
    fontWeight: 500,
  },
  inputText: {
    fontSize: '0.875em', // 16px
    lineHeight: '20px',
    fontWeight: 400,
  },
  inputLabel: {
    fontSize: '0.75em', // 14px'
    color: COLOR.TEXT.LABEL,
    marginTop: SPACING.themeSpacing(-3),
    lineHeight: '16px',
    fontWeight: 500,
  },
  textLabel: {
    fontSize: '0.875em',  // 14px'
    color: COLOR.TEXT.LABEL,
    lineHeight: '16px',
    fontWeight: 500,
  },
  // Data (and Tables)
  tableHeading: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.25px',
  },
  tableCell: {
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.15px',
  },
  body3: {
    fontFamily: 'Inter',
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '0.15px',
  },
  // #endregion
}

export default baseTypography

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable complexity */
/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
import 'src/styles/Custom/cookielaw.css'

import { Box, Stack, SwipeableDrawer } from '@mui/material'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { useEffect, useRef, useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useLocation } from 'react-router-dom'
import TiktokPixel from 'tiktok-pixel'
import { v4 } from 'uuid'

import { createAppleMusicPresave, createMarketingEvent } from 'src/api/links'
import SmartLinkContent from 'src/components/pages/SmartLink/Content/SmartLinkContent'
import PresaveConsent from 'src/components/pages/SmartLink/PresaveConsentModal/PresaveConsent'
import PresaveModal from 'src/components/pages/SmartLink/PresaveModal/PresaveModal'
import type { trackingData } from 'src/components/pages/SmartLink/ReleasePreview'
import { type MarketingLink, AppleMusicPresave, MarketingEvent } from 'src/models/Marketing'
import type { BasicOrganisation } from 'src/models/Organisation'
import * as darkTheme from 'src/styles/dark.theme'
import * as lightTheme from 'src/styles/light.theme'

type Props = {
  link: MarketingLink
  open: boolean
  close: () => void
  onOpen: () => void
  organisation: BasicOrganisation
  browserName: string
  osName: string
  referrerHistory: string
  trackingData?: trackingData
}

const RenderLatestReleaseDrawer = (props: Props) => {
  const link = props.link
  const activeTheme = props.link.palette === 'Light'
    ? lightTheme
    : darkTheme

  dayjs.extend(advancedFormat)

  const widthItem = useRef<HTMLDivElement | null>(null)
  const [width, setWidth] = useState(0)

  const [successModal, setSuccessModal] = useState(false)
  const [urlPresave, setUrlPresave] = useState<string>()
  const [presaveDestination, setPresaveDestination] = useState<string>()
  const [presaveConsent, setPresaveConsent] = useState(false)

  const search = useLocation().search
  const subscribed = new URLSearchParams(search).get('subscribed')

  useEffect(() => {
    const getDimensions = () => widthItem.current
      ? widthItem.current.offsetWidth
      : 1920

    const handleResize = () => {
      setWidth(getDimensions())
    }

    setWidth(getDimensions())

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [widthItem.current])

  const appleMusicPresave = async () => {
    const music = MusicKit.getInstance()

    await music.authorize()
      .then(musicUserToken => {
        void createMarketingEvent(new MarketingEvent({
          type: 'Click',
          marketingLinkId: link.id,
          destination: 'Apple Music',
          browser: props.browserName,
          operatingSystem: props.osName,
          referrer: props.referrerHistory,
          uuid: v4(),
        }))

        void createAppleMusicPresave(new AppleMusicPresave({
          marketingLinkId: link.id,
          accessToken: musicUserToken,
        }))

        window.location.href = `${window.location.href}?status=done`
      })
  }

  const presave = (type: 'Amazon' | 'Apple Music' | 'Deezer' | 'Spotify') => {
    if (link.tiktokPixel && link.tiktokPixel.length > 0) {
      TiktokPixel.track('ClickButton',
        {
          'content_type': 'product',
          'content_name': type,
          'value': '0',
          'content_id': 0,
        })
    }
    if (link.facebookPixel && link.facebookPixel.length > 0) {
      ReactPixel.track('ViewContent',
        { 'content_name': type })
    }
    switch (type) {
      case 'Apple Music':
        void appleMusicPresave()
        break
      case 'Spotify':
        setPresaveDestination('Spotify')
        setUrlPresave(link.spotifyPresaveUrl)
        setPresaveConsent(true)
        break
      case 'Deezer':
        setPresaveDestination('Deezer')
        setUrlPresave(link.deezerPresaveUrl)
        setPresaveConsent(true)
        break
      case 'Amazon':
        setPresaveDestination('Amazon')
        setUrlPresave(`https://www.amazon.com/ap/oa?client_id=amzn1.application-oa2-client.f75a9018d47e41019cdcfb52c517f79b&scope=profile&response_type=code&state=${link.id}&redirect_uri=https://api.releese.io/api/amazon/webhook`)
        setPresaveConsent(true)
        break
      default:
        window.location.href = 'https://releese.io'
        break
    }
  }

  const handleButtonClick = (index: number) => {
    const eventId = v4()
    if (link.tiktokPixel && link.tiktokPixel.length > 0) {
      TiktokPixel.track('ClickButton',
        {
          'content_type': 'product',
          'content_name': link.inputList[index].label,
          'value': '0',
          'content_id': eventId,
        })
    }
    if (link.facebookPixel && link.facebookPixel.length > 0) {
      ReactPixel.track('ViewContent',
        { 'content_name': link.inputList[index].label, 'eventID': eventId })
    }
    void createMarketingEvent(new MarketingEvent({
      type: 'Click',
      marketingLinkId: link.id,
      destination: link.inputList[index].label,
      browser: props.browserName,
      operatingSystem: props.osName,
      country: props.trackingData?.country_name,
      city: props.trackingData?.city,
      referrer: props.referrerHistory,
      uuid: eventId,
    }))
    window.location.href = link.inputList[index].value.includes('apple')
      ? `${link.inputList[index].value.split('?')[0]}?at=1000l3aaQ&ct=smart_link&itscg=30200&itsct=toolbox_linkbuilder&ls=1&app=music`
      : link.inputList[index].value
  }

  return (
    <>
      <SwipeableDrawer
        anchor='bottom'
        draggable
        onClose={props.close}
        onOpen={props.onOpen}
        open={props.open}
        sx={{
          '.MuiDrawer-paper': {
            borderRadius: '16px 16px 0px 0px',
            background: activeTheme.default.palette.background.default,
          },
        }}
      >
        <Box
          sx={{
            width: 64,
            height: 6,
            background: activeTheme.default.palette.divider,
            borderRadius: 3,
            display: 'flex',
            marginY: 1,
            marginX: 'auto',
          }}
        />
        <Stack
          height={1}
          sx={{
            borderRadius: '16px 16px 0px 0px',
            overflow: 'hidden',
          }}
          width={1}
        >
          <Stack
            height={1}
            maxHeight={700}
            sx={{
              background: link.theme === 'Subtle'
                ? activeTheme.default.palette.background.default
                : link.backgroundColor,
            }}
            width={1}
          >
            <Box height={1} overflow='hidden' position='relative' width={1}>
              {props.link.artworkUrl && props.link.theme === 'Subtle' &&
              <div
                ref={widthItem}
                style={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  background: `url(${props.link.artworkUrl})`,
                  backgroundSize: '200%',
                  backgroundPosition: 'center',
                  filter: 'blur(60px)',
                  zIndex: 2,
                  transform: 'scale(1.5)',
                  opacity: 0.5,
                  minWidth: 1920,
                  left: `calc(50% - ${width}px / 2)`,
                }}
              />}
              <Stack
                height={1}
                maxHeight={700}
                overflow='auto'
                position='relative'
                sx={{
                  '::-webkit-scrollbar': {
                    background: activeTheme.default.palette.background.default,
                    width: 8,
                  },
                  '::-webkit-scrollbar-thumb': {
                    background: activeTheme.default.palette.divider,
                    borderRadius: 16,
                  },
                }}
                width={1}
                zIndex={3}
              >
                <SmartLinkContent
                  handleClick={handleButtonClick}
                  link={props.link}
                  organisation={props.organisation}
                  presave={presave}
                  subscribed={subscribed === 'true'}
                />
              </Stack>
            </Box>

          </Stack>
        </Stack>
      </SwipeableDrawer>
      {successModal &&
      <PresaveModal
        close={() => setSuccessModal(false)}
        link={link}
        onOpen={() => setSuccessModal(true)}
        open={successModal}
        organisation={props.organisation}
      />}
      <PresaveConsent
        browserName={props.browserName}
        city={props.trackingData?.city}
        close={() => setPresaveConsent(false)}
        country={props.trackingData?.country_name}
        link={link}
        open={presaveConsent}
        openDrawer={() => setPresaveConsent(true)}
        operatingSystem={props.osName}
        presaveDestination={presaveDestination}
        referrer={props.referrerHistory}
        url={urlPresave ?? ''}
      />
    </>
  )
}

export default RenderLatestReleaseDrawer

/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const NaverLogo = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='0 0 520.346 93.715' >
    <g>
      <path d='M0 .322h36.738s33.372 50.42 34.365 51.775c.996 1.352 1.119 0 1.119 0-1.538-7.808-3.208-11.312-3.208-23.78V.322h36.598v93.393H69.015s-32.688-47.73-33.678-49.049c-.983-1.318-1.112 0-1.112 0 1.257 6.445 2.373 7.523 2.373 18.703v30.346H0V.322zM163.307 59.896l4.985-18.955c1.081-3.829 1.822-9.354 1.822-9.354.155-.844 1.324-.844 1.529.041.236 1.307.826 5.273 1.938 9.231l5.093 19.037h-15.367zm-6.85 22.176h28.929l3.488 11.643h40.506L191.807.322h-42.328l-36.602 93.393h40.51l3.07-11.643zM325.796.322l-40.79 93.393h-32.413L211.805.322h39.955l13.969 42.486c1.529 4.583 2.65 14.646 2.65 14.646.262.794 1.204.735 1.352 0 0 0 1.119-10.063 2.657-14.646L285.841.322h39.955zM333.756.322h70.835V25.84h-32.276v8.912h29.48v24.281h-29.48v9.164h33.396v25.518h-71.952V.322h-.003zM458.725 25.518h2.944c6.56 0 12.289.864 12.289 8.051 0 6.932-6.293 7.799-12.576 7.799h-2.657v-15.85zm-37.436 67.875h37.437V63.416s.24-1.062.958 0l16.515 29.977h44.147l-22.919-30.352c-2.099-2.719-5.638-5.048-6.423-5.566-.784-.526 0-.75 0-.75 14.257-4.209 20.402-10.147 20.402-24.274C511.406 8.918 491.273 0 467.528 0h-46.239v93.393z' />
    </g>
  </SvgIcon>

export default NaverLogo

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-child-element-spacing */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'
import { Avatar, Button, Card, Divider, IconButton, Link, Stack, Switch, Typography } from '@mui/material'
import { useState } from 'react'

import LogoReleese from 'src/images/LogoR.svg'
import type { BasicOrganisation } from 'src/models/Organisation'

type Props = {
  close: () => void
  statusMarketing: boolean
  statusPerformance: boolean
  setStatusMarketing: (selection: boolean) => void
  setStatusPerformance: (selection: boolean) => void
  organisation: BasicOrganisation | null | undefined
}

const PreferenceCenter = (props: Props) => {
  const [selectionMarketing, setSelectionMarketing] = useState(props.statusMarketing)
  const [selectionPerformance, setSelectionPerformance] = useState(props.statusPerformance)

  const handleChangeMarketing = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectionMarketing(event.target.checked)
  }

  const handleChangePerformance = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectionPerformance(event.target.checked)
  }

  const handleSave = () => {
    props.setStatusPerformance(selectionPerformance)
    props.setStatusMarketing(selectionMarketing)
    props.close()
  }

  return (
    <Stack maxWidth={1} >
      <Stack direction='column' maxWidth={400} padding={2} spacing={2}>
        <Stack alignItems='center' direction='row' justifyContent='space-between' width={1}>
          <Stack alignItems='center' direction='row' spacing={1}>
            <SecurityRoundedIcon color='secondary' fontSize='large'  />
            <Typography variant='h4'>
              Your privacy is important
            </Typography>
          </Stack>
          <IconButton onClick={() => props.close()}>
            <CloseRoundedIcon />
          </IconButton>
        </Stack>

        <Divider />

        <Typography variant='caption'>
          On behalf of
          {' '}
          <b>
            {props.organisation?.name}
          </b>
          , Releese sets cookies that can identify
          you as a visitor. Your data will only be used with your permissions.
          {' '}
          <Link href='https://releese.io/legal/privacy-policy' rel='noreferrer' target='_blank'>
            privacy policy
          </Link>
        </Typography>

        <Stack direction='column' height={1} spacing={1} width={1}>
          <Typography variant='h6'>
            Data management
          </Typography>

          <Card variant='outlined'>
            <Stack direction='column' padding={2} spacing={1}>
              <Typography variant='h6'>
                Data controller
              </Typography>
              <Stack alignItems='center' direction='row' spacing={2}>
                <Avatar
                  src={props.organisation?.logoUrl}
                  variant='rounded'
                />
                <Typography variant='body2'>
                  {props.organisation?.name}
                </Typography>
              </Stack>
            </Stack>
          </Card>

          <Card variant='outlined'>
            <Stack direction='column' padding={2} spacing={1}>
              <Typography variant='h6'>
                Data processor
              </Typography>
              <Stack alignItems='center' direction='row' spacing={2}>
                <Avatar src={LogoReleese} sx={{ borderRadius: '2px' }} variant='rounded' />
                <Typography variant='body2'>
                  Releese Innovations Ltd.
                </Typography>
              </Stack>
            </Stack>
          </Card>
        </Stack>

        <Stack direction='column' height={1} spacing={1} width={1}>
          <Typography variant='h6'>
            Settings
          </Typography>

          <Card variant='outlined'>
            <Stack direction='column' padding={2} spacing={1}>
              <Stack alignItems='center' direction='row' justifyContent='space-between'>
                <Typography variant='textLabel'>
                  Marketing tracking
                </Typography>
                <Switch
                  checked={selectionMarketing}
                  onChange={handleChangeMarketing}
                />
              </Stack>
              <Typography variant='caption'>
                To better personalise and deliver tailored advertising to users, we work with 3rd party
                providers and systems. These 3rd parties may place cookies containing user identifiers.
              </Typography>
            </Stack>
          </Card>

          <Card variant='outlined'>
            <Stack direction='column' padding={2} spacing={1}>
              <Stack alignItems='center' direction='row' justifyContent='space-between'>
                <Typography variant='textLabel'>
                  Performance tracking
                </Typography>
                <Switch
                  checked={selectionPerformance}
                  onChange={handleChangePerformance}
                />
              </Stack>
              <Typography variant='caption'>
                We set performance cookies to help us understand how our website is used.
                This information is used to help us provide you with a better user experience.
              </Typography>
            </Stack>
          </Card>
        </Stack>

        <Typography variant='caption'>
          Releese reserves the right to store functional, non personally identifiable cookies
          for performance purposes only.
        </Typography>

        <Button
          onClick={handleSave}
        >
          Save preferences
        </Button>
      </Stack>
    </Stack>
  )
}

export default PreferenceCenter

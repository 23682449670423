/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const BamdcampLogo = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='0 0 30 30'>
    <path d='M15,3C8.373,3,3,8.373,3,15s5.373,12,12,12s12-5.373,12-12S21.627,3,15,3z M17.333,18.774H8.578l4.089-7.547h8.755L17.333,18.774z' />
  </SvgIcon>

export default BamdcampLogo

export const removeIndexFromArray = <T>(index: number, array: T[]) =>
  [...array.slice(0, index), ...array.slice(index + 1)]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type URecord = Record<string, Record<string, any>>

export const isObject = (item: unknown): item is URecord =>
  !!(item && typeof item === 'object' && !Array.isArray(item))

/**
 * Usage:
 * `mergeDeep(this, { a: { b: { c: 123 } } })`
 * or
 * `const merged = mergeDeep({a: 1}, { b : { c: { d: { e: 12345}}}})`
 */
export function mergeDeep<T, S>(target: T, source: S) {
  const output: URecord = Object.assign({}, target)
  if (isObject(target) && isObject(source)) {
    for (const key of Object.keys(source)) {
      if (isObject(source[key]) && (key in target)) {
        output[key] = mergeDeep(target[key], source[key])
      } else {
        Object.assign(output, { [key]: source[key] })
      }
    }
  }

  return output
}

export function defaultOfType(type: string) {
  if (typeof type !== 'string') throw new TypeError('Type must be a string.')

  // Handle simple types (primitives and plain function/object)
  switch (type) {
    case 'bigint': return BigInt(0)
    case 'boolean': return false
    case 'function': return function () { /**/ }
    case 'null': return null
    case 'number': return 0
    case 'object': return {}
    case 'string': return ''
    case 'symbol': return Symbol(undefined)
    case 'undefined': return void 0
    default: throw new TypeError(`Unsupported type '${type}'`)
  }
}

// HINT: We actually want any non-nullish value
// eslint-disable-next-line @typescript-eslint/ban-types
export const toFormData = (object: {}) => {
  const formData = new FormData()

  const toFormDataField = (key: string, value: unknown) => {
    if (value instanceof File) {
      formData.append(key, value, value.name)
    } else if (value instanceof Blob) {
      formData.append(key, value)
    } else if (value instanceof Date) {
      formData.append(key, value.toISOString())
    } else if (typeof value === 'object' && !(value instanceof Date) && value !== null) {
      for (const [subKey, subValue] of Object.entries(value)) {
        toFormDataField(`${key}[${subKey}]`, subValue)
      }
    } else {
      formData.append(key, serialize(value))
    }
  }

  for (const [key, value] of Object.entries(object)) {
    toFormDataField(key, value)
  }

  return formData
}
export const serialize = (value: unknown) =>
  typeof value === 'string'
    ? String(value)
    : JSON.stringify(value)

/* eslint-disable sonarjs/max-switch-cases */
/* eslint-disable complexity */
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import YouTubeIcon from '@mui/icons-material/YouTube'

import SevenDigitalLogo from 'src/components/Icons/7digitalLogo'
import AmazonMusicIcon from 'src/components/Icons/AmazonMusicIcon'
import AnghamiLogo from 'src/components/Icons/AnghamiLogo'
import AppleMusicIcon from 'src/components/Icons/AppleMusicIcon'
import AudiomackLogo from 'src/components/Icons/AudiomackLogo'
import AwaLogo from 'src/components/Icons/AwaLogo'
import BamdcampLogo from 'src/components/Icons/BandcampLogo'
import BandsintownIcon from 'src/components/Icons/Bandsintown'
import BeatportLogo from 'src/components/Icons/BeatportLogo'
import BugsLogo from 'src/components/Icons/BugsLogo'
import ClaroLogo from 'src/components/Icons/ClaroLogo'
import DeezerIcon from 'src/components/Icons/DeezerIcon'
import DiscordIcon from 'src/components/Icons/DiscordIcon'
import EmusicLogo from 'src/components/Icons/eMusicLogo'
import FloLogo from 'src/components/Icons/FloLogo'
import GracenoteLogo from 'src/components/Icons/GracenoteLogo'
import IheartRadioLogo from 'src/components/Icons/IheartRadioLogo'
import ItunesLogo from 'src/components/Icons/ItunesLogo'
import JooxLogo from 'src/components/Icons/JooxLogo'
import KanjianLogo from 'src/components/Icons/Kanjian'
import KkboxLogo from 'src/components/Icons/KkboxLogo'
import KuackLogo from 'src/components/Icons/KuackLogo'
import MelonLogo from 'src/components/Icons/MelonIcon'
import NapsterLogo from 'src/components/Icons/NapsterLogo'
import NaverLogo from 'src/components/Icons/NaverLogo'
import NeteaseLogo from 'src/components/Icons/NeteaseLogo'
import NuudayLogo from 'src/components/Icons/NuudayLogo'
import PandoraIcon from 'src/components/Icons/PandoraIcon'
import QobuzLogo from 'src/components/Icons/QobuzLogo'
import QqIcon from 'src/components/Icons/QqIcon'
import QzoneIcon from 'src/components/Icons/QzoneIcon'
import SaavnLogo from 'src/components/Icons/SaavnLogo'
import SnapchatIcon from 'src/components/Icons/SnapchatIcon'
import SongkickIcon from 'src/components/Icons/Songkick'
import SoundCloudIcon from 'src/components/Icons/SoundCloudIcon'
import SpotifyIcon from 'src/components/Icons/SpotifyIcon'
import TencentLogo from 'src/components/Icons/TencentLogo'
import TicketmasterIcon from 'src/components/Icons/Ticketmaster'
import TidalLogo from 'src/components/Icons/TidalLogo'
import TikTokIcon from 'src/components/Icons/TikTokIcon'
import TumblrIcon from 'src/components/Icons/TumblrIcon'
import TwitchIcon from 'src/components/Icons/TwitchIcon'
import TwitterIcon from 'src/components/Icons/TwitterIcon'
import VevoLogo from 'src/components/Icons/VevoLogo'
import VkIcon from 'src/components/Icons/VkIcon'
import WeChatIcon from 'src/components/Icons/WeChatIcon'
import WeiboIcon from 'src/components/Icons/WeiboIcon'

const GetIcon = (LinkName: string) => {
  switch (LinkName) {
    case 'Spotify':
      return <SpotifyIcon />
    case 'Apple Music':
      return <AppleMusicIcon />
    case 'iTunes & Apple Music':
      return <AppleMusicIcon />
    case 'Deezer':
      return <DeezerIcon />
    case 'Amazon Music':
      return <AmazonMusicIcon />
    case 'Amazon':
      return <AmazonMusicIcon />
    case 'Soundcloud':
      return <SoundCloudIcon />
    case 'SoundCloud':
      return <SoundCloudIcon />
    case 'TikTok':
      return <TikTokIcon />
    case 'YouTube':
      return <YouTubeIcon />
    case 'iTunes':
      return <ItunesLogo />
    case 'Tidal':
      return <TidalLogo />
    case 'Pandora':
      return <PandoraIcon />
    case 'Audiomack':
      return <AudiomackLogo />
    case 'iHeartRadio':
      return <IheartRadioLogo />
    case 'Vevo':
      return <VevoLogo />
    case 'Joox':
      return <JooxLogo />
    case 'KKBox':
      return <KkboxLogo />
    case 'Napster':
      return <NapsterLogo />
    case 'YouTube Music':
      return <YouTubeIcon />
    case 'YouTube Content ID':
      return <YouTubeIcon />
    case 'Beatport':
      return <BeatportLogo />
    case 'Bandcamp':
      return <BamdcampLogo />
    case 'Anghami':
      return <AnghamiLogo />
    case 'Facebook':
      return <FacebookIcon />
    case 'Instagram':
      return <InstagramIcon />
    case 'Twitter':
      return <TwitterIcon />
    case '7Digital':
      return <SevenDigitalLogo />
    case 'Bugs!':
      return <BugsLogo />
    case 'Kuack Media':
      return <KuackLogo />
    case 'Nuuday':
      return <NuudayLogo />
    case 'Claro-musica':
      return <ClaroLogo />
    case 'FLO':
      return <FloLogo />
    case 'Melon':
      return <MelonLogo />
    case 'Naver':
      return <NaverLogo />
    case 'Gracenote':
      return <GracenoteLogo />
    case 'Kanjian':
      return <KanjianLogo />
    case 'Qobuz':
      return <QobuzLogo />
    case 'Tencent':
      return <TencentLogo />
    case 'AWA':
      return <AwaLogo />
    case 'eMUSIC':
      return <EmusicLogo />
    case 'KKBOX':
      return <KkboxLogo />
    case 'Netease':
      return <NeteaseLogo />
    case 'Saavn':
      return <SaavnLogo />
    case 'Discord':
      return <DiscordIcon />
    case 'QQ':
      return <QqIcon />
    case 'Qzone':
      return <QzoneIcon />
    case 'Snapchat':
      return <SnapchatIcon />
    case 'Tumblr':
      return <TumblrIcon />
    case 'Twitch':
      return <TwitchIcon />
    case 'VK':
      return <VkIcon />
    case 'Weibo':
      return <WeiboIcon />
    case 'WeChat':
      return <WeChatIcon />
    case 'Bandsintown':
      return <BandsintownIcon />
    case 'Songkick':
      return <SongkickIcon />
    case 'Ticketmaster':
      return <TicketmasterIcon />
    case 'Download':
      return <DownloadRoundedIcon />
    case 'Website':
      return <LinkRoundedIcon />
    default:
      return <LinkRoundedIcon />
  }
}

export default GetIcon

/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const DeezerIcon = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='0 0 279 279'>
    <path d='M232.97 42.8737C235.539 27.9837 239.309 18.6204 243.485 18.6026H243.493C251.28 18.6294 257.592 51.1012 257.592 91.1924C257.592 131.284 251.271 163.782 243.476 163.782C240.28 163.782 237.331 158.264 234.957 149.017C231.205 182.867 223.419 206.136 214.405 206.136C207.431 206.136 201.172 192.158 196.97 170.114C194.101 212.038 186.88 241.783 178.44 241.783C173.143 241.783 168.314 229.996 164.739 210.804C160.44 250.421 150.508 278.18 138.926 278.18C127.344 278.18 117.394 250.43 113.113 210.804C109.564 229.996 104.735 241.783 99.4118 241.783C90.9722 241.783 83.7686 212.038 80.8818 170.114C76.6797 192.158 70.4383 206.136 63.4465 206.136C54.442 206.136 46.6469 182.875 42.895 149.017C40.5379 158.291 37.5717 163.782 34.376 163.782C26.5809 163.782 20.26 131.284 20.26 91.1924C20.26 51.1012 26.5809 18.6026 34.376 18.6026C38.5604 18.6026 42.3035 27.9926 44.8989 42.8737C49.0569 17.1985 55.8103 0.510986 63.4465 0.510986C72.5129 0.510986 80.3698 24.1114 84.0864 58.3808C87.7235 33.4389 93.241 17.5383 99.4206 17.5383C108.081 17.5383 115.443 48.8118 118.171 92.4355C123.3 70.0692 130.725 56.0377 138.944 56.0377C147.162 56.0377 154.587 70.0781 159.707 92.4355C162.444 48.8118 169.797 17.5383 178.458 17.5383C184.628 17.5383 190.137 33.4389 193.792 58.3808C197.5 24.1114 205.357 0.510986 214.423 0.510986C222.033 0.510986 228.812 17.2075 232.97 42.8737ZM0.0891113 84.0528C0.0891113 66.1311 3.67328 51.5989 8.0961 51.5989C12.5189 51.5989 16.1031 66.1311 16.1031 84.0528C16.1031 101.974 12.5189 116.507 8.0961 116.507C3.67328 116.507 0.0891113 101.974 0.0891113 84.0528ZM261.743 84.0528C261.743 66.1311 265.327 51.5989 269.75 51.5989C274.172 51.5989 277.757 66.1311 277.757 84.0528C277.757 101.974 274.172 116.507 269.75 116.507C265.327 116.507 261.743 101.974 261.743 84.0528Z' />
  </SvgIcon>

export default DeezerIcon

/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const BandsintownIconLight = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='0 0 749 153'>
    <defs><style>{'.cls-3{fill:#00cec8!important;}'}</style></defs>
    <path d='M181.63,100.25h-.2v6.66h-9.59V38.38h9.59v30h.2c2.84-4.9,7.83-8.13,15.27-8.13,11.94,0,22,9,22,24.08s-10.09,24.09-22,24.09C189.46,108.38,184.47,105.14,181.63,100.25Zm27.11-16c0-8.61-5.48-14.68-13.9-14.68s-13.9,6.07-13.9,14.68S186.42,99,194.84,99,208.74,92.91,208.74,84.29Z' />
    <path d='M225.5,84.29c0-15.07,10.08-24.08,22-24.08,7.44,0,12.43,3.23,15.27,8.13H263V61.68h9.6v45.23H263v-6.66h-.19c-2.84,4.89-7.83,8.13-15.27,8.13C235.58,108.38,225.5,99.37,225.5,84.29Zm38,0c0-8.61-5.49-14.68-13.91-14.68s-13.9,6.07-13.9,14.68S241.16,99,249.58,99,263.49,92.91,263.49,84.29Z' />
    <path d='M325.79,79.1v27.81H316.2V80.77c0-7.74-3.92-11-10.38-11-7.05,0-12,4.21-12,15.47v21.64h-9.59V61.68h9.59v6.85h.2c2.93-5.19,7.83-8.32,14.78-8.32C318.45,60.21,325.79,65.4,325.79,79.1Z' />
    <path d='M334.47,84.29c0-15.07,10.09-24.08,22-24.08,7.44,0,12.43,3.23,15.27,8.13h.2v-30h9.59v68.53H372v-6.66h-.2c-2.84,4.89-7.83,8.13-15.27,8.13C344.56,108.38,334.47,99.37,334.47,84.29Zm38,0c0-8.61-5.49-14.68-13.9-14.68s-13.91,6.07-13.91,14.68S350.14,99,358.56,99,372.46,92.91,372.46,84.29Z' />
    <path d='M389.24,103.58l4.31-8.52a28.53,28.53,0,0,0,14.39,4.31c4.6,0,7.05-1.37,7.05-4.21,0-1.86-1-3.23-4.8-5.09l-8.22-4c-6.85-3.23-9.5-7.83-9.5-13,0-8.32,6.17-12.82,16.06-12.82a39.74,39.74,0,0,1,15.66,3.52l-3.91,8.32a31.33,31.33,0,0,0-12-2.93c-4.21,0-5.58,1.37-5.58,3.42s1.28,3.53,5.19,5.39l8.42,4c5.88,2.74,9,6.66,9,12.73,0,9.2-7,13.71-17.62,13.71A37.7,37.7,0,0,1,389.24,103.58Z' />
    <path d='M433.71,61.68h9.6v45.23h-9.6Z' />
    <circle cx='438.51' cy='45.23' r='5.83' />
    <path d='M496.68,79.1v27.81h-9.59V80.77c0-7.74-3.92-11-10.38-11-7,0-12,4.21-12,15.47v21.64h-9.6V61.68h9.6v6.85h.19c2.94-5.19,7.84-8.32,14.79-8.32C489.34,60.21,496.68,65.4,496.68,79.1Z' />
    <path d='M520.26,69.8V93.5c0,3.23,1.37,4.69,4.7,4.69h2.35V107H522.9c-7.93,0-12.23-4.2-12.23-11.64V69.8h-8V61.68h8V43.57h9.59V61.68h9.2V69.8Z' />
    <path d='M582.52,84.29c0,13.9-10.57,24.09-24.77,24.09S533,98.19,533,84.29s10.58-24.08,24.77-24.08S582.52,70.39,582.52,84.29Zm-39.35,0c0,8.91,6,14.69,14.58,14.69s14.59-5.78,14.59-14.69-6-14.68-14.59-14.68S543.17,75.38,543.17,84.29Z' />
    <path d='M654.12,61.68,639,106.91h-8.82L619.37,74.5,608.5,106.91h-8.81L584.51,61.68h10.58l9.1,32.6,10.48-32.6H624l10.47,32.6,9.11-32.6Z' />
    <path d='M702.16,79.1v27.81h-9.59V80.77c0-7.74-3.92-11-10.38-11-7.05,0-12,4.21-12,15.47v21.64h-9.59V61.68h9.59v6.85h.2c2.93-5.19,7.83-8.32,14.78-8.32C694.82,60.21,702.16,65.4,702.16,79.1Z' />
    <rect className='cls-3' height='26.67' width='20' x='73.5' y='47' />
    <rect className='cls-3' height='26.67' width='20' x='100.17' y='47' />
    <rect className='cls-3' height='46.67' width='20' x='126.84' y='27' />
    <polygon className='cls-3' points='73.5 80.33 73.5 100.33 126.84 100.33 126.84 107 66.84 107 66.84 27 46.84 27 46.84 127 146.84 127 146.84 80.33 73.5 80.33' />
  </SvgIcon>

export default BandsintownIconLight

/* eslint-disable react/iframe-missing-sandbox */
/* eslint-disable max-lines */
import 'src/styles/Custom/cookielaw.css'

import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import { alpha, Avatar, Button, Card, CardContent, CardMedia, Container, darken, IconButton, lighten, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import tinycolor from 'tinycolor2'

import RenderBioLinkBlock from 'src/components/pages/SmartLink/Content/BioLinkContent/RenderBioLinkBlock'
import GetIcon from 'src/components/pages/SmartLink/Content/Functions/GetIcon'
import type { trackingData } from 'src/components/pages/SmartLink/ReleasePreview'
import SubscribeModal from 'src/components/pages/SmartLink/SubscribeModal/SubscribeModal'
import type { MarketingLink } from 'src/models/Marketing'
import type { BasicOrganisation } from 'src/models/Organisation'
import * as darkTheme from 'src/styles/dark.theme'
import * as lightTheme from 'src/styles/light.theme'

type Props = {
  smartLinkData: MarketingLink
  organisation: BasicOrganisation | null
  handleClick: (index: number) => void
  subscribed: boolean
  browserName: string
  osName: string
  referrerHistory: string
  trackingData?: trackingData
}

const getFontFamily = (font: string) => {
  switch (font) {
    case 'classical':
      return 'Times New Roman, Times, serif'
    case 'artistic':
      return 'Georgia, cursive'
    case 'modern':
      return 'Arial, Helvetica, sans-serif'
    default:
      return
  }
}

const getBorderRadius = (style: string) => {
  switch (style) {
    case 'square':
      return 0
    case 'rounded':
      return
    case 'round':
      return 64
    case 'outlined-square':
      return 0
    case 'outlined-rounded':
      return
    case 'outlined-round':
      return 64
    case 'glass-square':
      return 0
    case 'glass-rounded':
      return
    case 'glass-round':
      return 64
    default:
      return
  }
}

const BioLinkContent = (props: Props) => {
  const [subscribe, setSubscribe] = useState(false)

  const color = tinycolor(props.smartLinkData.bioLinkButtonColor)
  const isColorDark = color.isDark()

  const socialList = props.smartLinkData.useOrgDefaultSocialIcons
    ? props.organisation?.defaultSocialList ?? []
    : props.smartLinkData.socialList

  const activeTheme = props.smartLinkData.palette === 'Light'
    ? lightTheme
    : darkTheme

  const getButtonBackgroundColor = () => props.smartLinkData.buttonStyle.includes('outlined')
    ? 'transparent'
    : props.smartLinkData.buttonStyle.includes('glass')
      ? props.smartLinkData.colorStyle === 'color'
        ? alpha(props.smartLinkData.bioLinkButtonColor, 0.4)
        : alpha(activeTheme.default.palette.text.label, 0.25)
      : props.smartLinkData.colorStyle === 'color'
        ? props.smartLinkData.bioLinkButtonColor
        : activeTheme.default.palette.text.primary

  const getButtonBorderColor = () => props.smartLinkData.colorStyle === 'color'
    ? props.smartLinkData.bioLinkButtonColor
    : activeTheme.default.palette.text.primary

  const getButtonTextColor = () => props.smartLinkData.buttonStyle.includes('glass')
    ? props.smartLinkData.colorStyle === 'color'
      ? activeTheme.default.palette.primary.contrastText
      : activeTheme.default.palette.text.primary
    : props.smartLinkData.buttonStyle.includes('outlined')
      ? props.smartLinkData.colorStyle === 'color'
        ? props.smartLinkData.bioLinkButtonColor
        : activeTheme.default.palette.text.primary
      : props.smartLinkData.colorStyle === 'color'
        ? isColorDark
          ? activeTheme.default.palette.primary.contrastText
          : 'black'
        : activeTheme.default.palette.background.default

  const getButtonBackgroundHoverColor = () => props.smartLinkData.colorStyle === 'color'
    ? isColorDark
      ? lighten(props.smartLinkData.bioLinkButtonColor, 0.2)
      : darken(props.smartLinkData.bioLinkButtonColor, 0.2)
    : props.smartLinkData.palette === 'Light'
      ? lighten(activeTheme.default.palette.text.primary, 0.2)
      : darken(activeTheme.default.palette.text.primary, 0.2)

  const getButtonVariant = () => props.smartLinkData.buttonStyle.includes('outlined') ||
    props.smartLinkData.buttonStyle.includes('glass')
    ? 'outlined'
    : 'contained'

  const getBoxShadow = () => props.smartLinkData.buttonStyle.includes('glass')
    ? '4px 8px 8px rgba(0,0,0,.08)'
    : 'none'

  return (
    <>
      <link href='https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css' rel='stylesheet' />
      <Container
        sx={{
          display: 'flex',
          paddingLeft: '24px!important',
          paddingRight: '24px!important',
          marginTop: 4,
          width: '100%',
          maxWidth: '400px!important',
          height: 'auto',
          flexShrink: 0,
        }}
      >
        <Stack
          direction='column'
          minHeight={props.smartLinkData.showAllReleases
            ? '95vh'
            : '100vh'}
          spacing={1}
          width={1}
        >
          <Card
            elevation={0}
            sx={{
              width: 1,
              borderRadius: '16px',
              background: 'transparent',
              overflow: 'initial',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {props.smartLinkData.artworkUrl.length > 0
              ? <CardMedia
                alt={`${props.smartLinkData.artworkUrl ? 'Invalid' : 'Missing'} artwork`}
                component='img'
                height={128}
                image={props.smartLinkData.artworkUrl}
                sx={{
                  borderRadius: 128,
                  height: '128px',
                  width: '128px',
                  flexShrink: 0,
                }}
                width={128}
              />
              : <Card
                elevation={0}
                sx={{
                  borderRadius: 128,
                  background: theme => theme.palette.background.input,
                  height: 128,
                  width: 128,
                  flexShrink: 0,
                }}
              >
                <PersonRoundedIcon
                  sx={{
                    fontSize: 128,
                    color: theme => theme.palette.action.disabled,
                  }}
                />
              </Card>}
            <CardContent
              sx={{
                paddingX: 0,
                width: 1,
              }}
            >
              <Stack spacing={2}>
                <Stack alignItems='flex-start' direction='column' spacing={1} width={1}>
                  <Typography
                    color={activeTheme.default.palette.text.primary}
                    fontFamily={getFontFamily(props.smartLinkData.fontStyle)}
                    fontSize='1.5rem'
                    lineHeight={1}
                    sx={{ wordBreak: 'break-word' }}
                    textAlign='center'
                    variant='h3'
                    width='100%'
                  >
                    {props.smartLinkData.title}
                  </Typography>
                </Stack>

                {socialList.length > 0 && !props.smartLinkData.socialPositionBottom &&
                <Stack
                  alignItems='center'
                  direction='row'
                  flexWrap='wrap'
                  justifyContent='center'
                  spacing={1}
                  width={1}
                >
                  {socialList.map(element =>
                    <Stack key={element.label}>
                      <IconButton
                        color='default'
                        href={element.value}
                        size='medium'
                        sx={{
                          color: activeTheme.default.palette.text.primary,
                          ':hover': {
                            color: activeTheme.default.palette.text.primary,
                          },
                        }}
                      >
                        {GetIcon(element.label)}
                      </IconButton>
                    </Stack>)}
                </Stack>}

                {props.smartLinkData.descriptionText.length > 0 &&
                <Stack alignItems='flex-end' direction='row' spacing={1} width={1}>
                  <Avatar
                    src={props.organisation?.logoUrl}
                  />
                  <Card
                    elevation={0}
                    sx={{
                      width: 1,
                      background: props.smartLinkData.palette === 'Light'
                        ? '#e9e9eb'
                        : '#26252a',
                      borderRadius: '20px 20px 20px 6px',
                    }}
                  >
                    <Stack padding={1.5}>
                      <Typography
                        color={activeTheme.default.palette.text.primary}
                        fontFamily={getFontFamily(props.smartLinkData.fontStyle)}
                        fontSize='0.875rem'
                        textAlign='left'
                        variant='body2'
                      >
                        {props.smartLinkData.descriptionText}
                      </Typography>
                    </Stack>
                  </Card>
                </Stack>}

                <Stack spacing={1.5}>
                  {props.smartLinkData.foreverPresave &&
                  <Stack
                    alignItems='center'
                    direction='row'
                    justifyContent='space-between'
                    spacing={1}
                    width={1}
                  >
                    <Button
                      disabled={props.subscribed}
                      fullWidth
                      onClick={() => setSubscribe(true)}
                      startIcon={<NotificationsRoundedIcon
                        sx={{ animation: 'ring 3s infinite' }}
                      />}
                      sx={{
                        boxShadow: getBoxShadow(),
                        borderRadius: getBorderRadius(props.smartLinkData.buttonStyle),
                        fontFamily: getFontFamily(props.smartLinkData.fontStyle),
                        height: '48px',
                        color: getButtonTextColor(),
                        backgroundColor: getButtonBackgroundColor(),
                        borderColor: getButtonBorderColor(),
                        ':hover': {
                          color: activeTheme.default.palette.primary.contrastText,
                          backgroundColor: getButtonBackgroundHoverColor(),
                          borderColor: getButtonBorderColor(),
                        },
                        '.MuiButton-startIcon': {
                          position: 'absolute',
                          left: 16,
                        },
                      }}
                      variant={getButtonVariant()}
                    >
                      Subscribe
                      {props.organisation?.name && props.organisation.name.length > 0 ? ' to' : ''}
                      {' '}
                      {props.organisation?.name}
                    </Button>
                  </Stack>}

                  {props.smartLinkData.bioLinkList.filter(block => block.active).map((linkItem, index) =>
                    <RenderBioLinkBlock
                      borderRadius={getBorderRadius(props.smartLinkData.buttonStyle)}
                      boxShadow={getBoxShadow()}
                      browserName={props.browserName}
                      buttonBackgroundColor={getButtonBackgroundColor()}
                      buttonBackgroundHoverColor={getButtonBackgroundHoverColor()}
                      buttonBorderColor={getButtonBorderColor()}
                      buttonTextColor={getButtonTextColor()}
                      buttonVariant={getButtonVariant()}
                      fontFamily={getFontFamily(props.smartLinkData.fontStyle)}
                      handleClick={props.handleClick}
                      index={index}
                      key={linkItem.id.toString()}
                      linkItem={linkItem}
                      organisation={props.organisation}
                      osName={props.osName}
                      referrerHistory={props.referrerHistory}
                      smartLinkData={props.smartLinkData}
                      trackingData={props.trackingData}
                    />)}

                  {socialList.length > 0 && props.smartLinkData.socialPositionBottom &&
                  <Stack
                    alignItems='center'
                    direction='row'
                    flexWrap='wrap'
                    justifyContent='center'
                    spacing={1}
                    width={1}
                  >
                    {socialList.map(element =>
                      <Stack key={element.label}>
                        <IconButton
                          color='default'
                          href={element.value}
                          size='medium'
                          sx={{
                            color: activeTheme.default.palette.text.primary,
                            ':hover': {
                              color: activeTheme.default.palette.text.primary,
                            },
                          }}
                        >
                          {GetIcon(element.label)}
                        </IconButton>
                      </Stack>)}
                  </Stack>}
                </Stack>
              </Stack>
            </CardContent>
            {props.organisation &&
            <SubscribeModal
              close={() => setSubscribe(false)}
              link={props.smartLinkData}
              open={subscribe}
              openDrawer={() => setSubscribe(true)}
              organisation={props.organisation}
            />}
          </Card>
        </Stack>
      </Container>
    </>
  )
}

export default BioLinkContent

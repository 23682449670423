/* eslint-disable max-len */
import { Icon } from '@mui/material'

import melonImage from 'src/components/Icons/melon.png'

const MelonLogo = () =>
  <Icon sx={{ height: 26, width: 26, fontSize: '1rem' }}>
    <img alt='Melon Logo' height='26px' src={melonImage} width='100%' />
  </Icon>

export default MelonLogo

import type { Components } from '@mui/material'

import baseTypography from 'src/styles/base.typography.theme'

const MuiTable: Components['MuiTable'] = {
  styleOverrides: {
    root: {
      '& .MuiTableCell-head': {
        ...baseTypography.tableHeading,
      },
      '& .MuiTableCell-root': { borderBottomColor: 'divider' },
      '& .MuiTableRow-hover': { cursor: 'pointer' },
    },
  },
}

export default MuiTable

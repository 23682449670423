import { Box, Dialog, Stack, SwipeableDrawer, useMediaQuery, useTheme } from '@mui/material'

import type { MarketingLink } from 'src/models/Marketing'
import * as darkTheme from 'src/styles/dark.theme'
import * as lightTheme from 'src/styles/light.theme'

type Props = {
  close: () => void
  open: () => void
  isOpen: boolean
  children: React.ReactNode
  link?: MarketingLink
}

const DialogDrawer = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const activeTheme = props.link?.palette === 'Dark'
    ? darkTheme
    : lightTheme

  return (
    fullScreen
      ? <SwipeableDrawer
        anchor='bottom'
        draggable
        onClose={props.close}
        onOpen={props.open}
        open={props.isOpen}
        sx={{
          '.MuiDrawer-paper': {
            borderRadius: '16px 16px 0px 0px',
            background: activeTheme.default.palette.background.default,
          },
        }}
      >
        <Box
          sx={{
            width: 64,
            height: 6,
            background: activeTheme.default.palette.divider,
            borderRadius: 3,
            display: 'flex',
            marginY: 1,
            marginX: 'auto',
          }}
        />
        <Stack
          sx={{
            borderRadius: '16px 16px 0px 0px',
            overflow: 'auto',
            paddingBottom: 'env(safe-area-inset-bottom)',
          }}
          width={1}
        >
          {props.children}
        </Stack>
      </SwipeableDrawer>
      : <Dialog
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        fullScreen={fullScreen}
        maxWidth='xs'
        onClose={props.close}
        open={props.isOpen}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          overflow: 'hidden',
          '.MuiPaper-root': {
            background: activeTheme.default.palette.background.default,
          },
        }}
      >
        {props.children}
      </Dialog>
  )
}

export default DialogDrawer

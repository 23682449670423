/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const BeatportLogo = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='-5 0 90 90'>
    <path
      className='st0'
      d='M67.85,61.32c0,13.17-10.51,23.81-23.81,23.81c-13.17,0-23.68-10.38-23.68-23.81c0-6.32,2.39-11.91,6.19-16.1
		l-16.1,16.1L2,52.87l18.16-17.96c2.46-2.46,3.73-5.65,3.73-9.18V3.18h11.91v22.55c0,6.92-2.46,12.77-7.25,17.56l-0.53,0.53
		c4.19-3.86,9.91-6.19,16.03-6.19C57.54,37.64,67.85,48.35,67.85,61.32 M57.08,61.32c0-7.05-5.85-12.77-13.04-12.77
		c-7.25,0-12.9,5.99-12.9,12.77c0,6.98,5.72,12.9,12.9,12.9C51.49,74.22,57.08,68.17,57.08,61.32'
    />
    <path
      className='st0'
      d='M106.96,38.17c5,0,8.87,1.04,11.63,3.13c2.76,2.09,4.14,5,4.14,8.73c0,2.74-0.73,4.99-2.18,6.74
		c-1.46,1.76-3.55,2.94-6.29,3.55c6.82,1.17,10.23,4.76,10.23,10.75c0,3.95-1.47,7.06-4.4,9.32c-2.93,2.26-7.11,3.39-12.54,3.39
		H90.21V38.17H106.96z M97.7,57.53h9.45c2.56,0,4.55-0.59,5.96-1.76c1.41-1.17,2.12-2.78,2.12-4.82c0-2.04-0.71-3.64-2.12-4.79
		c-1.41-1.15-3.4-1.73-5.96-1.73H97.7V57.53z M97.7,77.47h9.9c2.95,0,5.22-0.6,6.81-1.79c1.59-1.19,2.38-2.86,2.38-4.99
		c0-2.22-0.8-3.93-2.41-5.15c-1.61-1.21-3.87-1.82-6.78-1.82h-9.9V77.47z'
    />
    <path
      className='st0'
      d='M127.97,58.15c1.37-2.58,3.28-4.58,5.73-5.99c2.45-1.41,5.27-2.12,8.44-2.12c3.21,0,6.05,0.65,8.5,1.96
		c2.45,1.3,4.39,3.15,5.8,5.54c1.41,2.39,2.14,5.19,2.18,8.41c0,0.87-0.07,1.76-0.2,2.67h-25.15V69c0.17,2.91,1.09,5.21,2.74,6.91
		c1.65,1.69,3.84,2.54,6.58,2.54c2.17,0,4-0.51,5.47-1.53c1.48-1.02,2.45-2.46,2.93-4.33h7.04c-0.61,3.39-2.25,6.17-4.92,8.34
		c-2.67,2.17-6.01,3.26-10,3.26c-3.48,0-6.51-0.71-9.09-2.12c-2.59-1.41-4.58-3.4-5.99-5.96c-1.41-2.56-2.12-5.54-2.12-8.93
		C125.92,63.74,126.61,60.73,127.97,58.15 M151.47,63.46c-0.3-2.43-1.27-4.33-2.9-5.7c-1.63-1.37-3.68-2.05-6.16-2.05
		c-2.3,0-4.29,0.71-5.96,2.12c-1.67,1.41-2.64,3.29-2.9,5.64H151.47z'
    />
    <path
      className='st0'
      d='M192.72,77.66v6.13h-3.71c-2.17,0-3.74-0.46-4.69-1.37c-0.96-0.91-1.43-2.24-1.43-3.97
		c-2.52,3.82-6.19,5.73-11.01,5.73c-3.74,0-6.73-0.87-8.99-2.61c-2.26-1.74-3.39-4.13-3.39-7.17c0-3.43,1.22-6.07,3.65-7.92
		c2.43-1.85,5.97-2.77,10.62-2.77h8.41V61.7c0-1.87-0.65-3.33-1.95-4.4c-1.3-1.06-3.11-1.6-5.41-1.6c-2.04,0-3.73,0.45-5.05,1.34
		c-1.33,0.89-2.12,2.08-2.38,3.55h-6.91c0.35-3.34,1.82-5.94,4.43-7.79c2.61-1.85,6.02-2.77,10.23-2.77c4.47,0,7.93,1.04,10.36,3.13
		c2.43,2.09,3.65,5.08,3.65,8.99v13.16c0,1.56,0.74,2.35,2.22,2.35H192.72z M182.17,68.93h-8.73c-2.17,0-3.84,0.42-5.02,1.27
		c-1.17,0.85-1.76,2.1-1.76,3.75c0,1.43,0.55,2.59,1.66,3.45c1.11,0.87,2.62,1.3,4.53,1.3c2.91,0,5.18-0.78,6.81-2.35
		c1.63-1.56,2.47-3.65,2.51-6.26V68.93z'
    />
    <path
      className='st0'
      d='M195.49,56.62h-5.8v-6.19h5.8v-9.32h7.1v9.32h8.02v6.19h-8.02v17.4c0,1.3,0.26,2.23,0.78,2.77
		c0.52,0.54,1.41,0.81,2.67,0.81h5.34v6.19h-6.78c-3.17,0-5.48-0.74-6.94-2.22c-1.46-1.48-2.18-3.76-2.18-6.84V56.62z'
    />
    <path
      className='st0'
      d='M239.25,52.09c2.39,1.37,4.26,3.35,5.6,5.93c1.35,2.58,2.02,5.62,2.02,9.09c0,3.39-0.67,6.38-2.02,8.96
		c-1.35,2.59-3.22,4.58-5.6,6c-2.39,1.41-5.1,2.12-8.14,2.12c-2.48,0-4.63-0.46-6.45-1.37c-1.82-0.91-3.28-2.15-4.37-3.71v17.72
		h-7.04v-46.4h6.13l0.85,4.95c2.69-3.56,6.32-5.34,10.88-5.34C234.14,50.03,236.86,50.72,239.25,52.09 M237,75.12
		c1.8-2.09,2.7-4.76,2.7-8.01c0-3.3-0.9-5.97-2.7-8.02c-1.8-2.04-4.16-3.06-7.07-3.06c-2.91,0-5.26,1.01-7.04,3.03
		c-1.78,2.02-2.67,4.66-2.67,7.92c0,3.35,0.89,6.06,2.67,8.14c1.78,2.09,4.13,3.13,7.04,3.13C232.84,78.25,235.2,77.21,237,75.12'
    />
    <path
      className='st0'
      d='M257.14,82.06c-2.59-1.41-4.58-3.41-6-5.99c-1.41-2.58-2.12-5.57-2.12-8.96c0-3.34,0.71-6.31,2.12-8.89
		c1.41-2.59,3.41-4.59,6-6.03c2.58-1.43,5.55-2.15,8.89-2.15c3.34,0,6.31,0.72,8.89,2.15c2.58,1.43,4.58,3.44,5.99,6.03
		c1.41,2.59,2.12,5.55,2.12,8.89c0,3.39-0.71,6.38-2.12,8.96c-1.41,2.59-3.41,4.58-5.99,5.99c-2.59,1.41-5.55,2.12-8.89,2.12
		C262.68,84.18,259.72,83.47,257.14,82.06 M273.17,75.09c1.8-2.06,2.7-4.72,2.7-7.98c0-3.26-0.9-5.92-2.7-7.98
		c-1.8-2.06-4.18-3.1-7.14-3.1c-2.95,0-5.32,1.03-7.1,3.1c-1.78,2.06-2.67,4.72-2.67,7.98c0,3.26,0.89,5.92,2.67,7.98
		c1.78,2.06,4.15,3.1,7.1,3.1C268.98,78.18,271.36,77.15,273.17,75.09'
    />
    <path
      className='st0'
      d='M303.89,50.42v6.58h-3.13c-2.91,0-5.02,0.94-6.32,2.83c-1.3,1.89-1.96,4.29-1.96,7.2v16.75h-7.04V50.42h6.26
		l0.78,5.02c0.96-1.56,2.19-2.79,3.71-3.68c1.52-0.89,3.56-1.34,6.13-1.34H303.89z'
    />
    <path
      className='st0'
      d='M306.17,41.11h7.04v9.32h8.08v6.19h-8.08v17.4c0,1.3,0.26,2.23,0.78,2.77c0.52,0.54,1.41,0.82,2.67,0.82H322
		v6.19h-6.78c-3.17,0-5.47-0.74-6.91-2.21c-1.43-1.48-2.15-3.76-2.15-6.84V41.11z'
    />
    {' '}
  </SvgIcon>

export default BeatportLogo

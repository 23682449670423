/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import 'src/styles/Custom/cookielaw.css'

import { Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import { getAllReleases } from 'src/api/links'
import AllReleasesItem from 'src/components/pages/SmartLink/Content/AllReleases/AllReleasesItem'
import type { trackingData } from 'src/components/pages/SmartLink/ReleasePreview'
import type { MarketingLink } from 'src/models/Marketing'
import type { BasicOrganisation } from 'src/models/Organisation'
import * as darkTheme from 'src/styles/dark.theme'
import * as lightTheme from 'src/styles/light.theme'

type Props = {
  link: MarketingLink
  browserName: string
  osName: string
  referrerHistory: string
  trackingData?: trackingData
  organisation: BasicOrganisation | null
}

const AllReleases = (props: Props) => {
  const activeTheme = props.link.palette === 'Light'
    ? lightTheme
    : darkTheme

  const [releases, setReleases] = useState<MarketingLink[]>()

  const getAllReleasesFunction = async (orgId: string) => {
    await getAllReleases(orgId, props.link.id ?? '')
      .then(setReleases)
      .catch(() => null)
  }

  useEffect(() => {
    if (props.organisation?.id) {
      void getAllReleasesFunction(props.organisation.id)
    }
  }, [props.organisation?.id])

  return (
    <Stack
      height={420}
      sx={{
        background: 'transparent',
        position: 'relative',
        zIndex: 99,
        flexShrink: 0,
      }}
      width={1}
    >
      <Stack
        direction='column'
        height={64}
        spacing={2}
        sx={{
          transform: 'rotate(180deg)',
          zIndex: 100,
          position: 'relative',
          height: 0,
          width: 1,
          backgroundColor: activeTheme.default.palette.background.default,
          '&::before': {
            content: '""',
            position: 'absolute',
            backgroundColor: 'transparent',
            bottom: '-50px',
            height: '50px',
            width: '50px',
            borderTopLeftRadius: '25px',
            boxShadow: `0 -25px 0 0 ${activeTheme.default.palette.background.default}`,
          },
        }}
        width={1}
      />
      <Stack
        direction='column'
        height={64}
        spacing={2}
        sx={{
          transform: 'rotate(180deg)',
          zIndex: 100,
          position: 'relative',
          height: 0,
          width: 1,
          backgroundColor: activeTheme.default.palette.background.default,
          '&::before': {
            content: '""',
            position: 'absolute',
            backgroundColor: 'transparent',
            bottom: '-50px',
            right: 0,
            height: '50px',
            width: '50px',
            borderTopRightRadius: '25px',
            boxShadow: `0 -25px 0 0 ${activeTheme.default.palette.background.default}`,
          },
        }}
        width={1}
      />
      <Stack
        direction='column'
        height={420}
        spacing={2}
        sx={{
          background: activeTheme.default.palette.background.default,
          position: 'relative',
          zIndex: 99,
        }}
        width={1}
      >
        <Stack alignItems='center' justifyContent='center' paddingTop={4} width={1}>
          <Typography
            color={activeTheme.default.palette.text.primary}
            textAlign='center'
            variant='h2'
          >
            Releases
          </Typography>
        </Stack>
        <Stack
          height='inherit'
          paddingBottom={4}
          paddingX={4}
          spacing={2}
          sx={{
            background: activeTheme.default.palette.background.default,
            position: 'relative',
            zIndex: 99,
            overflowX: 'auto',
            '::-webkit-scrollbar': {
              background: activeTheme.default.palette.background.default,
              height: 8,
            },
            '::-webkit-scrollbar-thumb': {
              background: activeTheme.default.palette.divider,
              borderRadius: 16,
            },
          }}
          width={1}
        >
          <Stack
            direction='row'
            height={1}
            spacing={2}
            sx={{
              position: 'relative',
              zIndex: 99,
              minWidth: '100%',
            }}
            width='fit-content'
          >
            {releases
              ? releases.map(release =>
                <AllReleasesItem
                  browserName={props.browserName}
                  key={`embed-${release.id}`}
                  link={release}
                  organisation={props.organisation}
                  osName={props.osName}
                  referrerHistory={props.referrerHistory}
                  theme={props.link.palette}
                  trackingData={props.trackingData}
                />)
              : <Typography
                color={activeTheme.default.palette.text.secondary}
                sx={{
                  width: 1,
                }}
                textAlign='center'
                variant='body1'
              >
                Nothing to see here
              </Typography>}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default AllReleases

import axios from 'axios'

import type { AppleMusicPresave, BandsintownArtist, BandsintownEvents, MarketingEvent, MarketingLinkDto, SeatedEvents } from 'src/models/Marketing'
import { MarketingLink } from 'src/models/Marketing'

export const baseUrlLinksApi = process.env.NODE_ENV === 'development'
  ? 'http://localhost:5000/api'
  : 'https://api.releese.io/api'

const PATH = '/links' as const

// leaving this ownerId undefined returns all releases belonging to current user

export const createMarketingEvent = (marketingEvent: Partial<MarketingEvent>) =>
  axios.post<string>(`${baseUrlLinksApi}${PATH}/event`, marketingEvent)
    .then(result => result.data)

export const getMarketingLinkByUrl = (url: string) =>
  axios.get<MarketingLinkDto | undefined>(`${baseUrlLinksApi}${PATH}/view/item?link=${url}`)
    .then(response => response.data ? new MarketingLink(response.data) : undefined)

export const createAppleMusicPresave = (presave: Partial<AppleMusicPresave>) =>
  axios.post<string>(`${baseUrlLinksApi}${PATH}/appleMusic`, presave)
    .then(response => response.data)

export const getNextRelease = (orgId: string) =>
  axios.get<MarketingLinkDto | undefined>(`${baseUrlLinksApi}${PATH}/nextrelease/${orgId}`)
    .then(response => response.data ? new MarketingLink(response.data) : undefined)

export const getLatestRelease = (orgId: string) =>
  axios.get<MarketingLinkDto | undefined>(`${baseUrlLinksApi}${PATH}/latestrelease/${orgId}`)
    .then(response => response.data ? new MarketingLink(response.data) : undefined)

export const getAllReleases = (orgId: string, linkId: string) =>
  axios.get<MarketingLinkDto[]>(`${baseUrlLinksApi}${PATH}/allreleasespublic/${orgId}/${linkId}`)
    .then(response => response.data.map(dto => new MarketingLink(dto)))

export const getBandsintownArtist = (link: string) =>
  axios.put<{ artist: BandsintownArtist, events: BandsintownEvents[] }>(`${baseUrlLinksApi}${PATH}/bandsintown`,
    { link })
    .then(response => response.data)

export const getSeatedArtist = (link: string) =>
  axios.put<{ artist: SeatedEvents }>(`${PATH}/seated`, { link })
    .then(response => response.data)

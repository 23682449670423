/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const NuudayLogo = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='0 0 689.50665 187.36' >
    <g
      id='g8'
      transform='matrix(1.3333333,0,0,-1.3333333,0,187.36)'
    >
      <g
        id='g10'
        transform='scale(0.1)'
      >
        <path
          d='M 765.215,766.492 V 440.73 c 0,-52.371 -42.16,-95.808 -95.813,-95.808 -52.382,0 -95.808,42.156 -95.808,95.808 v 325.762 c 0,129.024 -71.543,204.395 -190.352,204.395 -57.48,0 -141.797,-34.492 -191.621,-85.586 V 439.449 c 0,-52.371 -42.16,-95.808 -95.8124,-95.808 C 43.4375,343.641 0,385.801 0,439.449 v 614.471 c 0,52.38 42.1563,95.81 95.8086,95.81 35.7734,0 66.4254,-19.16 83.0394,-48.54 63.875,38.33 135.41,61.32 204.394,61.32 226.113,0 381.973,-160.97 381.973,-396.018'
        />
        <path
          d='m 861.035,753.719 v 300.201 c 0,52.38 42.156,95.81 95.809,95.81 52.376,0 95.806,-42.16 95.806,-95.81 V 753.719 c 0,-130.301 81.76,-229.953 201.84,-229.953 132.86,0 203.13,99.652 203.13,229.953 v 300.201 c 0,52.38 42.15,95.81 95.81,95.81 52.37,0 95.81,-42.16 95.81,-95.81 V 753.719 c 0,-235.059 -167.36,-421.571 -394.75,-421.571 -226.11,0 -393.455,186.512 -393.455,421.571'
        />
        <path
          d='m 1751.44,753.719 v 300.201 c 0,52.38 42.16,95.81 95.81,95.81 52.38,0 95.81,-42.16 95.81,-95.81 V 753.719 c 0,-130.301 81.76,-229.953 201.85,-229.953 132.85,0 203.12,99.652 203.12,229.953 v 300.201 c 0,52.38 42.15,95.81 95.81,95.81 52.38,0 95.81,-42.16 95.81,-95.81 V 753.719 c 0,-235.059 -167.35,-421.571 -394.74,-421.571 -226.11,0 -393.47,186.512 -393.47,421.571'
          id='path16'
        />
        <path
          d='m 3233.33,614.469 v 268.273 c -47.27,54.93 -125.19,88.145 -195.45,88.145 -123.92,0 -223.57,-99.641 -223.57,-223.567 0,-123.902 99.65,-223.554 223.57,-223.554 63.87,0 141.8,30.664 195.45,90.703 z m 191.62,694.951 V 440.73 c 0,-52.371 -42.16,-95.808 -95.81,-95.808 -34.49,0 -65.15,19.156 -81.76,47.269 -63.87,-39.601 -135.41,-60.043 -209.5,-60.043 -228.67,0 -415.19,186.512 -415.19,415.172 0,228.672 186.52,415.19 415.19,415.19 68.98,0 136.69,-17.88 195.45,-48.55 v 195.46 c 0,52.37 42.16,95.81 95.81,95.81 52.38,0 95.81,-42.16 95.81,-95.81'
        />
        <path
          d='m 4118.62,614.469 v 268.273 c -47.26,54.93 -125.18,88.145 -195.45,88.145 -123.91,0 -223.56,-99.641 -223.56,-223.567 0,-123.902 99.65,-223.554 223.56,-223.554 63.87,0 141.8,30.664 195.45,90.703 z m 191.62,439.451 V 440.73 c 0,-52.371 -42.16,-95.808 -95.81,-95.808 -34.49,0 -65.14,19.156 -81.76,47.269 -63.86,-39.601 -135.41,-60.043 -209.5,-60.043 -231.22,0 -415.18,186.512 -415.18,415.172 0,228.672 186.51,415.19 415.18,415.19 75.37,0 146.92,-20.44 210.78,-57.49 16.61,26.83 45.99,44.71 80.48,44.71 52.38,0 95.81,-42.16 95.81,-95.81'
        />
        <path
          d='M 5171.27,1053.92 V 394.738 C 5171.27,168.629 5007.75,0 4786.75,0 c -145.64,0 -245.29,57.4883 -310.43,141.789 -14.06,19.172 -25.55,43.441 -25.55,70.27 0,53.66 40.88,94.543 93.25,94.543 29.39,0 53.65,-15.332 74.09,-37.051 25.56,-28.11 70.27,-77.93 168.64,-77.93 111.14,0 192.9,81.77 192.9,203.117 v 25.555 c -51.11,-38.324 -112.42,-62.602 -180.13,-62.602 -226.11,0 -380.69,160.969 -380.69,396.028 v 300.201 c 0,53.65 43.43,95.81 95.81,95.81 53.66,0 95.82,-43.43 95.82,-95.81 V 753.719 c 0,-130.301 68.98,-204.406 189.06,-204.406 89.42,0 180.13,85.597 180.13,169.914 v 334.693 c 0,53.65 43.42,95.81 95.81,95.81 53.65,0 95.81,-43.43 95.81,-95.81'
          id='path22'
        />
      </g>
    </g>
  </SvgIcon>

export default NuudayLogo

/* eslint-disable max-len */
import { Icon } from '@mui/material'

import sevenImage from 'src/components/Icons/seated-white.png'

const SeatedDarkLogo = () =>
  <Icon
    sx={{ height: 'auto', width: 126 }}
  >
    <img alt='Seated Logo' height='100%' src={sevenImage} width='100%' />
  </Icon>

export default SeatedDarkLogo

import type { Components } from '@mui/material'

const MuiAppBar: Components['MuiAppBar'] = {
  styleOverrides: {
    root: {
      backgroundColor: 'transparent',
      color: 'inherit',
      boxShadow: 'none',
    },
  },
}

export default MuiAppBar

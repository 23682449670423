import type { Components } from '@mui/material'

import SPACING from 'src/styles/spacing'

const MuiInputAdornment: Components['MuiInputAdornment'] = {
  styleOverrides: {
    filled: {
      '&.MuiInputAdornment-root.MuiInputAdornment-filled:not(.MuiInputAdornment-hiddenLabel)': {
        marginTop: SPACING.INPUT_BORDER_WIDTH,
      },
    },
  },
}

export default MuiInputAdornment

import type { Components } from '@mui/material'

import SPACING from 'src/styles/spacing'

const MuiSwitch: Components['MuiSwitch'] = {
  styleOverrides: {
    root: {
      width: SPACING.themeSpacing(5),
      height: SPACING.themeSpacing(3),
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: SPACING.themeSpacing(0.5),
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          '& + .MuiSwitch-track': {
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: SPACING.themeSpacing(2),
        height: SPACING.themeSpacing(2),
      },
      '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        opacity: 1,
      },
    },
  },
}

export default MuiSwitch

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable complexity */
/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
import 'src/styles/Custom/cookielaw.css'

import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

import BandsintownIconDark from 'src/components/Icons/BandsintownDark'
import BandsintownIconLight from 'src/components/Icons/BandsintownIconLight'
import RenderBandsintownItem from 'src/components/pages/SmartLink/Content/BioLinkContent/BandsInTownItem'
import DialogDrawer from 'src/components/pages/SmartLink/DialogDrawer/DialogDrawer'
import type { BandsintownArtist, BandsintownEvents } from 'src/models/Marketing'
import { type MarketingLink } from 'src/models/Marketing'
import * as darkTheme from 'src/styles/dark.theme'
import * as lightTheme from 'src/styles/light.theme'

type Props = {
  link: MarketingLink
  open: boolean
  close: () => void
  onOpen: () => void
  events: BandsintownEvents[]
  artist: BandsintownArtist
}

const RenderAllBandsintownEvents = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const activeTheme = props.link.palette === 'Light'
    ? lightTheme
    : darkTheme

  return (
    <DialogDrawer
      close={props.close}
      isOpen={props.open}
      link={props.link}
      open={props.onOpen}
    >
      <Stack
        height={1}
        maxHeight={700}
        overflow='auto'
        padding={2}
        spacing={2}
        sx={{
          '::-webkit-scrollbar': {
            background: activeTheme.default.palette.background.default,
            width: 8,
          },
          '::-webkit-scrollbar-thumb': {
            background: activeTheme.default.palette.divider,
            borderRadius: 16,
          },
        }}
        width={1}
      >

        <Stack alignItems='center' direction='row' justifyContent='space-between' width={1}>
          <Typography
            color={activeTheme.default.palette.text.primary}
            variant='h3'
          >
            All events
          </Typography>
          {!fullScreen &&
          <IconButton
            onClick={() => props.close()}
            sx={{
              width: 'fit-content',
            }}
          >
            <CloseRoundedIcon />
          </IconButton>}
        </Stack>
        <Stack spacing={2} width={1}>
          {props.events.map(event =>
            <RenderBandsintownItem
              artist={props.artist}
              event={event}
              key={event.id}
              link={props.link}
            />)}
          <Stack alignItems='center' direction='row' justifyContent='center' width={1}>
            <Typography
              color={activeTheme.default.palette.text.primary}
              textAlign='center'
              variant='body1'
            >
              Powered by
            </Typography>
            {props.link.palette === 'Light'
              ? <BandsintownIconLight
                sx={{
                  fontSize: 32,
                  width: 164,
                }}
              />
              : <BandsintownIconDark
                sx={{
                  fontSize: 32,
                  width: 164,
                }}
              />}
          </Stack>
        </Stack>
      </Stack>
    </DialogDrawer>
  )
}

export default RenderAllBandsintownEvents

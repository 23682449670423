/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const PandoraIcon = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='0 0 48 48'>
    <path d='M26,7H11c-0.552,0-1,0.448-1,1v32c0,0.552,0.448,1,1,1h8c1.105,0,2-0.895,2-2v-5 c0-0.552,0.448-1,1-1h3c9,0,14-7,14-13C39,11,33.18,7,26,7z' />
  </SvgIcon>

export default PandoraIcon

import type { Components } from '@mui/material'

const MuiTypography: Components['MuiTypography'] = {
  defaultProps: {
    variantMapping: {
      body1: 'span',
      body2: 'span',
      // CUSTOM COMPONENTS custom.d.ts
      callout: 'span',
      footnote: 'span',
      helperText: 'span',
      tooltip: 'span',
      inputText: 'span',
      inputLabel: 'label',
      textLabel: 'span',
      // Data (and Tables)
      tableHeading: 'th',
      tableCell: 'td',
      body3: 'span',
    },
  },
  styleOverrides: {
    root: {
      wordBreak: 'break-word',
    },
  },
}

export default MuiTypography

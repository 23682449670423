/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import 'src/styles/Custom/cookielaw.css'

import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import { Avatar, Button, Card, CardActionArea, darken, Divider, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useState } from 'react'

import BandsintownIconDark from 'src/components/Icons/BandsintownDark'
import BandsintownIconLight from 'src/components/Icons/BandsintownIconLight'
import DialogDrawer from 'src/components/pages/SmartLink/DialogDrawer/DialogDrawer'
import type { BandsintownArtist, BandsintownEvents, MarketingLink } from 'src/models/Marketing'
import * as darkTheme from 'src/styles/dark.theme'
import * as lightTheme from 'src/styles/light.theme'

type Props = {
  link: MarketingLink
  event: BandsintownEvents
  artist: BandsintownArtist
}

const RenderBandsintownItem = (props: Props) => {
  const activeTheme = props.link.palette === 'Light'
    ? lightTheme
    : darkTheme

  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const [open, setOpen] = useState(false)

  return (
    <>
      <Card
        key={props.event.id}
        sx={{
          background: 'transparent',
          borderColor: activeTheme.default.palette.border,
          width: 1,
        }}
        variant='outlined'
      >
        <CardActionArea
          onClick={() => setOpen(true)}
        >
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            padding={1}
            spacing={1}
          >
            <Card
              elevation={0}
              sx={{
                background: activeTheme.default.palette.background.input,
                borderRadius: '8px',
                flexShrink: 0,
              }}
            >
              <Stack alignItems='center' flexShrink={0} justifyContent='center' padding={1}>
                <Typography
                  color={activeTheme.default.palette.text.primary}
                  lineHeight={1}
                  textAlign='center'
                  variant='subtitle1'
                >
                  {dayjs(new Date(props.event.datetime)).format('MMM')}
                </Typography>
                <Typography
                  color={activeTheme.default.palette.text.primary}
                  lineHeight={1}
                  textAlign='center'
                  variant='h3'
                >
                  {dayjs(new Date(props.event.datetime)).date()}
                </Typography>
              </Stack>
            </Card>
            <Stack flexGrow={1}>
              <Typography
                color={activeTheme.default.palette.text.primary}
                lineHeight={1.3}
                variant='h4'
              >
                {props.event.venue.name}
              </Typography>
              <Typography
                color={activeTheme.default.palette.text.secondary}
                lineHeight={1.2}
                variant='body1'
              >
                {props.event.venue.city}
                {', '}
                {props.event.venue.country}
                {' · '}
                {dayjs(new Date(props.event.datetime)).format('ha')}
              </Typography>
            </Stack>
            <OpenInNewRoundedIcon
              sx={{
                color: activeTheme.default.palette.text.secondary,
                fontSize: 24,
              }}
            />
          </Stack>
        </CardActionArea>
      </Card>
      <DialogDrawer
        close={() => setOpen(false)}
        isOpen={open}
        link={props.link}
        open={() => setOpen(true)}
      >
        <Stack paddingX={3} paddingY={3} spacing={1.5} width={1}>
          <Stack width={1}>
            <Stack alignItems='center' direction='row' justifyContent='space-between' width={1}>
              <Typography
                color={activeTheme.default.palette.text.primary}
                lineHeight={1}
                variant='h3'
              >
                {props.event.venue.name}
              </Typography>
              {!fullScreen &&
              <IconButton
                onClick={() => setOpen(false)}
                size='small'
                sx={{
                  width: 'fit-content',
                }}
              >
                <CloseRoundedIcon />
              </IconButton>}
            </Stack>

            <Stack alignItems='center' direction='row' marginTop={1} spacing={1} width={1}>
              <Avatar
                src={props.artist.image_url}
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
              <Typography
                color={activeTheme.default.palette.text.primary}
                variant='subtitle2'
              >
                {props.artist.name}
              </Typography>
            </Stack>
            <Typography
              color={activeTheme.default.palette.text.label}
              sx={{
                marginTop: 0.5,
              }}
              variant='body1'
            >
              <CalendarMonthRoundedIcon />
              {' '}
              {dayjs(new Date(props.event.datetime)).format('LLL')}
            </Typography>
            <Divider
              sx={{
                marginTop: '16px!important',
                borderColor: activeTheme.default.palette.divider,
              }}
            />

            <Typography
              color={activeTheme.default.palette.text.secondary}
              sx={{
                marginTop: 2,
              }}
              variant='body1'
            >
              {props.event.description}
            </Typography>

          </Stack>
          {props.event.offers.map(offer =>
            <Button
              href={offer.url}
              key={offer.url}
              sx={{
                background: '#00CEC8',
                ':hover': {
                  background: darken('#00CEC8', 0.25),
                },
              }}
            >
              {offer.type}
            </Button>)}

          {props.event.offers.length === 0 &&
          <Button
            href={`${props.event.url}&trigger=notify_me`}
            sx={{
              background: '#00CEC8',
              ':hover': {
                background: darken('#00CEC8', 0.25),
              },
            }}
          >
            Notify Me
          </Button>}

          <Button
            href={`${props.event.url}&trigger=rsvp_going`}
            sx={{
              color: '#00CEC8',
              borderColor: '#00CEC8',
              ':hover': {
                color: '#00CEC8',
                borderColor: '#00CEC8',
              },
            }}
            variant='outlined'
          >
            RSVP
          </Button>

          <Stack alignItems='center' direction='row' justifyContent='center' width={1}>
            <Typography
              color={activeTheme.default.palette.text.primary}
              textAlign='center'
              variant='body1'
            >
              Powered by
            </Typography>
            {props.link.palette === 'Light'
              ? <BandsintownIconLight
                sx={{
                  fontSize: 32,
                  width: 164,
                }}
              />
              : <BandsintownIconDark
                sx={{
                  fontSize: 32,
                  width: 164,
                }}
              />}
          </Stack>
        </Stack>
      </DialogDrawer>
    </>
  )
}

export default RenderBandsintownItem

import type { Components } from '@mui/material'

import baseTypography from 'src/styles/base.typography.theme'
import SPACING from 'src/styles/spacing'

const MuiInputLabel: Components['MuiInputLabel'] = {
  styleOverrides: {
    shrink: true,
    root: {
      ...baseTypography.inputLabel,
      transform: 'translate(0)',
      shrink: true,
    },
    sizeSmall: {
      transform: `translate(${SPACING.INPUT_SIDE_PADDING}, ${SPACING.themeSpacing(2)}) scale(1)`,
      '&[data-shrink="true"]': {
        transform: `translate(${SPACING.INPUT_SIDE_PADDING}, ${SPACING.themeSpacing(0.5)}) scale(1)`,
      },
    },
  },
}

export default MuiInputLabel

import type { Components } from '@mui/material'

const MuiStep: Components['MuiStep'] = {

  styleOverrides: {
    root: {
      borderRadius: 32,
      padding: 8,
      width: '100%',
    },
  },
}

export default MuiStep

/* eslint-disable react/forbid-component-props */
/* eslint-disable complexity */
/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
import 'src/styles/Custom/cookielaw.css'

import ImageRoundedIcon from '@mui/icons-material/ImageRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded'
import { Avatar, Button, Card, CardActionArea, Divider, Fab, Icon, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { useEffect, useState } from 'react'
import EmbedContainer from 'react-oembed-container'
import { FacebookEmbed, InstagramEmbed, TikTokEmbed } from 'react-social-media-embed'
import tinycolor from 'tinycolor2'

import { getLatestRelease, getNextRelease } from 'src/api/links'
import RenderLatestReleaseDrawer from 'src/components/pages/SmartLink/Content/BioLinkContent/LatestReleaseDrawer'
import RenderBandsintown from 'src/components/pages/SmartLink/Content/BioLinkContent/RenderBandsInTown'
import RenderSeated from 'src/components/pages/SmartLink/Content/BioLinkContent/RenderSeated'
import type { trackingData } from 'src/components/pages/SmartLink/ReleasePreview'
import type { BioLinkListItem, MarketingLink } from 'src/models/Marketing'
import type { BasicOrganisation } from 'src/models/Organisation'
import * as darkTheme from 'src/styles/dark.theme'
import * as lightTheme from 'src/styles/light.theme'

type Props = {
  smartLinkData: MarketingLink
  linkItem: BioLinkListItem
  fontFamily?: string
  borderRadius?: number
  buttonBackgroundColor: string
  buttonBorderColor: string
  buttonTextColor: string
  buttonBackgroundHoverColor: string
  buttonVariant: 'contained' | 'outlined'
  boxShadow: string
  organisation: BasicOrganisation | null
  handleClick: (index: number) => void
  index: number
  browserName: string
  osName: string
  referrerHistory: string
  trackingData?: trackingData
}

const RenderBioLinkBlock = (props: Props) => {
  const linkItem = props.linkItem
  const activeTheme = props.smartLinkData.palette === 'Light'
    ? lightTheme
    : darkTheme
  dayjs.extend(advancedFormat)

  const color = tinycolor(props.smartLinkData.bioLinkButtonColor)
  const isColorDark = color.isDark()

  const getButtonTextColor = () => props.smartLinkData.colorStyle === 'color'
    ? isColorDark
      ? activeTheme.default.palette.primary.contrastText
      : 'black'
    : activeTheme.default.palette.text.primary

  const getButtonTextColorSecondary = () => props.smartLinkData.colorStyle === 'color'
    ? isColorDark
      ? activeTheme.default.palette.primary.contrastText
      : 'black'
    : activeTheme.default.palette.text.secondary

  const [_loading, setLoading] = useState(false)
  const [latestRelease, setLatestRelease] = useState<MarketingLink>()
  const [nextRelease, setNextRelease] = useState<MarketingLink>()

  const [openDrawerLatest, setOpenLatestDrawer] = useState(false)
  const [openDrawerNext, setOpenNextDrawer] = useState(false)

  const nextReleaseHandler = async (orgId: string) => {
    setLoading(true)
    await getNextRelease(orgId)
      .then(setNextRelease)
      .finally(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  const latestReleaseHandler = async (orgId: string) => {
    setLoading(true)
    await getLatestRelease(orgId)
      .then(setLatestRelease)
      .finally(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  useEffect(() => {
    if (props.organisation?.id && linkItem.blockType === 'latest_release') {
      void latestReleaseHandler(props.organisation.id)
    } else if (props.organisation?.id && linkItem.blockType === 'next_release') {
      void nextReleaseHandler(props.organisation.id)
    }
  }, [linkItem.blockType, props.organisation])

  const regexUrlBandsintown = /https:\/\/www.bandsintown.com\/a\/(.*?)-/
  const regexUrlBandsintownShort = /https:\/\/www.bandsintown.com\/a\/(.*)/
  const artistId = regexUrlBandsintown.exec(props.linkItem.videoEmbedUrl)
  const artistIdShort = regexUrlBandsintownShort.exec(props.linkItem.videoEmbedUrl)

  return (
    <>
      {linkItem.blockType === 'link'
        ? <Button
          fullWidth
          key={linkItem.id}
          onClick={() => props.handleClick(props.index)}
          startIcon={<Icon
            className={`fa ${props.linkItem.icon}`}
            sx={{
              display: 'block!important',
            }}
          />}
          sx={{
            boxShadow: props.boxShadow,
            borderRadius: props.borderRadius,
            fontFamily: props.fontFamily,
            color: props.buttonTextColor,
            backgroundColor: props.buttonBackgroundColor,
            borderColor: props.buttonBorderColor,
            ':hover': {
              color: activeTheme.default.palette.primary.contrastText,
              backgroundColor: props.buttonBackgroundHoverColor,
              borderColor: props.buttonBorderColor,
            },
            '.MuiButton-startIcon': {
              position: 'absolute',
              left: 16,
            },
          }}
          variant={props.buttonVariant}
        >
          {linkItem.linkPrimaryText}
        </Button>
        : linkItem.blockType === 'text'
          ? <Typography
            color={activeTheme.default.palette.text.primary}
            marginTop='4px!important'
            sx={{
              fontFamily: props.fontFamily,
            }}
            textAlign='center'
            variant='body1'
          >
            {linkItem.textContent}
          </Typography>
          : linkItem.blockType === 'header'
            ? <Typography
              color={activeTheme.default.palette.text.primary}
              lineHeight={1.4}
              marginTop='4px!important'
              sx={{
                fontFamily: props.fontFamily,
              }}
              textAlign='center'
              variant='h2'
            >
              {linkItem.textContent}
            </Typography>
            : linkItem.blockType === 'left_divider'
              ? <Stack width={1}>
                <Typography
                  color={activeTheme.default.palette.text.primary}
                  lineHeight={1.4}
                  marginTop='4px!important'
                  sx={{
                    fontFamily: props.fontFamily,
                  }}
                  textAlign='left'
                  variant='h2'
                >
                  {linkItem.textContent}
                </Typography>
                <Divider sx={{ borderColor: activeTheme.default.palette.text.primary }} />
              </Stack>
              : linkItem.blockType === 'card_block'
                ? <Stack width={1}>
                  <Card
                    elevation={0}
                    sx={{
                      background: props.smartLinkData.colorStyle === 'color'
                        ? props.smartLinkData.bioLinkButtonColor
                        : activeTheme.default.palette.background.default,
                    }}
                  >
                    <CardActionArea
                      onClick={() => props.handleClick(props.index)}
                    >
                      <Stack direction='row' width={1}>
                        <Stack width='fit-content'>
                          {linkItem.imageUrl.length > 0
                            ? <img
                              alt='Embeded Releese'
                              height={96}
                              src={linkItem.imageUrl}
                              style={{
                                borderRadius: '16px',
                                objectFit: 'cover',
                              }}
                              width={96}
                            />
                            : <Card
                              elevation={0}
                              sx={{
                                background: activeTheme.default.palette.background.default,
                                width: 96,
                                height: 96,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <Stack alignItems='center' justifyContent='center' width={1}>
                                <ImageRoundedIcon
                                  sx={{
                                    fontSize: 64,
                                    color: activeTheme.default.palette.text.primary,
                                  }}
                                />
                              </Stack>
                            </Card>}
                        </Stack>
                        <Stack
                          height='auto'
                          justifyContent='center'
                          paddingLeft={2}
                          paddingRight={1}
                          paddingY={1}
                          width={1}
                        >
                          {linkItem.linkPrimaryText.length > 0 &&
                          <Typography
                            color={getButtonTextColor()}
                            lineHeight={1.3}
                            sx={{
                              fontFamily: props.fontFamily,
                            }}
                            textAlign='left'
                            variant='h4'
                          >
                            {linkItem.linkPrimaryText}
                          </Typography>}
                          {linkItem.linkSecondaryText.length > 0 &&
                          <Typography
                            color={getButtonTextColorSecondary()}
                            sx={{
                              fontFamily: props.fontFamily,
                            }}
                            textAlign='left'
                            variant='body1'
                          >
                            {linkItem.linkSecondaryText}
                          </Typography>}
                        </Stack>
                      </Stack>
                    </CardActionArea>
                  </Card>
                </Stack>
                : linkItem.blockType === 'bandsintown' &&
                    linkItem.videoEmbedUrl.length > 0 &&
                    (artistId?.[1] || artistIdShort?.[1])
                  ? <Stack paddingY={1} width={1}>
                    <RenderBandsintown
                      embedUrl={linkItem.videoEmbedUrl}
                      link={props.smartLinkData}
                    />
                  </Stack>
                  : linkItem.blockType === 'seated'
                    ? <RenderSeated
                      embedUrl={linkItem.videoEmbedUrl}
                      link={props.smartLinkData}
                    />
                    : linkItem.blockType === 'chat'
                      ? <Stack alignItems='flex-end' direction='row' spacing={1} width={1}>
                        <Avatar
                          src={props.organisation?.logoUrl}
                        />
                        <Card
                          elevation={0}
                          sx={{
                            width: 1,
                            background: props.smartLinkData.palette === 'Light'
                              ? '#e9e9eb'
                              : '#26252a',
                            borderRadius: '20px 20px 20px 6px',
                          }}
                        >
                          <Stack padding={1.5}>
                            <Typography
                              color={activeTheme.default.palette.text.primary}
                              fontFamily={props.fontFamily}
                              fontSize='0.875rem'
                              textAlign='left'
                              variant='body2'
                            >
                              {props.linkItem.textContent}
                            </Typography>
                          </Stack>
                        </Card>
                      </Stack>
                      : linkItem.blockType === 'image'
                        ? linkItem.imageUrl.length > 0
                          ? <img
                            alt='Embeded Releese'
                            height='auto'
                            src={linkItem.imageUrl}
                            style={{
                              borderRadius: '16px',
                            }}
                            width='100%'
                          />
                          : <Card
                            sx={{
                              background: activeTheme.default.palette.background.default,
                            }}
                            variant='outlined'
                          >
                            <Stack alignItems='center' justifyContent='center' width={1}>
                              <ImageRoundedIcon
                                sx={{
                                  fontSize: 128,
                                  color: activeTheme.default.palette.text.primary,
                                }}
                              />
                            </Stack>
                          </Card>
                        : linkItem.blockType === 'next_release'
                          ? nextRelease
                            ? <Card
                              elevation={0}
                              sx={{
                                background: activeTheme.default.palette.background.default,
                                width: 1,
                              }}
                            >
                              <CardActionArea
                                onClick={() => setOpenNextDrawer(true)}
                              >
                                <Stack direction='column' padding={1.5} spacing={2} width={1}>
                                  <Stack alignItems='center' direction='row' spacing={1} width={1}>
                                    <Avatar
                                      alt='Artwork next release'
                                      src={nextRelease.artworkUrl}
                                      sx={{
                                        borderRadius: 0.5,
                                        flexShrink: 0,
                                        height: 38,
                                        width: 38,
                                      }}
                                      variant='rounded'
                                    >
                                      <MusicNoteRoundedIcon />
                                    </Avatar>
                                    <Stack direction='column' width={1}>
                                      <Typography
                                        color={activeTheme.default.palette.text.primary}
                                        fontFamily={props.fontFamily}
                                        fontSize='1rem'
                                        textAlign='left'
                                        variant='body1'
                                      >
                                        {nextRelease.title}
                                      </Typography>
                                      {nextRelease.releaseDate &&
                                      <Typography
                                        color={activeTheme.default.palette.text.secondary}
                                        fontFamily={props.fontFamily}
                                        fontSize='0.875rem'
                                        textAlign='left'
                                        variant='body2'
                                      >
                                        {`Out ${dayjs(nextRelease.releaseDate).format('MMMM Do')}`}
                                      </Typography>}
                                    </Stack>
                                    <Stack>
                                      <Fab
                                        size='small'
                                        sx={{
                                          boxShadow: 'none',
                                          borderRadius: 0.5,
                                          color: activeTheme.default.palette.background.default,
                                          background: activeTheme.default.palette.text.primary,
                                          ':hover': {
                                            color: activeTheme.default.palette.background.default,
                                            background: activeTheme.default.palette.text.primary,
                                          },
                                        }}
                                      >
                                        <NotificationsRoundedIcon />
                                      </Fab>
                                    </Stack>
                                  </Stack>
                                </Stack>
                              </CardActionArea>
                            </Card>
                            : null
                          : linkItem.blockType === 'latest_release'
                            ? latestRelease
                              ? <Card
                                elevation={0}
                                sx={{
                                  background: activeTheme.default.palette.background.default,
                                  width: 1,
                                }}
                              >
                                <CardActionArea
                                  onClick={() => setOpenLatestDrawer(true)}
                                >
                                  <Stack direction='column' padding={1.5} spacing={2} width={1}>
                                    <Stack alignItems='center' direction='row' spacing={1} width={1}>
                                      <Avatar
                                        alt='Artwork next release'
                                        src={latestRelease.artworkUrl}
                                        sx={{
                                          borderRadius: 0.5,
                                          flexShrink: 0,
                                          height: 38,
                                          width: 38,
                                        }}
                                        variant='rounded'
                                      >
                                        <MusicNoteRoundedIcon />
                                      </Avatar>
                                      <Stack direction='column' width={1}>
                                        <Typography
                                          color={activeTheme.default.palette.text.primary}
                                          fontFamily={props.fontFamily}
                                          fontSize='1rem'
                                          textAlign='left'
                                          variant='body1'
                                        >
                                          {latestRelease.title}
                                        </Typography>
                                        {latestRelease.releaseDate &&
                                        <Typography
                                          color={activeTheme.default.palette.text.secondary}
                                          fontFamily={props.fontFamily}
                                          fontSize='0.875rem'
                                          textAlign='left'
                                          variant='body2'
                                        >
                                          Out now
                                        </Typography>}
                                      </Stack>
                                      <Stack>
                                        <Fab
                                          size='small'
                                          sx={{
                                            boxShadow: 'none',
                                            borderRadius: 0.5,
                                            color: activeTheme.default.palette.background.default,
                                            background: activeTheme.default.palette.text.primary,
                                            ':hover': {
                                              color: activeTheme.default.palette.background.default,
                                              background: activeTheme.default.palette.text.primary,
                                            },
                                          }}
                                        >
                                          <MusicNoteRoundedIcon />
                                        </Fab>
                                      </Stack>
                                    </Stack>
                                  </Stack>
                                </CardActionArea>
                              </Card>
                              : null
                            : linkItem.blockType === 'facebook' && linkItem.videoEmbedUrl.length > 0
                              ? <Stack sx={{ background: 'white' }} width={1}>
                                <FacebookEmbed url={linkItem.videoEmbedUrl} width='100%' />
                              </Stack>
                              : linkItem.blockType === 'instagram' && linkItem.videoEmbedUrl.length > 0
                                ? <Stack sx={{ background: 'white' }} width={1}>
                                  <InstagramEmbed debug url={linkItem.videoEmbedUrl} width='100%' />
                                </Stack>
                                : linkItem.embedHtml && linkItem.embedHtml.length > 0
                                  ? linkItem.blockType === 'tiktok' && linkItem.videoEmbedUrl.length > 0
                                    ? <TikTokEmbed url={linkItem.videoEmbedUrl} width='100%' />
                                    : <EmbedContainer markup={linkItem.embedHtml}>
                                      <Stack
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{ __html: linkItem.embedHtml }}
                                        sx={{
                                          width: '100%',
                                          border: 'none',
                                          '& iframe': {
                                            'width': '100%',
                                          },
                                        }}
                                      />
                                    </EmbedContainer>
                                  : null}
      {latestRelease && props.organisation &&
      <RenderLatestReleaseDrawer
        browserName={props.browserName}
        close={() => setOpenLatestDrawer(false)}
        link={latestRelease}
        onOpen={() => setOpenLatestDrawer(true)}
        open={openDrawerLatest}
        organisation={props.organisation}
        osName={props.osName}
        referrerHistory={props.referrerHistory}
        trackingData={props.trackingData}
      />}
      {nextRelease && props.organisation &&
      <RenderLatestReleaseDrawer
        browserName={props.browserName}
        close={() => setOpenNextDrawer(false)}
        link={nextRelease}
        onOpen={() => setOpenNextDrawer(true)}
        open={openDrawerNext}
        organisation={props.organisation}
        osName={props.osName}
        referrerHistory={props.referrerHistory}
        trackingData={props.trackingData}
      />}
    </>
  )
}

export default RenderBioLinkBlock

/*
 * IMPORTANT NOTICE
 * Never reference these colors directly in code.
 * ALWAYS reference the theme!
 */

export const PRIMARY = {
  DEFAULT: '#5F2EEA' as const,
  DARKMODE: '#a69bff' as const,
  MAIN: '#5F2EEA' as const,
  LIGHT: '#a69bff' as const,
  DARK: '#3F1D95' as const,
  CONTRAST_1: '#FCFCFD' as const,
  CONTRAST_2: '#E5E7EB' as const,
  SURFACE: '#F5F3FC' as const,
  CONTAINED_RESTING_BG: '#5F2EEA' as const,
  CONTAINED_HOVER_BG: '#7B5CF6' as const,
  CONTAINED_ACTIVE_BG: '#5A28D9' as const,
  SUBTLE_RESTING_BG: '#F4F3FF' as const,
  SUBTLE_HOVER_BG: '#EBE9FE' as const,
  SUBTLE_ACTIVE_BG: '#EBE9FE' as const,
  TEXT_ACTIVE_BG: '#F4F3FF' as const,
  OUTLINED_BORDER: '#602EEA' as const,
  DARKMODE_SUBTLE: '#978aff' as const,
}

export const SECONDARY = {
  DEFAULT: '#1CC8EE' as const,
  DARKMODE: '#77DEF5' as const,
  MAIN: '#1CC8EE' as const,
  LIGHT: '#77DEF5' as const,
  DARK: '#0891B2' as const,
  CONTRAST: '#FCFCFC' as const,
  SURFACE: '#E7F9FD' as const,
  CONTAINED_RESTING_BG: '#22D3EE' as const,
  CONTAINED_HOVER_BG: '#67E8F9' as const,
  CONTAINED_ACTIVE_BG: '#06B6D4' as const,
  SUBTLE_RESTING_BG: '#ECFEFF' as const,
  SUBTLE_HOVER_BG: '#F5FEFF' as const,
  SUBTLE_ACTIVE_BG: '#CFFAFE' as const,
  TEXT_ACTIVE_BG: '#ECFEFF' as const,
  OUTLINED_BORDER: '#22D3EE' as const,
}

export const TERTIARY = {
  DEFAULT: '#FDE047' as const,
  DARKMODE: '#A16207' as const,
  MAIN: '#FDE047' as const,
  LIGHT: '#FEF9C3' as const,
  DARK: '#A16207' as const,
  CONTRAST: '#111827' as const,
  SURFACE: '#FEFCE8' as const,
}

export const TEXT = {
  PRIMARY: '#111827' as const,
  SECONDARY: '#4B5563' as const,
  LABEL: '#9CA3AF' as const,
  DISABLED: '#D1D5DB' as const,
  LINK: '#38BDF8' as const,

  DARK_PRIMARY: '#FFFFFFFF' as const,
  DARK_SECONDARY: 'rgba(255, 255, 255, 0.7)' as const,
  DARK_LABEL: 'rgba(255, 255, 255, 0.5)' as const,
  DARK_DISABLED: 'rgba(255, 255, 255, 0.5)' as const,
  DARK_LINK: '#38BDF8' as const,
}

export const ACTION = {
  ACTIVE: '#6b7280' as const,
  HOVER: '#E5E7EB' as const,
  SELECTED: '#E5E7EB' as const,
  FOCUS: '#D1D5DB' as const,
  DISABLED: '#D1D5DB' as const,
  DISABLED_BG: '#F3F4F6' as const,
  DARK_DISABLED_BG: '#646669' as const,
}

export const SUCCESS = {
  DEFAULT: '#4ADE80' as const,
  DARKMODE: '#BBF7D0' as const,
  MAIN: '#4ADE80' as const,
  LIGHT: '#BBF7D0' as const,
  DARK: '#16A34A' as const,
  CONTRAST: '#FCFCFD' as const,
  CONTAINED_HOVER_BG: '#16A34A' as const,
  OUTLINED_RESTING_BG: '#BBF7D0' as const,
  OUTLINED_HOVER_BG: '#DCFCE7' as const,
  COMPONENTS_CONTENT: '#22C55E' as const,
  COMPONENTS_BG: '#DCFCE7' as const,

}

export const INFO = {
  DEFAULT: '#3B82F6' as const,
  DARKMODE: '#93C5FD' as const,
  MAIN: '#3B82F6' as const,
  LIGHT: '#93C5FD' as const,
  DARK: '#1D4ED8' as const,
  CONTRAST: '#FCFCFD' as const,
  CONTAINED_HOVER_BG: '#2563EB' as const,
  OUTLINED_RESTING_BG: '#BFDBFE' as const,
  OUTLINED_HOVER_BG: '#DBEAFE' as const,
  COMPONENTS_CONTENT: '#3B82F6' as const,
  COMPONENTS_BG: '#DBEAFE' as const,
}

export const WARNING = {
  DEFAULT: '#F97316' as const,
  DARKMODE: '#FDBA74' as const,
  MAIN: '#F97316' as const,
  LIGHT: '#FDBA74' as const,
  DARK: '#C2410C' as const,
  CONTRAST: '#FCFCFD' as const,
  CONTAINED_HOVER_BG: '#EA580C' as const,
  OUTLINED_RESTING_BG: '#FED7AA' as const,
  OUTLINED_HOVER_BG: '#FFEDD5' as const,
  COMPONENTS_CONTENT: '#F97316' as const,
  COMPONENTS_BG: '#FFF7ED' as const,
}

export const ERROR = {
  DEFAULT: '#EF4444' as const,
  DARKMODE: '#FCA5A5' as const,
  MAIN: '#EF4444' as const,
  LIGHT: '#FCA5A5' as const,
  DARK: '#B91C1C' as const,
  CONTRAST: '#FCFCFD' as const,
  CONTAINED_HOVER_BG: '#DC2626' as const,
  OUTLINED_RESTING_BG: '#FCA5A5' as const,
  OUTLINED_HOVER_BG: '#FECACA' as const,
  COMPONENTS_CONTENT: '#EF4444' as const,
  COMPONENTS_BG: '#FEE2E2' as const,
}

export const OTHER = {
  DIVIDER: '#D1D5DB' as const,
  DARK_DIVIDER: '#3E4042' as const,
  FILLED_INPUT_BACKGROUND: '#FFF' as const,
  PLACEHOLDER: '#D1D5DB' as const,
  OUTLINE_BORDER: '#E5E7EB' as const,
  STANDARD_INPUT_LINE: '#' as const,
  BACKDROP_OVERLAY: '#' as const,
  RATING_ACTIVE: '#FBBF24' as const,
  SNACKBAR_BG: '#1F2937' as const,
  SHADOW: '#e0e0e0' as const,
  SHADOW_DARKMODE: '#1a1919' as const,
  DARK_FILLED_INPUT_BACKGROUND: '#4E4F50' as const,
  WAVEFORM_LIGHT: '#D1D6DA' as const,
  WAVEFORM_DARK: '#D1D6DA' as const,
}

export const SOCIAL = {
  FACEBOOK: '#1778F2' as const,
  TWITTER: '#00acee' as const,
  INSTAGRAM: '#8a3ab9' as const,
  YOUTUBE: '#FF0000' as const,
  TIKTOK: '#EE1D52' as const,
  APPLE: '#000000' as const,
  GOOGLE: '#DB4437' as const,
}

export const STREAMING = {
  SPOTIFY: '#1db954' as const,
  APPLE_MUSIC: '#fc3c44' as const,
  AMAZON_MUSIC: '#25d1da' as const,
  DEEZER: '#A238FF' as const,
  ITUNES: '#EA4CC0' as const,
  TIDAL: '#000000' as const,
  SOUNDCLOUD: '#ff8800' as const,
}

export const SURFACE = {
  BACKGROUND: '#FCFCFD' as const,
  INPUT: '#F3F4F6' as const,
  DARK_PAPER: '#000000' as const,
  DARK_BACKGROUND: '#171717' as const,
}

const COLOR = {
  PRIMARY,
  SECONDARY,
  TERTIARY,
  SUCCESS,
  INFO,
  WARNING,
  ERROR,
  TEXT,
  ACTION,
  OTHER,
  SURFACE,
  SOCIAL,
  STREAMING,
}
export default COLOR

import type { Components } from '@mui/material'

import COLOR from 'src/styles/colors'
import SPACING from 'src/styles/spacing'

const MuiCheckbox: Components['MuiCheckbox'] = {
  defaultProps: {
    color: 'primary',
  },
  styleOverrides: {
    root: {
      padding: SPACING.themeSpacing(1),
      '.MuiSvgIcon-root': {
        fontSize: '1.125rem', // 18px
      },
      color: COLOR.OTHER.DIVIDER,
    },
  },
}

export default MuiCheckbox

import type { PaletteOptions } from '@mui/material'

import COLOR from './colors'

const basePalette: PaletteOptions  = {
  primary: {
    main: '',
    light: COLOR.PRIMARY.LIGHT,
    dark: COLOR.PRIMARY.DARK,
    contrastText: COLOR.PRIMARY.CONTRAST_1,
  },
  secondary: {
    main: '',
    light: COLOR.SECONDARY.LIGHT,
    dark: COLOR.SECONDARY.DARK,
    contrastText: COLOR.SECONDARY.CONTRAST,
  },
  success: {
    main: '',
    light: COLOR.SUCCESS.LIGHT,
    dark: COLOR.SUCCESS.DARK,
    contrastText: COLOR.SUCCESS.CONTRAST,
  },
  warning: {
    main: '',
    light: COLOR.WARNING.LIGHT,
    dark: COLOR.WARNING.DARK,
    contrastText: COLOR.WARNING.CONTRAST,
  },
  error: {
    main: '',
    light: COLOR.ERROR.LIGHT,
    dark: COLOR.ERROR.DARK,
    contrastText: COLOR.ERROR.CONTRAST,
  },
}

export default basePalette

/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const SongkickIcon = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='0 0 134 134'>
    <path d='M115.5,15.6C106.5,8.9,96.5,4.4,84,2.3v59.8l17.9-25h19.6L99,68.6c0,0,5.2,8.3,8.3,13.2c3.2,5,6.2,6.3,10.8,6.3
	c1.4,0,1.4,0,3.1,0v15.5l-4.8,0c-9.9,0-15.9-1.8-20.4-8.7c-2.8-4.4-12-18.9-12-18.9v27.5H67.6V1c-0.2,0-0.4,0-0.6,0
	C45.8,1,31.2,6.1,18.5,15.6C0.3,29.3,1,47.5,1,67c0,19.5-0.7,37.8,17.5,51.4C31.2,127.9,45.8,133,67,133c21.2,0,35.8-5.1,48.5-14.6
	C133.7,104.7,133,86.5,133,67C133,47.5,133.7,29.3,115.5,15.6 M37,104.3c-10.2,0-18.5-1.9-25.3-8.8L22.2,85
	c3.5,3.5,9.5,4.7,14.9,4.7c6.5,0,9.6-2.2,9.6-6c0-1.6-0.4-2.9-1.3-3.9c-0.8-0.8-2.2-1.5-4.4-1.8l-8.1-1.1C27,76,22.5,74,19.5,70.9
	c-3.1-3.2-4.6-7.7-4.6-13.4c0-12.2,9.2-21.1,24.3-21.1c9.6,0,16.8,2.2,22.5,8L51.4,54.7c-4.2-4.2-9.7-3.9-12.6-3.9
	c-5.7,0-8.1,3.3-8.1,6.2c0,0.8,0.3,2.1,1.3,3.1c0.8,0.8,2.2,1.7,4.6,2l8,1.1c6,0.8,10.3,2.7,13.1,5.5c3.6,3.5,5,8.4,5,14.6
	C62.8,96.9,51.1,104.3,37,104.3'
    />
  </SvgIcon>

export default SongkickIcon

/* eslint-disable max-len */
import { Icon } from '@mui/material'

import awaImage from 'src/components/Icons/awa.png'

const AwaLogo = () =>
  <Icon sx={{ height: 26, width: 26, fontSize: '1rem' }}>
    <img alt='AWA Logo' height='100%' src={awaImage} width='100%' />
  </Icon>

export default AwaLogo

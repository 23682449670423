/* eslint-disable max-len */
import { Icon } from '@mui/material'

import kanjianImage from 'src/components/Icons/kanjian.png'

const KanjianLogo = () =>
  <Icon sx={{ height: 26, width: 26, fontSize: '1rem' }}>
    <img alt='Flo Logo' height='100%' src={kanjianImage} width='100%' />
  </Icon>

export default KanjianLogo

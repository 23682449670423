/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const ItunesLogo = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='0 0 50 50'>
    <path d='M25,2C12.318,2,2,12.317,2,25s10.318,23,23,23s23-10.317,23-23S37.682,2,25,2z M35,29.866C35,32.701,32.701,35,29.866,35 h-0.101c-1.785,0-3.452-1.214-3.725-2.977c-0.05-0.325-0.053-0.649-0.01-0.973c0.18-1.39,1.17-2.52,2.48-2.91l0.29-0.07l3.447-0.878 C32.69,27.079,33,26.68,33,26.223v-6.164c0-0.622-0.561-1.093-1.174-0.985l-9.676,1.884C21.485,21.075,21,21.653,21,22.329v10.536 C21,35.701,18.701,38,15.866,38h-0.101c-1.785,0-3.452-1.214-3.725-2.977c-0.05-0.325-0.053-0.649-0.01-0.973 c0.18-1.39,1.17-2.52,2.48-2.91l0.29-0.07l3.447-0.878C18.69,30.079,19,29.68,19,29.223V15.956c0-0.664,0.476-1.232,1.13-1.347 l13.696-2.594C34.439,11.907,35,12.378,35,13V29.866z' />
  </SvgIcon>

export default ItunesLogo

import 'src/styles/Custom/cookielaw.css'

import { Button, Drawer, Grid, Stack, Typography } from '@mui/material'

type Props = {
  open: boolean
  close: () => void
  gdprTracking: boolean
  openPreferenceCenter: () => void
  acceptAll: () => void
  declineAll: () => void
}

const PrivacyBanner = (props: Props) => {
  const acceptAllCookies = () => {
    props.acceptAll()
    props.close()
  }

  const declineAllCookies = () => {
    props.declineAll()
    props.close()
  }

  const customizeCookies = () => {
    props.openPreferenceCenter()
    props.close()
  }

  return (
    <Drawer
      anchor='bottom'
      onClose={props.close}
      open={props.open}
    >
      <Grid container minHeight={100} padding={2} spacing={2}>

        <Grid item lg={8} md={8} sm={12} xl={8} xs={12}>
          <Stack direction='column' width={1}>
            <Typography variant='h4'>
              Personalization
            </Typography>
            <Typography variant='body2'>
              By clicking “Enable All”, you agree to the storing of cookies on your device to enhance site navigation,
              analyze site usage, and assist in our marketing efforts.
            </Typography>
          </Stack>
        </Grid>

        <Grid item lg={4} md={4} sm={12} xl={4} xs={12}>
          <Stack alignItems='center' direction='row' height={1} justifyContent='center' spacing={1} width={1}>
            {props.gdprTracking &&
            <Button color='error' onClick={declineAllCookies} rounded variant='text'>
              Reject all
            </Button>}
            <Button color='primary' onClick={customizeCookies} rounded variant='outlined'>
              Customize
            </Button>
            <Button color='primary' onClick={acceptAllCookies} rounded sx={{ minWidth: 130 }}>
              Enable all
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Drawer>
  )
}

export default PrivacyBanner

import 'src/styles/fonts/fonts.css'

import type { ThemeOptions } from '@mui/material/styles'

import components from './base.components.theme'
import palette from './base.palette.theme'
import shape from './base.shape.theme'
import typography from './base.typography.theme'

const baseThemeOptions: ThemeOptions = {
  palette,
  typography,
  shape,
  components,
}

export default baseThemeOptions

/* eslint-disable react/jsx-no-leaked-render */
import { CssBaseline, ThemeProvider } from '@mui/material'
import { Route, Routes } from 'react-router-dom'

import ReleasePreview from 'src/components/pages/SmartLink/ReleasePreview'
import lightTheme from 'src/styles/light.theme'

export type themeSelector = 'dark' | 'light'

const App = () =>
  <ThemeProvider theme={lightTheme}>
    <CssBaseline />
    <Routes>
      <Route element={<ReleasePreview />} path='*' />
    </Routes>
  </ThemeProvider>

export default App

import type { Components } from '@mui/material'

import SPACING from 'src/styles/spacing'

const largeSize = SPACING.themeSpacing(7.5) // 60px
const mediumSize = SPACING.themeSpacing(6) // 40px
const smallSize = SPACING.themeSpacing(4.5) // 36px

const MuiFab: Components['MuiFab'] = {
  defaultProps: {
    color: 'primary',
    size: 'medium',
  },
  styleOverrides: {
    root: {
      height: largeSize,
      width: largeSize,
      '.MuiSvgIcon-root': {
        fontSize: '1.5rem',
      },
    },
    sizeMedium: {
      height: mediumSize,
      width: mediumSize,
    },
    sizeSmall: {
      height: smallSize,
      width: smallSize,
    },
  },
}

export default MuiFab

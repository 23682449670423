import axios from 'axios'

import { allowNotFoundAndUnauthorized } from 'src/api/axios'
import { baseUrlLinksApi } from 'src/api/links'
import type { BasicOrganisationDto } from 'src/models/Organisation'
import { BasicOrganisation } from 'src/models/Organisation'

const PATH = '/organisation' as const

export const getBasicOrganisation = (organisationId: string) =>
  axios.get<BasicOrganisationDto>(`${baseUrlLinksApi}${PATH}/basic/${organisationId}`)
    .then(response => new BasicOrganisation(response.data))
    .catch(allowNotFoundAndUnauthorized)

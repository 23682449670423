/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const JooxLogo = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='0 0 100 100'>
    <path d='M 28 16 C 21.384534 16 16 21.384534 16 28 L 16 72 C 16 78.615466 21.384534 84 28 84 L 72 84 C 78.615466 84 84 78.615466 84 72 L 84 28 C 84 21.384534 78.615466 16 72 16 L 28 16 z M 28 18 L 72 18 C 77.534534 18 82 22.465466 82 28 L 82 72 C 82 77.534534 77.534534 82 72 82 L 28 82 C 22.465466 82 18 77.534534 18 72 L 18 28 C 18 22.465466 22.465466 18 28 18 z M 50.628906 24.984375 C 47.90502 24.940596 45.171641 25.337188 42.572266 26.132812 C 38.210266 27.477812 34.255953 30.027344 31.251953 33.402344 C 28.326953 36.667344 26.303797 40.688266 25.466797 44.947266 C 24.402797 50.221266 25.202578 55.821797 27.642578 60.591797 C 29.581578 64.425797 32.606484 67.732594 36.271484 70.058594 C 40.355484 72.688594 45.273453 74.089859 50.189453 74.005859 C 54.253453 73.960859 58.313813 72.917094 61.882812 70.996094 C 62.370812 70.761094 62.812281 70.359484 63.363281 70.271484 C 65.687281 70.309484 68.027328 70.441922 70.361328 70.419922 C 70.074328 68.627922 69.768 66.838922 69.5 65.044922 C 69.422 64.802922 69.600094 64.578437 69.746094 64.398438 C 72.893094 60.452438 74.717703 55.547547 74.970703 50.560547 C 75.210703 45.378547 73.780562 40.1125 70.851562 35.8125 C 67.332562 30.6285 61.721187 26.856828 55.492188 25.548828 C 53.894312 25.196328 52.263238 25.010643 50.628906 24.984375 z' />
    {' '}
  </SvgIcon>

export default JooxLogo
